import { Renderer } from '@unseenco/taxi'
import blockList from '../blocks/blockList'
// import gsap from 'gsap'
import store from '../util/store'

export default class Page extends Renderer {
  onEnter() {
    this.blockList = blockList
    this.blocks = []

    if (this.blockList && this.blockList.length) this.initBlocks()

    // if (store.detect.isMobile) {
    //   this.screensElements = document.body.querySelectorAll('.h-screen')
    //   this.screensElements.length && gsap.set(this.screensElements, { height: store.w.h })
    // }
  }

  initBlocks() {
    for (let i = 0; i < this.blockList.length; i++) {
      const foundBlocks = this.content.querySelectorAll('.' + this.blockList[i].name)
      const block = {
        name: this.blockList[i].name,
        instances: []
      }

      for (let j = 0; j < foundBlocks.length; j++) {
        const instance = {
          el: foundBlocks[j],
          class: new this.blockList[i].Class(foundBlocks[j])
        }

        block.instances.push(instance)
      }
      this.blocks.push(block)
    }
  }

  onEnterCompleted() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        this.blocks[i].instances[j].class.onEnterCompleted()
      }
    }

    if (store.isFirstLoaded) this.appear()
    else window.addEventListener('loaderComplete', () => this.appear(), { once: true })
  }

  appear() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        this.blocks[i].instances[j].class.appear()
      }
    }
  }

  onLeave() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        if (!this.blocks[i].instances[j].class.destroyLast) this.blocks[i].instances[j].class.destroy()
      }
    }
  }

  onLeaveCompleted() {
    // Destroy blocks with `destroyLast` property set to `true`
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        if (this.blocks[i].instances[j].class.destroyLast) this.blocks[i].instances[j].class.destroy()
      }
    }
  }

  resize() {
    // store.detect.isMobile && this.screensElements && this.screensElements.length && gsap.set(this.screensElements, { height: store.w.h })

    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        this.blocks[i].instances[j].class.resize()
      }
    }
  }

  resizeX() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        this.blocks[i].instances[j].class.resizeX()
      }
    }
  }

  scroll(e) {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        this.blocks[i].instances[j].class.scroll(e)
      }
    }
  }

  loop() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) {
        this.blocks[i].instances[j].class.update()
      }
    }
  }
}
