import store from './store'

export default class SliderMobile {
  constructor({ el, wrapper, items, arrows }) {
    this.$el = el
    this.$wrapper = wrapper
    this.$items = items
    this.$arrows = arrows

    this.getElems()
    this.init()
    this.bindMethods()
    this.events()
  }

  getElems() {
    this.$leftArrow = this.$arrows.querySelector('.arrow-slider__left')
    this.$rightArrow = this.$arrows.querySelector('.arrow-slider__right')
  }

  init() {
    this.activeIndex = 0
    this.max = store.w.w - store.remToPixel(parseFloat(getComputedStyle(document.body).getPropertyValue('--padding-container')))
  }

  bindMethods() {
    this.onLeftClick = this.onLeftClick.bind(this)
    this.onRightClick = this.onRightClick.bind(this)
    this.checkActive = this.checkActive.bind(this)
  }

  events() {
    this.$leftArrow.addEventListener('click', this.onLeftClick)
    this.$rightArrow.addEventListener('click', this.onRightClick)
  }

  onLeftClick() {
    this.checkActive()
    
    if (this.activeIndex > 0) {
      this.activeIndex--
      this.$items[this.activeIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  onRightClick() {
    this.checkActive()
    
    if (this.activeIndex !== this.$items.length - 1) {
      this.activeIndex++
      this.$items[this.activeIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  checkActive() {
    this.$items.forEach((item, i) => {
      const left = item.getBoundingClientRect().left

      if (left > 0 && left < this.max) this.activeIndex = i
    })
  }

  destroy() { }

  resizeX() { }
}
