/* eslint-disable max-lines */

import Anchor from '../util/Anchor'
import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class HomeCover extends Block {
  getElems() {
    this.$caeliOneSection = this.$el.querySelector('.home-cover__caeli-one-section')
    this.$caeliOneSectionImage = this.$caeliOneSection.querySelector('.home-cover__image')
    this.$caeliOneSectionScrollIndicator = this.$caeliOneSection.querySelector('.scroll-indicator')
    this.$caeliOneSectionScrollIndicatorWrapper = this.$caeliOneSectionScrollIndicator.parentNode

    this.$firstSection = this.$el.querySelector('.home-cover__first-section')
    this.$firstSectionContent = this.$firstSection.querySelector('.home-cover__content')
    if (this.$firstSectionContent) this.$firstSectionContentWrapper = this.$firstSectionContent.parentNode
    this.$firstSectionTitle = this.$firstSection.querySelector('.home-cover__title')

    this.$secondSection = this.$el.querySelector('.home-cover__second-section')
    this.$secondSectionAirs = this.$secondSection.querySelectorAll('.home-cover__air')
    this.$secondSectionPlant = this.$secondSection.querySelector('.home-cover__plant')
    this.$secondSectionShelf = this.$secondSection.querySelector('.home-cover__shelf')
    this.$secondSectionConsole = this.$secondSection.querySelector('.home-cover__console')

    this.$thirdSection = this.$el.querySelector('.home-cover__third-section')
    this.$thirdSectionInner = this.$thirdSection.querySelector('.home-cover__inner')
    this.$thirdSectionTitle = this.$thirdSection.querySelector('.home-cover__title')
    this.$thirdSectionSurtitle = this.$thirdSection.querySelector('.home-cover__surtitle')
    this.$thirdSectionButton = this.$thirdSection.querySelector('.button')
    this.$thirdSectionSlider = this.$thirdSection.querySelector('.home-cover__slider')
    this.$thirdSectionWrapper = this.$thirdSection.querySelector('.home-cover__wrapper')
    this.$thirdSectionCountersWrappers = this.$thirdSectionSlider.querySelectorAll('.home-cover__counter-wrapper')
    this.$thirdSectionCounters = this.$thirdSectionSlider.querySelectorAll('.home-cover__counter')
    this.$thirdSectionTitles = this.$thirdSectionSlider.querySelectorAll('.home-cover__title')
    this.$thirdSectionContents = this.$thirdSectionSlider.querySelectorAll('.home-cover__content')
    this.$thirdSectionImages = this.$thirdSectionSlider.querySelectorAll('.home-cover__image')
  }

  init() {
    this.currentIndex = -1
    this.sliderLength = this.$thirdSectionCounters.length

    this.getBounds()

    this.firstSectionTitleLinesCharacters = this.splitTitle(this.$firstSectionTitle)
    this.thirdSectionTitleLinesCharacters = this.splitTitle(this.$thirdSectionTitle)
    this.thirdSectionTitlesLinesCharacters = []

    for (let i = 0; i < this.$thirdSectionTitles.length; i++) this.thirdSectionTitlesLinesCharacters.push(this.splitTitle(this.$thirdSectionTitles[i]))

    this.setClipPath()
    this.initAnimation()
    this.initScrollTrigger()
    this.prepareAnimation()

    setTimeout(() => {
      this.anchor = new Anchor({
        scrollDuration: 6.5,
        targetScrollPosition: this.enteringThirdSectionScrollTrigger.end,
        triggerEl: this.$caeliOneSectionScrollIndicator
      })
    }, 0)
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharactersData = []
    
    lines.forEach((line, lineIndex) => {
      linesCharactersData[lineIndex] = {
        characters: [],
        charactersWrappers: []
      }
      
      /* eslint-disable object-property-newline */
      Splitting({ by: 'charactersWrappers', target: line }).forEach((lineCharactersData) => {
        linesCharactersData[lineIndex].characters.push(...lineCharactersData.charactersWrappers.characters)
        linesCharactersData[lineIndex].charactersWrappers.push(...lineCharactersData.charactersWrappers.charactersWrappers)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharactersData
  }
  
  getBounds() {
    this.caeliOneSectionRect = this.$caeliOneSection.getBoundingClientRect()
    this.caeliOneSectionBottom = this.caeliOneSectionRect.bottom
    this.caeliOneSectionHeight = this.caeliOneSectionRect.height

    this.firstSectionTitleRect = this.$firstSectionTitle.getBoundingClientRect()
    this.firstSectionTitleBottom = this.firstSectionTitleRect.bottom
    this.firstSectionTitleHeight = this.firstSectionTitleRect.height

    this.caeliOneSectionTranslateValue = this.firstSectionTitleBottom - this.caeliOneSectionBottom + this.caeliOneSectionHeight * 1.5 - this.firstSectionTitleHeight / 8

    if (store.w.w < 768) {
      this.caeliOneSectionTranslateValue = store.w.h * 0.6
    } else if (store.w.w < 1024) {
      this.caeliOneSectionTranslateValue = store.w.h * 0.5
    } else if (store.w.w < 1200) {
      this.caeliOneSectionTranslateValue = store.w.h > store.w.w ? store.w.h * 0.4 : store.w.h * 0.75
    }

    const heights = Array.from(this.$thirdSectionTitles).map((title) => title.getBoundingClientRect().height);
    const maxHeightIndex = heights.indexOf(Math.max(...heights));
    
    if (maxHeightIndex !== -1) {
      const largestThirdSectionTitle = this.$thirdSectionTitles[maxHeightIndex];

      largestThirdSectionTitle.classList.remove('absolute', 'left-1/2', 'top-0', '-translate-x-1/2', 'translate-y-1/2');
    }

    this.insetTopBottom = '50%'
    this.insetLeftRight = '50%'
  }

  setClipPath() {
    gsap.set(this.$el, {
      '--inset-top-bottom': () => this.insetTopBottom,
      '--inset-left-right': () => this.insetLeftRight
    })
  }

  initAnimation() {
    this.leavingFirstSectionAnimation = gsap.timeline({ defaults: { duration: 1 }})

    this.leavingFirstSectionAnimation
      .to(this.$firstSectionContent, {
        alpha: 0,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      })
      .to(this.$caeliOneSectionScrollIndicator, {
        alpha: 0,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      }, 0)
      .fromTo(this.$caeliOneSectionImage, {
        scale: 2
       }, {
        ease: 'power3.inOut',
        scale: 0.7
      }, 0.25)
      .fromTo(this.$caeliOneSection, {
        y: () => this.caeliOneSectionTranslateValue
       }, {
        ease: 'power3.inOut',
        y: 0
      }, 0.25)
      .fromTo([this.$secondSectionShelf, this.$secondSectionConsole, this.$secondSectionPlant], {
        scale: 2,
        y: () => this.caeliOneSectionTranslateValue
      }, {
        ease: 'power3.inOut',
        scale: 1,
        stagger: 0.05,
        y: 0
      }, 0.25 + 0.05)
      .fromTo(this.$secondSectionShelf, {
        xPercent: 100
      }, {
        ease: 'power3.inOut',
        xPercent: 0
      }, 0.25 + 0.05)
      .to(this.$secondSectionAirs, {
        alpha: 0.5,
        duration: 0.5,
        ease: 'beaucoup.alpha'
      }, 1)

    this.firstSectionTitleLinesCharacters.forEach((lineCharacters, i) => {
      this.leavingFirstSectionAnimation.to(lineCharacters.characters, {
        alpha: 0,
        duration: 0.25,
        ease: 'beaucoup.alpha',
        stagger: 0.0125
      }, i * 0.05)
    })

    this.enteringThirdSectionAnimation = gsap.timeline({ defaults: { duration: 1 }})

    this.enteringThirdSectionAnimation
      .fromTo(this.$el, {
        '--inset-top-bottom': () => this.insetTopBottom,
        '--inset-left-right': () => this.insetLeftRight
      }, {
        ease: 'power3.out',
        '--inset-top-bottom': '0%',
        '--inset-left-right': '0%'
      })
      .fromTo([this.$secondSectionPlant, this.$secondSectionConsole, this.$secondSectionShelf], {
        scale: 1
      }, {
        ease: 'power2.inOut',
        scale: 1.3,
        stagger: 0.025
      }, 0)
      .to([this.$secondSectionPlant, this.$secondSectionConsole, this.$secondSectionShelf], {
        alpha: 0,
        duration: 0.25,
        stagger: 0.025
      }, 0.5)
      .to(this.$caeliOneSectionImage, {
        ease: 'power2.inOut',
        scale: Math.min(1, Math.max(store.w.h * 0.9 / 1328, 0.8))
      }, 0.025)
      .to(this.$thirdSectionInner, {
        ease: 'power2.inOut',
        scale: 1
      }, 0)
      .to(this.$thirdSectionSurtitle, {
        alpha: 1,
        duration: 0.25,
        ease: 'none'
      }, 0.35)
    
    this.thirdSectionTitleLinesCharacters.forEach((lineCharacters, i) => {
      this.enteringThirdSectionAnimation.to(lineCharacters.characters, {
        alpha: 1,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, 0.35 + i * 0.05)
    })

    if (store.w.w > 1199) {
      /* eslint-disable object-property-newline */
      this.leavingParallaxAnimation = gsap.to(this.$thirdSectionImages, { ease: 'none', yPercent: 15 })
      /* eslint-enable object-property-newline */
              
      this.leavingThirdSectionAnimation = gsap.timeline({ defaults: { duration: 1 }})
  
      this.leavingThirdSectionAnimation
        .to(this.$thirdSectionSurtitle, {
          alpha: 0,
          duration: 0.25,
          ease: 'beaucoup.alpha'
        })
        .to(this.$thirdSectionSlider, {
          autoAlpha: 1,
          duration: 0.25,
          ease: 'beaucoup.alpha'
        })
  
      this.thirdSectionTitleLinesCharacters.forEach((lineCharacters, i) => {
        this.leavingThirdSectionAnimation.to(lineCharacters.characters, {
          alpha: 0,
          duration: 0.25,
          ease: 'beaucoup.alpha',
          stagger: 0.0125
        }, i * 0.05)
      })
    }

  }

  initScrollTrigger() {
    this.isLeavingFirstSectionScrollTriggerActive = false
    this.isLeavingFirstSectionScrollTriggerFnished = false

    this.leavingFirstSectionScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingFirstSectionAnimation,
      end: store.w.h * 2 + 'px top',
      invalidateOnRefresh: true,
      onEnter: () => {
        this.isLeavingFirstSectionScrollTriggerActive = true
      },
      onEnterBack: () => {
        this.isLeavingFirstSectionScrollTriggerActive = true
        this.isLeavingFirstSectionScrollTriggerFnished = false
      },
      onLeave: () => {
        this.isLeavingFirstSectionScrollTriggerActive = false
        this.isLeavingFirstSectionScrollTriggerFnished = true
      },
      onLeaveBack: () => {
        this.isLeavingFirstSectionScrollTriggerActive = false
      },
      onRefresh: () => {
        if (this.isLeavingFirstSectionScrollTriggerActive || this.isLeavingFirstSectionScrollTriggerFnished) return

        gsap.set([this.$caeliOneSection, this.$secondSectionPlant, this.$secondSectionShelf, this.$secondSectionConsole], { y: () => this.caeliOneSectionTranslateValue })
        gsap.set([this.$caeliOneSectionImage, this.$secondSectionPlant, this.$secondSectionShelf, this.$secondSectionConsole], { scale: 2 })
        gsap.set(this.$caeliOneSectionScrollIndicator, { y: () => - this.caeliOneSectionHeight / 2 })
      },
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.enteringThirdSectionScrollTrigger = store.scrollTrigger.create({
      animation: this.enteringThirdSectionAnimation,
      end: store.w.h * 3.5 + 'px top',
      scrub: true,
      start: store.w.h * 2 + 'px top',
      trigger: this.$el
    })

    this.thirdSectionButtonScrollTrigger = store.scrollTrigger.create({
      start: store.w.h * 3 + 'px top',
      onEnter: () => {
        gsap.killTweensOf(this.$thirdSectionButton)
        gsap.to(this.$thirdSectionButton, {
          duration: 0.9,
          ease: 'expo.out',
          yPercent: 0
        })
      },
      onLeaveBack: () => {
        gsap.killTweensOf(this.$thirdSectionButton)
        gsap.to(this.$thirdSectionButton, {
          duration: 0.9,
          ease: 'expo.out',
          yPercent: 150
        })
      },
      trigger: this.$el
    })

    if (store.w.w > 1199) {
      this.leavingParallaxScrollTrigger = store.scrollTrigger.create({
        animation: this.leavingParallaxAnimation,
        scrub: true,
        start: 'bottom bottom',
        trigger: this.$el
      })

      this.leavingThirdSectionScrollTrigger = store.scrollTrigger.create({
        animation: this.leavingThirdSectionAnimation,
        end: 'bottom bottom',
        scrub: true,
        start: store.w.h * 3.5 + 'px top',
        trigger: this.$el
      })
    }
  }

  prepareAnimation() {
    /* eslint-disable object-property-newline */
    gsap.set([this.$caeliOneSection, this.$secondSectionPlant, this.$secondSectionShelf, this.$secondSectionConsole], { y: () => this.caeliOneSectionTranslateValue })
    gsap.set([this.$caeliOneSectionImage, this.$secondSectionPlant, this.$secondSectionShelf, this.$secondSectionConsole], { scale: 2 })
    gsap.set(this.$caeliOneSectionImage, { yPercent: 200 })
    gsap.set(this.$secondSectionShelf, { xPercent: 100 })
    gsap.set(this.$caeliOneSectionScrollIndicator, { y: () => - this.caeliOneSectionHeight / 2 })
    gsap.set(this.$caeliOneSectionScrollIndicatorWrapper, { alpha: 0 })
    if (this.$firstSectionContent) gsap.set(this.$firstSectionContentWrapper, { alpha: 0 })
    for (let i = 0; i < this.firstSectionTitleLinesCharacters.length; i++) gsap.set(this.firstSectionTitleLinesCharacters[i].charactersWrappers, { alpha: 0 })
    gsap.set(this.$secondSectionAirs, { alpha: 0 })
    gsap.set(this.$thirdSectionInner, { scale: 1.15 })
    gsap.set(this.$thirdSectionSurtitle, { alpha: 0 })
    for (let i = 0; i < this.thirdSectionTitleLinesCharacters.length; i++) gsap.set(this.thirdSectionTitleLinesCharacters[i].characters, { alpha: 0 })
    gsap.set(this.$thirdSectionButton, { yPercent: 150 })
    gsap.set(this.$thirdSectionSlider, { autoAlpha: 0 })
    for (let i = 1; i < this.sliderLength; i++) {
      gsap.set([this.$thirdSectionContents[i], this.$thirdSectionImages[i]], { alpha: 0 })
      for (let j = 0; j < this.thirdSectionTitlesLinesCharacters[i].length; j++) gsap.set(this.thirdSectionTitlesLinesCharacters[i][j].characters, { alpha: 0 })
    }
    /* eslint-enable object-property-newline */
  }

  events() {
    for (let i = 0; i < this.$thirdSectionCountersWrappers.length; i++) this.$thirdSectionCountersWrappers[i].addEventListener('click', this.onThirdSectionCounterClick.bind(this, i))
  }

  onThirdSectionCounterClick(index) {
    if (index === this.currentIndex) return

    this.updateSlide(index)
  }

  updateSlide(index) {
    this.currentIndex = index

    this.updatingSlideAnimation = gsap.timeline()

    for (let i = 0; i < this.sliderLength; i++) {
      const isCurrentIndex = i === this.currentIndex

      gsap.killTweensOf([this.$thirdSectionContents[i], this.$thirdSectionCountersWrappers[i], this.$thirdSectionCounters[i], this.$thirdSectionImages[i]])

      this.updatingSlideAnimation
        .to(this.$thirdSectionContents[i], {
          alpha: isCurrentIndex ? 1 : 0,
          duration: isCurrentIndex ? 0.5 : 0.25,
          ease: 'beaucoup.alpha'
        }, isCurrentIndex ? 0.3 : 0)
        .fromTo(this.$thirdSectionImages[i], {
          alpha: isCurrentIndex ? 1 : 0,
          scale: isCurrentIndex ? 1.1 : 1
        }, {
          duration: 1.5,
          ease: 'expo.out',
          scale: 1
        }, 0)

        this.$thirdSectionCounters[i].classList.toggle('a', isCurrentIndex)

      for (let j = 0; j < this.thirdSectionTitlesLinesCharacters[i].length; j++) {
        gsap.killTweensOf(this.thirdSectionTitlesLinesCharacters[i][j])

        this.updatingSlideAnimation.to(this.thirdSectionTitlesLinesCharacters[i][j].characters, {
          alpha: isCurrentIndex ? 1 : 0,
          duration: 0.375,
          ease: 'beaucoup.alpha',
          stagger: 0.01875
        }, isCurrentIndex ? 0.375 + j * 0.0375 : j * 0.0375)
      }
    }
  }
  
  appear() {
    gsap.to(this.$caeliOneSectionImage, {
      delay: 0.5,
      duration: 1.2,
      ease: 'expo.out',
      yPercent: 0
    })

    gsap.to(this.$caeliOneSectionScrollIndicatorWrapper, {
      alpha: 1,
      delay: this.$firstSectionContent ? 0.775 : 0.75,
      duration: 0.5,
      ease: 'beaucoup.alpha'
    })

    if (this.$firstSectionContent) {
      gsap.to(this.$firstSectionContentWrapper, {
        alpha: 1,
        delay: 0.75,
        duration: 0.5,
        ease: 'beaucoup.alpha'
      })
    }

    for (let i = 0; i < this.firstSectionTitleLinesCharacters.length; i++) {
      gsap.to(this.firstSectionTitleLinesCharacters[i].charactersWrappers, {
        alpha: 1,
        delay: 0.5 + i * 0.05,
        duration: 0.75,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      })
    }
  }

  resize() {
    setTimeout(() => {
      gsap.set([this.$caeliOneSection, this.$secondSectionPlant, this.$secondSectionShelf, this.$secondSectionConsole], { y: 0 })
      gsap.set([this.$caeliOneSectionImage, this.$secondSectionPlant, this.$secondSectionShelf, this.$secondSectionConsole], { scale: 1 })
  
      this.getBounds()
  
      gsap.set(this.$caeliOneSectionScrollIndicator, { y: () => - this.caeliOneSectionHeight / 2 })
      
      store.scrollTrigger.refresh()
    }, 0)
  }
}

/* eslint-enable max-lines */
