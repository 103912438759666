import Block from './Block'
import gsap from 'gsap'

export default class UnderlinedLink extends Block {
  bindMethods() {
    this.onMouseEnter = this.onMouseEnter.bind(this)
  }

  events() {
    this.$el.addEventListener('mouseenter', this.onMouseEnter)
  }

  init() {
    this.thickness = getComputedStyle(this.$el).getPropertyValue('--underlined-thickness') ?? '2px'
  }

  onMouseEnter() {
    gsap.killTweensOf(this.$el)

    this.animation = gsap.timeline({
      defaults: {
        duration: 0.4,
        ease: 'expo.out'
      }
    })

    this.animation
      .set(this.$el, { backgroundPosition: '100% 100%' })
      .to(this.$el, { backgroundSize: `0% ${this.thickness}` })
      .set(this.$el, { backgroundPosition: '0 100%' })
      .to(this.$el, { backgroundSize: `100% ${this.thickness}` })
  }
}
