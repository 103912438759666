import { gsap } from 'gsap';
import store from '../store';

export default class SimulatorTimeline {
  constructor({ el }) {
    this.$el = el

    this.getElems()
    this.init()
    this.bindMethods()
  }

  bindMethods() {
    this.updateStep = this.updateStep.bind(this)
    this.restart = this.restart.bind(this)
    this.prepareAnimation = this.prepareAnimation.bind(this)
  }

  getElems() {
    this.$questions = this.$el.querySelectorAll('.simulator__timeline__question')
    this.$start = this.$el.querySelector('.simulator__timeline__start')
    this.$end = this.$el.querySelector('.simulator__timeline__end')
    this.$endingLine = this.$el.querySelector('.simulator__timeline__question__line.ending>.simulator__timeline__question-active')
  }

  init() {
    this.beige = getComputedStyle(document.body).getPropertyValue('--color-beige')
    this.green = '#4B6A5A'

    this.formatElems()
    this.prepareAnimation()
  }

  formatElems() {
    this.steps = []

    this.$questions.forEach((question) => {
      const circle = question.querySelector('.simulator__timeline__question__circle')
      const line = question.querySelectorAll('.simulator__timeline__question-active')

      const obj = {
        question,
        circle,
        line
      }

      this.steps.push(obj)
    })

    // console.log(this.steps);
  }

  updateStep(currentStep, fromStep) {
    this.updateAnimation && store.cancelAnimation(this.updateAnimation)
    this.updateAnimation = gsap.timeline()

    if (fromStep === 0) gsap.to(this.$el, { alpha: 1 })
    if (currentStep === 0) gsap.to(this.$el, { alpha: 1 })
 
    if (fromStep < currentStep) {
      this.updateAnimation
        .to(this.steps[fromStep].line, {
          scaleY: 1
        })
        .to(this.steps[fromStep].circle, {
          borderColor: `rgb(${this.beige})`
        })
    } else {
      this.updateAnimation
        .to(this.steps[currentStep].circle, {
          borderColor: this.green
        })
        .to(this.steps[currentStep].line, {
          scaleY: 0
        }, '<0.1')
    }
  }

  restart() {
    this.prepareAnimation()
  }

  ending() {
    this.endingAnimation && store.cancelAnimation(this.endingAnimation)
    this.endingAnimation = gsap.timeline()

    this.endingAnimation
      .to(this.$endingLine, {
        scaleY: 1
      })
      .to(this.$end, {
        borderColor: `rgb(${this.beige})`
      })
  }

  prepareAnimation() {
    gsap.set(this.$el, { alpha: 0 })
    gsap.set(this.$endingLine, { scaleY: 0 })
    gsap.set(this.$end, { borderColor: this.green })

    this.steps.forEach((step) => {
      gsap.set(step.line, { scaleY: 0 })
      gsap.set(step.circle, { borderColor: this.green })
    })
  }

  resizeX() {
    
  }
}

