import gsap from 'gsap'
import LanguageSwitcher from './LanguageSwitcher'
import Menu from './Menu'
import store from './store'

export default class Header {
  constructor() {
    this.bindMethods()
    this.getElems()
    this.init()
    this.events()
    this.updateCSSVariables()
    this.onPageChange({ location: window.location })
  }

  bindMethods() {
    this.onButtonEnter = this.onButtonEnter.bind(this)
  }

  getElems() {
    this.$el = document.body.querySelector('header')
    this.$button = this.$el.querySelector('.header__button')
    this.$buttonArrows = this.$button.querySelectorAll('.button__arrow')
    this.$buttonArrowsWrapper = this.$button.querySelector('.button__arrows-wrapper')
    this.$languageSwitcher = this.$el.querySelector('.language-switcher')
    if (this.$languageSwitcher) this.$languageSwitcherToggler = this.$languageSwitcher.querySelector('.language-switcher__toggler')
    this.$menu = this.$el.querySelector('.menu')
    this.$menuToggler = this.$el.querySelector('.menu__toggler')
    this.$overlay = this.$el.querySelector('.header__overlay')
  }

  init() {
    this.isHidden = false
    this.lastScroll = 0
    this.scrollDownOffset = store.remToPixel(15)
    this.scrollUpOffset = 0

    /* eslint-disable object-property-newline */
    if (this.$languageSwitcher) this.languageSwitcher = new LanguageSwitcher({ el: this.$languageSwitcher, overlay: this.$overlay, toggler: this.$languageSwitcherToggler })
    this.menu = new Menu({ el: this.$menu, overlay: this.$overlay, toggler: this.$menuToggler })
    /* eslint-enable object-property-newline */

    this.getBounds()
  }

  getBounds() {
    const { width: buttonArrowWrapperWidth } = this.$buttonArrowsWrapper.getBoundingClientRect()
    const { height: buttonArrowHeight } = this.$buttonArrows[0].getBoundingClientRect()

    this.buttonArrowTranslateXValue = (buttonArrowWrapperWidth + buttonArrowHeight) / 2
  }

  events() {
    this.$button.addEventListener('mouseenter', this.onButtonEnter)
  }

  onButtonEnter() {
    gsap.killTweensOf(this.$buttonArrows)

    gsap.fromTo(this.$buttonArrows, { x: 0 }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      x: () => this.buttonArrowTranslateXValue
    })
  }

  updateCSSVariables() {
    this.height = this.$el.offsetHeight

    document.body.style.setProperty('--header-height', `${this.height}px`)
    document.body.style.setProperty('--header-width', `${this.$el.offsetWidth}px`)
    document.body.style.setProperty('--header-button-width', `${this.$button.getBoundingClientRect().width}px`)
  }

  onPageChange({ location }) {
    this.menu.onPageChange({ location })
  }

  scroll() {
    if (this.$languageSwitcher && this.languageSwitcher.isOpen || this.menu.isOpen || this.menu.panelItemsWrappers.flatMap((panelItemsWrapper) => panelItemsWrapper.items.filter((item) => item.submenu && item.submenu.isOpen)).length) return

    const isAtBottom = store.w.h + store.currentScroll >= store.smoothScroll.dimensions.scrollHeight
    const isScrollingUp = store.currentScroll < this.lastScroll
    const isScrollingDown = store.currentScroll > this.lastScroll

    if (this.isHidden) {
      if (isAtBottom || isScrollingUp && this.lastScroll - store.currentScroll > this.scrollUpOffset) {
        this.show()
        this.lastScroll = store.currentScroll
      }
    } else if (isScrollingDown && store.currentScroll > this.lastScroll + this.scrollDownOffset) {
      this.hide()
      this.lastScroll = store.currentScroll
    }

    if (isScrollingUp && !this.isHidden || isScrollingDown && this.isHidden) this.lastScroll = store.currentScroll
  }

  hide () {
    this.isHidden = true

    /* eslint-disable object-property-newline */
    this.hidingAnimation = gsap.timeline({ defaults: { duration: 0.6, ease: 'expo.out' }})
    /* eslint-enable object-property-newline */

    store.cancelAnimation(this.showingAnimation)

    this.hidingAnimation.to(this.$el, { y: - this.height - store.remToPixel(store.w.w < 1024 ? 1 : 1.5) - 1 })

    window.dispatchEvent(new CustomEvent('headerIsHiding'))
  }

  show() {
    this.isHidden = false

    /* eslint-disable object-property-newline */
    this.showingAnimation = gsap.timeline({ defaults: { duration: 0.6, ease: 'expo.out' }})
    /* eslint-enable object-property-newline */

    store.cancelAnimation(this.hidingAnimation)

    this.showingAnimation.to(this.$el, { y: 0 })

    window.dispatchEvent(new CustomEvent('headerIsShowing'))
  }

  resize() {
    this.menu.resize()
    this.getBounds()
    this.updateCSSVariables()

    if (this.$languageSwitcher && this.languageSwitcher.isOpen || this.menu.isOpen || this.menu.panelItemsWrappers.flatMap((panelItemsWrapper) => panelItemsWrapper.items.filter((item) => item.submenu && item.submenu.isOpen)).length) return

    this.isHidden && gsap.set(this.$el, { y: - this.height - store.remToPixel(store.w.w < 1024 ? 1 : 1.5) - 1 })
  }
}
