import store from './store'

export default class Anchor {
  constructor({ scrollDuration, targetEl = null, targetScrollOffset = null, targetScrollPosition = null, triggerEl }) {
    this.scrollDuration = scrollDuration
    this.targetEl = targetEl
    this.targetScrollOffset = targetScrollOffset
    this.targetScrollPosition = targetScrollPosition
    this.$triggerEl = triggerEl

    this.bindMethods()
    this.events()
  }

  bindMethods() {
    this.onTriggerElClick = this.onTriggerElClick.bind(this)
  }

  events() {
    this.$triggerEl.addEventListener('click', this.onTriggerElClick)
  }

  onTriggerElClick() {
    if (this.targetEl) this.targetScrollPosition = this.targetEl.getBoundingClientRect().top - this.targetScrollOffset + store.smoothScroll.scroll

    store.smoothScroll.scrollTo(this.targetScrollPosition, { duration: this.scrollDuration })

    if (this.targetEl) this.targetScrollPosition = null
  }
}
