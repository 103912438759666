import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class Dropdown extends Block {
  bindMethods() {
    this.onHeaderClick = this.onHeaderClick.bind(this)
    this.onHeaderEnter = this.onHeaderEnter.bind(this)
    this.onHeaderLeave = this.onHeaderLeave.bind(this)
  }

  getElems() {
    this.$arrows = this.$el.querySelectorAll('.dropdown__arrow')
    this.$content = this.$el.querySelector('.dropdown__content')
    this.$header = this.$el.querySelector('.dropdown__header')
    this.$icon = this.$el.querySelector('.dropdown__icon')
  }

  init() {
    this.isHovering = false

    store.dropdowns.push(this)

    this.getBounds()
    this.createAnimation()
  }

  getBounds() {
    const { height } = this.$icon.getBoundingClientRect()
    const { height: arrowHeight } = this.$arrows[0].getBoundingClientRect()

    this.translateYValue = (height * 0.75 + arrowHeight) / 2
  }

  createAnimation() {
    this.animation = gsap.timeline({
      paused: true
    })

    this.animation
      .fromTo(this.$arrows, {
        y: 0
      }, {
        duration: 0.8,
        ease: 'expo.out',
        stagger: 0.15,
        y: () => this.translateYValue
      })
  }

  events() {
    this.$header.addEventListener('click', this.onHeaderClick)
    this.$header.addEventListener('mouseenter', this.onHeaderEnter)
    this.$header.addEventListener('mouseleave', this.onHeaderLeave)
  }

  onHeaderClick() {
    this.isOpen ? this.close() : this.open()
  }

  close() {
    gsap.to(this.$content, {
      duration: 0.6,
      ease: 'expo.out',
      height: 0,
      onComplete: () => {
        window.dispatchEvent(new CustomEvent('dropdownResize'))
      },
      onStart: () => {
        this.isOpen = false
        this.$el.classList.remove('active')
      }
    })
  }

  open(force = false) {
    store.dropdowns.filter((activeDropdown) => activeDropdown !== this && activeDropdown.isOpen).forEach((activeDropdown) => activeDropdown.close())

    this.isOpen = true

    this.$content.style.height = 'auto'

    const height = `${this.$content.clientHeight}px`

    this.$content.style.height = '0px'

    this.$el.classList.add('active')

    this.openingAnimation = gsap.timeline()

    if (force) {
      this.openingAnimation.set(this.$content, { height })
      window.dispatchEvent(new CustomEvent('dropdownResize'))
    } else {
      this.openingAnimation.to(this.$content, {
        duration: 0.6,
        ease: 'expo.out',
        height,
        onComplete: () => {
          window.dispatchEvent(new CustomEvent('dropdownResize'))
        }
      })
    }
  }

  onHeaderEnter() {
    if (store.detect.isMobile) return

    this.isHovering = true

    this.animation.play(this.animation.time() === this.animation.totalDuration() ? 0 : this.animation.time())
  }

  onHeaderLeave() {
    if (store.detect.isMobile) return
    
    this.isHovering = false
  }

  resize() {
    this.getBounds()
    this.createAnimation()

    if (!this.isOpen) return

    this.open(true)
  }

  destroy() {
    const index = store.dropdowns.indexOf(this)

    index > -1 && store.dropdowns.splice(index, 1)
  }
}
