import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import store from '../util/store'

export default class MobileTransition extends Transition {

  /**
   * Handle the transition leaving the previous page.
   * @param {HTMLElement} from HTML element of the previous page
   * @param {string|HTMLElement|false} trigger Element that trigger page transition
   * @param {function} done Promise resolver
   */
  onLeave({ done }) {
    store.smoothScroll && store.smoothScroll.stop()

    const openedToggleManager = store.toggleManagers.find((toggleManager) => toggleManager.isOpen)
    const delay = openedToggleManager ? 0.2 : 0

    openedToggleManager && openedToggleManager.close()

    gsap.fromTo(store.fade, { autoAlpha: 0 }, {
      autoAlpha: 1,
      delay,
      duration: 0.5,
      ease: 'beaucoup.alpha',
      onComplete: () => {
        store.smoothScroll && store.smoothScroll.scrollTo(0, {
          immediate: true,
          force: true
        })

        done()
      }
    })
  }

  /**
   * Handle the transition entering the next page.
   * @param {HTMLElement} to HTML element of the current page
   * @param {string|HTMLElement|false} trigger Element that trigger page transition
   * @param {function} done Promise resolver
   */
  onEnter({ done }) {
    store.smoothScroll && store.smoothScroll.start()

    const tl = gsap.timeline()

    tl
      .to(store.fade, {
        autoAlpha: 0,
        duration: 0.85,
        ease: 'beaucoup.alpha'
      }, 0)
      .call(() => {
        done()
      }, [], 0.1)
  }
}
