import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class TechnicalDocuments extends Block {
  getElems() {
    this.$documents = this.$el.querySelectorAll('.technical-documents__document')

    if (this.$documents.length) {
      this.documents = []

      for (let i = 0; i < this.$documents.length; i++) {
        const el = this.$documents[i]
        
        this.documents.push({
          el,
          icons: el.querySelectorAll('.technical-documents__icon'),
          iconsWrapper: el.querySelector('.technical-documents__icons-wrapper')
        })
      }
    }
  }

  init() {
    if (this.$documents.length) this.getBounds()
  }

  getBounds() {
    const { height } = this.documents[0].iconsWrapper.getBoundingClientRect()
    const { height: iconHeight } = this.documents[0].icons[0].getBoundingClientRect()

    this.iconTranslateYValue = (height * 0.75 + iconHeight) / 2 + 1
  }

  events() {
    if (this.$documents.length) for (let i = 0; i < this.documents.length; i++) this.documents[i].el.addEventListener('mouseenter', this.onDocumentEnter.bind(this, i))
  }

  onDocumentEnter(index) {
    if (store.detect.isMobile) return

    gsap.killTweensOf(this.documents[index].icons)

    gsap.fromTo(this.documents[index].icons, { y: 0 }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      y: () => this.iconTranslateYValue
    })
  }

  resizeX() {
    if (this.$documents.length) {
      this.getBounds()

      for (let i = 0; i < this.documents.length; i++) gsap.set(this.documents[i].icons, { y: 0 })
    }
  }
}
