import store from './store'

export default class Mousemove {
  constructor({ el, cb, mobile = false }) {
    this.cb = cb
    this.mobile = mobile
    this.mouse = {
      x: 0,
      y: 0
    }

    // eslint-disable-next-line
    this.el = typeof el === 'string' ? document.querySelector(el) : (el === undefined ? document : el)
    this.run = this.run.bind(this)
  }

  on() {
    this.listener('add')
  }

  off() {
    this.listener('remove')
  }

  listener(a) {
    if (this.mobile && store.detect.isMobile) this.el[a + 'EventListener']('touchmove', this.run)
    else this.el[a + 'EventListener']('mousemove', this.run)
  }

  run(e) {
    const x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX
    const y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY

    this.mouse.x = x
    this.mouse.y = y

    this.cb(x, y, e)
  }
}
