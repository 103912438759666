export default class Block {
  constructor(el, destroyLast) {
    this.$el = el
    this.destroyLast = destroyLast

    this.bindMethods()
    this.getElems()
    this.init()
    this.events()
  }

  onEnterCompleted() {}

  bindMethods() {}

  appear() {}

  getElems() {}

  init() {}

  events() {}

  destroy() {}

  resize() {}

  resizeX() {}

  scroll() {}

  scrollEnd() {}

  update() {}
}
