/* eslint-disable max-lines */
import Block from './Block';
import { gsap } from 'gsap';
import store from '../util/store';
import Splitting from 'splitting'
import SimulatorTimeline from '../util/simulator/Timeline';
import SimulatorResult from '../util/simulator/Result';

export default class Simulator extends Block {
  bindMethods() {
    this.hideError = this.hideError.bind(this)
    this.prevStep = this.prevStep.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.updateStep = this.updateStep.bind(this)
    this.restart = this.restart.bind(this)
  }

  getElems() {
    this.$documents = this.$el.querySelectorAll('.simulator__document')

    if (this.$documents.length) {
      this.documents = []

      for (let i = 0; i < this.$documents.length; i++) {
        const el = this.$documents[i]
        
        this.documents.push({
          el,
          icons: el.querySelectorAll('.simulator__icon'),
          iconsWrapper: el.querySelector('.simulator__icons-wrapper')
        })
      }
    }

    this.$steps = this.$el.querySelectorAll('.simulator__step')
    this.$previous = this.$el.querySelector('.simulator__previous')
    this.$startsOver = this.$el.querySelectorAll('.simulator__start-over')
    this.$positiveResult = this.$el.querySelector('.simulator__result-positive')
    this.$negativeResult = this.$el.querySelector('.simulator__result-negative')
    this.$bgShape = this.$el.querySelector('.simulator__bg-shape')
    this.$timeline = this.$el.querySelector('.simulator__timeline')
  }

  init() {
    this.currentStep = 0
    this.errorShown = false
    this.previousShown = false
    this.responses = {}

    this.timeline = new SimulatorTimeline({
      el: this.$timeline
    })

    this.result = new SimulatorResult({
      positive: this.$positiveResult,
      negative: this.$negativeResult
    })

    this.formatElems()
    if (this.$documents.length) this.getBounds()
    this.prepareAnimation()
  }

  appear() {
    for (let i = 0; i < this.steps[this.currentStep].splittedQuestion.length; i++) {
      gsap.to(this.steps[this.currentStep].splittedQuestion[i], {
        alpha: 1,
        delay: 0.5 + i * 0.05,
        duration: 0.75,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      })
    }

    gsap.to([this.steps[this.currentStep].surtitle, this.steps[this.currentStep].answer], {
      alpha: 1,
      delay: 0.75,
      duration: 0.5,
      ease: 'beaucoup.alpha',
      stagger: 0.025
    })
  }

  formatElems() {
    this.steps = []

    this.$steps.forEach((step) => {
      const question = step.querySelector('.simulator__step__question')
      const subtitle = step.querySelector('.simulator__step__subtitle')
      const answer = step.querySelector('.simulator__step__answer')
      const type = answer.dataset.type
      const category = step.dataset.category
      const surtitle = type === 'start' ? step.querySelector('.simulator__step__intro-content') : step.querySelector('.simulator__step__surtitle')
      const error = step.querySelector('.simulator__step__error')

      const obj = {
        el: step,
        category,
        surtitle,
        question,
        subtitle,
        answer,
        type
      }

      if (answer.hasAttribute('data-mandatory')) obj.mandatory = answer.dataset.mandatory
      if (error) {
        obj.error = error
        obj.errorClose = error.querySelector('.simulator__step__error__close')
      }

      if (type === 'bool' || type === 'multiple') {
        const possibilitiesEl = answer.querySelectorAll('.simulator__step__answer-possibility')
        const possibilities = []

        possibilitiesEl.forEach((possibility) => {
          possibilities.push({
            el: possibility,
            value: possibility.dataset.value
          })
        })

        obj.possibilities = possibilities
      }

      if (type === 'number') {
        const submit = answer.querySelector('.simulator__step__answer-submit')
        const input = answer.querySelector('.simulator__step__answer-input')

        obj.submit = submit
        obj.input = input
      }

      this.steps.push(obj)
    })

    // console.log(this.steps);
  }
  
  getBounds() {
    const { height } = this.documents[0].iconsWrapper.getBoundingClientRect()
    const { height: iconHeight } = this.documents[0].icons[0].getBoundingClientRect()

    this.iconTranslateYValue = (height * 0.75 + iconHeight) / 2 + 1
  }

  events() {
    if (this.$documents.length) for (let i = 0; i < this.documents.length; i++) this.documents[i].el.addEventListener('mouseenter', this.onDocumentEnter.bind(this, i))
    this.$previous.addEventListener('click', this.prevStep)
    this.$startsOver.forEach((btn) => {
      btn.addEventListener('click', this.restart)
    })
    this.steps.forEach((step) => {
      switch (step.type) {
        case 'start':
          step.answer.addEventListener('click', this.handleAnswer.bind(this, false))
          break
        case 'bool':
          if (step.error) step.errorClose.addEventListener('click', this.hideError)
          step.possibilities.forEach((possibility) => {
            possibility.el.addEventListener('click', this.handleAnswer.bind(this, possibility))
          })
          break
        case 'multiple':
          step.possibilities.forEach((possibility) => {
            possibility.el.addEventListener('click', this.handleAnswer.bind(this, possibility))
          })
          break
        case 'number':
          step.input.addEventListener('input', this.handleSubmitShowing.bind(this))
          step.submit.addEventListener('click', this.handleAnswer.bind(this, false))
          break
        default:
          break
      }
    })
  }

  onDocumentEnter(index) {
    if (store.detect.isMobile) return

    gsap.killTweensOf(this.documents[index].icons)

    gsap.fromTo(this.documents[index].icons, { y: 0 }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      y: () => this.iconTranslateYValue
    })
  }

  handleAnswer(possibility) {
    const step = this.steps[this.currentStep]

    switch (step.type) {
      case 'start':
      case 'multiple':
        if (possibility) possibility.el.classList.add('active')
        if (step.mandatory) this.responses[step.mandatory] = possibility.value
        this.nextStep()

        break
      case 'bool':
        if (possibility) {
          possibility.el.classList.add('active')
          if (step.mandatory) this.responses[step.mandatory] = possibility.value
          if (possibility.value === 'no' && !step.mandatory) !this.errorShown && this.showError()
          else {
            this.nextStep()
          }
        }
        break
      case 'number':
        if (step.mandatory) this.responses[step.mandatory] = step.input.value
        this.nextStep()
        break
      default:
        break
    }
  }

  prevStep() {
    this.errorShown && this.hideError()

    const fromStep = this.currentStep

    if (this.currentStep > 0) {
      this.currentStep--
      this.updateStep(fromStep)
    }
  }

  nextStep() {
    this.errorShown && this.hideError()

    if (this.currentStep === this.steps.length - 1) {
      this.timeline.ending()

      gsap.to(this.steps[this.currentStep].el, {
        alpha: 0,
        pointerEvents: 'none'
      })

      const animation = gsap.timeline()

      animation
        .set(this.steps[this.currentStep].el, { pointerEvents: 'none' })
        .to(this.steps[this.currentStep].splittedQuestion, {
          alpha: 0,
          stagger: 0.0125
        })
        .to([this.steps[this.currentStep].surtitle, this.steps[this.currentStep].answer], { alpha: 0 }, '<')

      animation.set(this.steps[this.currentStep].el, { alpha: 0 })

      this.result.show(this.responses)

      return
    }

    const fromStep = this.currentStep

    this.currentStep++
    this.updateStep(fromStep)
  }

  updateStep(fromStep = false) {
    this.timeline && this.timeline.updateStep(this.currentStep, fromStep)

    this.nextAnimation && store.cancelAnimation(this.nextAnimation)
    this.bgAnimation && store.cancelAnimation(this.bgAnimation)

    this.nextAnimation = gsap.timeline({
      defaults: {
        ease: 'beaucoup.alpha'
      }
    })
    this.bgAnimation = gsap.timeline()

    // BG Animation
    if (this.currentStep === 0) {
      this.previousShown = false
      this.bgAnimation.to(this.$bgShape, {
        scale: 1,
        yPercent: 15,
        duration: 1.5,
        ease: 'expo.inOut'
      })
    } else if (fromStep === 0) {
      this.previousShown = true
      this.bgAnimation.to(this.$bgShape, {
        scale: 1.84,
        yPercent: -105,
        duration: 1.5,
        ease: 'expo.inOut'
      })
    } else this.previousShown = true

    // From a question to another, hide previous question
    if (fromStep !== false) {
      this.nextAnimation
        .set(this.steps[fromStep].el, { pointerEvents: 'none' })

      this.steps[fromStep].splittedQuestion.forEach((lineCharacters, i) => {
        this.nextAnimation.to(lineCharacters, {
          alpha: 0,
          stagger: 0.0125,
          duration: 0.25
        }, `<${i * 0.05}`)
      })

      if (this.steps[fromStep].subtitle) {
        this.steps[fromStep].splittedSubtitle.forEach((lineCharacters, i) => {
          this.nextAnimation.to(lineCharacters, {
            alpha: 0,
            stagger: 0.0125,
            duration: 0.25
          }, `<${i * 0.05}`)
        })
      }

      this.nextAnimation.to([this.steps[fromStep].surtitle, this.steps[fromStep].answer], { alpha: 0 }, '<')

      if (this.steps[fromStep].type === 'number') this.nextAnimation.to(this.steps[fromStep].submit, {
        alpha: 0,
        pointerEvents: 'none',
        ease: 'beaucoup.alpha'
      }, '<')

      this.nextAnimation.set(this.steps[fromStep].el, { alpha: 0 })
    }

    // Show next step
    this.nextAnimation
      .set(this.steps[this.currentStep].el, {
        alpha: 1,
        pointerEvents: 'auto'
      })

    this.steps[this.currentStep].splittedQuestion.forEach((lineCharacters, i) => {
      this.nextAnimation.to(lineCharacters, {
        alpha: 1,
        stagger: 0.0125,
        duration: 0.25
      }, `<${i * 0.05}`)
    })

    if (this.steps[this.currentStep].subtitle) {
      this.steps[this.currentStep].splittedSubtitle.forEach((lineCharacters, i) => {
        this.nextAnimation.to(lineCharacters, {
          alpha: 1,
          stagger: 0.0125,
          duration: 0.25
        }, `<${i * 0.05}`)
      })
    }

    if (this.steps[this.currentStep].type === 'number' && this.responses.space) this.nextAnimation.to(this.steps[this.currentStep].submit, {
      alpha: 1,
      pointerEvents: 'auto',
      ease: 'beaucoup.alpha'
    }, '<')

    this.nextAnimation
      .to(this.steps[this.currentStep].surtitle, { alpha: 1 }, '<')
      .to(this.steps[this.currentStep].answer, { alpha: 1 }, '<')
      .to(this.$previous, {
        alpha: this.previousShown ? 1 : 0,
        pointerEvents: this.previousShown ? 'auto' : 'none'
      }, '<')
  }

  hideError() {
    this.errorShown = false

    gsap.to(this.steps[this.currentStep].error, { alpha: 0 })

    this.steps[this.currentStep].error.classList.add('pointer-events-none')
  }

  showError() {
    this.errorShown = true

    gsap.to(this.steps[this.currentStep].error, { alpha: 1 })

    this.steps[this.currentStep].error.classList.remove('pointer-events-none')
  }

  handleSubmitShowing(e) {
    if (e.target.value !== '' || e.target.value !== 0) {

      gsap.to(this.steps[this.currentStep].submit, {
        alpha: 1,
        pointerEvents: 'auto'
      })
    }
  }

  restart() {
    this.currentStep = 0
    this.responses = {}

    this.steps.forEach((step) => {
      if (step.type === 'number') step.input.value = ''
    })

    this.timeline.restart()
    this.result.restart()
    this.updateStep()
  }

  prepareAnimation() {
    // Questions
    this.steps.forEach((step, i) => {
      step.splittedQuestion = this.splitTitle(step.question)

      step.splittedQuestion.forEach((lineCharacters) => {
        gsap.set(lineCharacters, { alpha: 0 })
      })

      if (step.subtitle) {
        step.splittedSubtitle = this.splitTitle(step.subtitle)

        step.splittedSubtitle.forEach((lineCharacters) => {
          gsap.set(lineCharacters, { alpha: 0 })
        })
      }

      gsap.set([step.answer, step.surtitle], { alpha: 0 })

      if (i !== this.currentStep) gsap.set(step.el, {
        alpha: 0,
        pointerEvents: 'none'
      })

      if (this.currentStep === 0) gsap.set(this.$previous, {
        alpha: 0,
        pointerEvents: 'none'
      })

      step.error && gsap.set(step.error, {
        alpha: 0
      })

      if (step.type === 'number') gsap.set(step.submit, {
        alpha: 0,
        pointerEvents: 'none'
      })
    })

    // Background
    if (this.currentStep === 0) {
      gsap.set(this.$bgShape, {
        yPercent: 15,
        scale: 1
      })
    } else {
      gsap.set(this.$bgShape, {
        yPercent: -105,
        scale: 1.84
      })
    }
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharacters = []

    lines.forEach((line, i) => {
      linesCharacters[i] = []

      /* eslint-disable object-property-newline */
      Splitting({ by: 'chars', target: line }).forEach((lineCharacters) => {
        linesCharacters[i].push(...lineCharacters.chars)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharacters
  }

  resizeX() {
    if (this.$documents.length) {
      this.getBounds()

      for (let i = 0; i < this.documents.length; i++) gsap.set(this.documents[i].icons, { y: 0 })
    }

    this.timeline && this.timeline.resizeX()
  }
}
/* eslint-enable max-lines */
