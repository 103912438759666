import Block from './Block'

export default class Footer extends Block {
  bindMethods() {
    this.onCookiesClick = this.onCookiesClick.bind(this)
  }
  
  getElems() {
    this.$cookies = this.$el.querySelector('.footer__cookies')
    this.$sections = this.$el.querySelectorAll('.footer__section')
  }

  init() {
    this.sections = []
    this.activeSectionIndex = -1
    this.activeLinkIndex = -1

    this.$sections.forEach((section) => {
      this.sections.push({
        section,
        links: section.querySelectorAll('.footer__link')
      })
    })

    this.onPageChange({ location: window.location })
  }

  onPageChange({ location }) {
    if (this.activeSectionIndex > -1 && this.activeLinkIndex > -1) {
      this.sections[this.activeSectionIndex].section.classList.remove('a')
      this.sections[this.activeSectionIndex].links[this.activeLinkIndex].classList.remove('a')
    }

    this.sections.forEach((section, i) => {
      section.links.forEach((link, j) => {
        if (link.href === location.href) {
          this.activeSectionIndex = i
          this.activeLinkIndex = j
        }
      })
    })

    if (this.activeSectionIndex > -1 && this.activeLinkIndex > -1) {
      this.sections[this.activeSectionIndex].section.classList.add('a')
      this.sections[this.activeSectionIndex].links[this.activeLinkIndex].classList.add('a')
    }
  }

  events() {
    this.$cookies.addEventListener('click', this.onCookiesClick)
  }

  onCookiesClick() {
    if (!window.tarteaucitron) return

    window.tarteaucitron.userInterface.openPanel()
  }
}
