import LazyLoad from 'vanilla-lazyload'
import { Core } from '@unseenco/taxi'
import { throttle, debounce } from 'throttle-debounce'
import store from './util/store'
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import Splitting from 'splitting'

// Utils
import Header from './util/Header'
import Loader from './util/Loader'
// import-gl

// Routes
import Page from './routes/Page'

// Transitions
import GlobalTransition from './transitions/GlobalTransition'

export default class Site {
  /**
   * Loads the page
   * then calls `this.start()` when DOM is ready
   */
  constructor() {
    this.resize = this.resize.bind(this)
    this.scroll = this.scroll.bind(this)
    this.update = this.update.bind(this)

    this.resizeDebounced = debounce(100, this.resize)
    this.resizeThrottled = throttle(100, this.resize)
    this.scrollDebounced = debounce(100, this.scroll)
    this.scrollThrottled = throttle(30, this.scroll)

    // Init CustomEase
    gsap.registerPlugin(CustomEase)
    gsap.registerPlugin(ScrollTrigger)

    CustomEase.create('beaucoup.alpha', '.25, .46, .45, .9')
    
    store.scrollTrigger = ScrollTrigger

    store.w = {
      w: window.innerWidth,
      h: window.innerHeight
    }

    store.paddingContainer = parseInt(getComputedStyle(document.body).getPropertyValue('--padding-container'))
    store.columnSize = (store.pixelToRem(store.w.w) - parseFloat(store.paddingContainer) * 2 - (24 + 1)) / 24

    this.start()
  }

  /**
   * Inits everything that is app-wide
   * ie: Menu, scroll / resize events...
   *
   * @returns {void}
   */
  start() {
    // init-gl
    store.fade = document.querySelector('.fade')

    history.scrollRestoration && (history.scrollRestoration = 'manual')

    document.documentElement.style.setProperty('--vh-initial', `${store.w.h / 100}px`)
    
    store.header = new Header()
    
    this.addCustomSplitting()
    this.initSmoothScroll()
    this.initScrollTrigger()
    this.initTaxi()

    this.loader = new Loader()
    this.lazyLoad = new LazyLoad()

    this.events()
    this.updateLinks()
    this.addConsoleBrand()

    // gsap.ticker.fps(60)
    // gsap.ticker.add(this.update)

    requestAnimationFrame(this.update)
  }

  addCustomSplitting() {
    Splitting.add({
      by: 'charactersWrappers',
      key: 'charactersWrappers',
      depends: ['chars'],
      split: (target, _, split) => {
        const characters = []
        const charactersWrappers = []
        const elements = []

        target.innerHTML = ''

        split.chars.forEach((char) => {
          const charWrapper = document.createElement('span')
          const charElement = document.createElement('span')

          charElement.classList.add('char')
          charElement.dataset.char = char.innerText
          charElement.innerText = char.innerText
          charWrapper.appendChild(charElement)

          target.appendChild(charWrapper)

          characters.push(charElement)
          charactersWrappers.push(charWrapper)
        })

        elements.characters = characters
        elements.charactersWrappers = charactersWrappers

        return elements
      }
    })

    Splitting.add({
      by: 'linesChars',
      key: 'linesChars',
      depends: ['lines', 'chars'],
      split: (target, _, split) => {
        const elements = []

        target.innerHTML = ''

        split.lines.forEach((line, lineIndex) => {
          const lineElement = document.createElement('div')

          lineElement.classList.add('w-line')

          line.forEach((word, wordIndex) => {
            const chars = word.innerText.split('')

            word.innerHTML = chars.map((char) => {
              const charElement = document.createElement('span')

              charElement.classList.add('char')
              charElement.dataset.char = char
              charElement.innerText = char

              return charElement.outerHTML
            }).join('')

            lineElement.appendChild(word)

            if (wordIndex < line.length - 1) {
              const whitespace = document.createElement('span')

              whitespace.classList.add('whitespace')
              whitespace.innerHTML = ' '
              lineElement.appendChild(whitespace)
            }
          })

          target.appendChild(lineElement)

          elements[lineIndex] = lineElement
        })

        return elements
      }
    })
  }

  initSmoothScroll() {
    if (store.smoothScroll) store.smoothScroll.destroy()

    store.smoothScrollContent = document.body.querySelector('.lenis-content')
    store.smoothScrollWrapper = document.body.querySelector('.lenis-wrapper')

    store.smoothScroll = new Lenis({
      content: store.smoothScrollContent,
      duration: 0.8,
      easing: (t) => 1 - Math.pow(1 - t, 5),
      wheelEventsTarget: document.body,
      wrapper: store.smoothScrollWrapper
    })

    window.lenis = store.smoothScroll

    store.smoothScroll.on('scroll', (e) => {
      this.scroll(e)
    })
  }

  initScrollTrigger() {
    store.scrollTrigger.scrollerProxy(store.smoothScrollWrapper, {
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight
        }
      },
      scrollTop() {
        return store.smoothScroll.scroll
      }
    })
  
    store.scrollTrigger.defaults({
      scroller: store.smoothScrollWrapper
    })
  }

  initTaxi() {
    this.taxi = new Core({
      links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
      reloadJsFilter: (element) => element.dataset.taxiReload !== undefined,
      transitions: {
        default: GlobalTransition
      },
      renderers: {
        default: Page
      }
    })

    this.currentRenderer = this.taxi.currentCacheEntry.renderer
  }

  reloadTartaucitronServices() {
    if (!window.tarteaucitron) return

    const allowBtns = document.body.querySelectorAll('.tarteaucitronAllow')

    for (let i = 0; i < allowBtns.length; i++) {
      window.tarteaucitron.addClickEventToElement(allowBtns[i], function() {
        // eslint-disable-next-line
        window.tarteaucitron.userInterface.respond(this, true)
      })
    }
  }

  resize() {
    const windowWidth = window.innerWidth
    const xDifferent = windowWidth !== store.w.w

    store.w = {
      w: window.innerWidth,
      h: window.innerHeight
    }

    store.paddingContainer = parseInt(getComputedStyle(document.body).getPropertyValue('--padding-container'))
    store.columnSize = (store.pixelToRem(store.w.w) - parseFloat(store.paddingContainer) * 2 - (24 + 1)) / 24

    if (store.detect.isMobile && xDifferent) {
      this.resizeX()

      this.currentRenderer.resize()
      store.header.resize()
    } else if (!store.detect.isMobile) {
      xDifferent && this.resizeX()

      this.currentRenderer.resize()
      store.header.resize()

      store.scrollTrigger.refresh()
    }
  }

  resizeX() {
    document.documentElement.style.setProperty('--vh-initial', `${store.w.h / 100}px`)

    this.currentRenderer.resizeX()
  }

  scroll(e) {
    store.currentScroll = store.smoothScroll.scroll

    this.currentRenderer.scroll(e)
    store.header.scroll()
  }

  update(time) {
    // begin-fps
    // const t = time * 1000

    store.smoothScroll && store.smoothScroll.raf(time)
    // store.scrollTrigger.update()
    this.currentRenderer.loop()
    requestAnimationFrame(this.update)
    // update-gl
    // end-fps
  }

  events() {
    window.addEventListener('resize', this.resizeDebounced)
    window.addEventListener('orientationchange', this.resize)
    window.addEventListener('wheel', this.scrollThrottled)
    window.addEventListener('wheel', this.scrollDebounced)

    this.taxi.on('NAVIGATE_IN', ({ to }) => {
      this.currentRenderer = to.renderer
      store.header.onPageChange({ location })
    })

    this.taxi.on('NAVIGATE_END', ({ to }) => {
      this.updateTracking(to)
      this.reloadTartaucitronServices()
      this.updateLinks()
      this.updateLanguages(to)
      this.lazyLoad.update()
    })
  }

  updateTracking(to) {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname,
        page_title: to.page.title,
        page_location: window.location.href
      })
    }
  }

  updateLinks() {
    this.links = document.body.querySelectorAll('a')
    if (!this.siteUrl) this.siteUrl = 'http://caeli-energie.localhost/'

    for (let i = 0; i < this.links.length; i++) {
      const link = this.links[i]

      if (link.href.indexOf(this.siteUrl) > -1) link.href = `${window.location.origin}/${link.href.split(this.siteUrl)[1]}`
    }
  }

  updateLanguages(to) {
    const newLanguagesLinks = to.page.body.querySelectorAll('.language-switcher__item a')
    const languagesLinks = document.querySelectorAll('.language-switcher__item a')

    for (let i = 0; i < newLanguagesLinks.length; i++) languagesLinks[i].href = newLanguagesLinks[i].href
  }

  addConsoleBrand() {
    console.log('\n %c ✦ Merci Beaucoup ✦ ', 'background: #00F; color: #fffaf5; padding: 5px 0; margin-right: 5px;', 'https://beaucoup.studio/ \n\n')
  }
}
