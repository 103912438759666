import gsap from 'gsap'
import store from './store'
import Mousemove from './MouseMove'

export default class ArrowDragSlider {
  constructor({ el }) {
    this.$el = el

    this.bindMethods()
    this.getElems()
    this.events()
    this.init()
  }

  init() {
    this.drag = {
      target: 0,
      current: 0,
      currentR: 0,
      start: 0,
      end: 0,
      max: 0
    }

    this.isHover = false
    this.currentIndex = 0
    this.currentDragIndex = 0
    this.activeIndex = 0

    if (!store.detect.isMobile) {
      this.mm = new Mousemove({ cb: this.move })
    }
  }

  bindMethods() {
    this.down = this.down.bind(this)
    this.up = this.up.bind(this)
    this.move = this.move.bind(this)
    this.onLeftClick = this.onLeftClick.bind(this)
    this.onRightClick = this.onRightClick.bind(this)
    this.checkActive = this.checkActive.bind(this)
  }

  getElems() {
    this.$slider = this.$el.querySelector('.slider__wrapper')
    this.$items = this.$el.querySelectorAll('.slider__item')
    this.$last = this.$el.querySelector('.slider__item:last-child')
    this.$leftArrow = this.$el.querySelector('.arrow-slider__left')
    this.$rightArrow = this.$el.querySelector('.arrow-slider__right')
  }

  events() {
    if (!store.detect.isMobile) {
      this.$el.addEventListener('mousedown', this.down)
      this.$el.addEventListener('mouseup', this.up)
      this.$el.addEventListener('mouseleave', this.up)
    }


    this.$leftArrow.addEventListener('click', this.onLeftClick)
    this.$rightArrow.addEventListener('click', this.onRightClick)
  }

  appear() {
    this.setup()
    this.mm && this.mm.on()
  }

  destroy() {
    this.mm && this.mm.off()
  }

  setup() {
    this.drag.target = 0
    this.drag.current = 0
    this.drag.currentR = 0
    this.drag.end = 0

    this.max = this.$last.getBoundingClientRect().width
    this.$slider.style.transform = 'translate3d(0, 0, 0)'
    this.drag.max = this.$last.getBoundingClientRect().right - this.$slider.getBoundingClientRect().right

    this.items = []
    this.$items.forEach((item) => {
      const rect = item.getBoundingClientRect()

      this.items.push({
        left: rect.left,
        width: rect.width
      })
    })
  }

  move(x) {
    if (this.isDown) {
      if (!this.isMoving) {
        this.onDrag()
        this.isMoving = true
      }
      
      this.drag.target = store.round(this.drag.end + x - this.drag.start)
      this.drag.target = gsap.utils.clamp(-this.drag.max, 0, this.drag.target)
    }
  }

  onDrag() {
    this.$el.classList.add('a')
  }

  down(e) {
    this.isDown = true
    this.drag.start = e.changedTouches ? e.changedTouches[0].clientX : e.clientX
  }

  up() {
    this.isMoving = false
    this.isDown = false
    this.drag.end = this.drag.target
    this.$el.classList.remove('a')
  }

  onLeftClick() {
    this.checkActive()

    if (this.activeIndex > 0) {
      this.activeIndex--

      if (store.detect.isMobile || store.w.w < 1024) {
        this.$items[this.activeIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      } else {
        const left = this.items[this.activeIndex].left

        this.drag.target = - store.round(left) + store.remToPixel(store.paddingContainer)
        this.drag.target = gsap.utils.clamp(-this.drag.max, 0, this.drag.target)
        this.drag.end = this.drag.target
      }
    }
  }

  onRightClick() {
    this.checkActive()

    if (this.activeIndex !== this.$items.length - 1) {
      this.activeIndex++

      if (store.detect.isMobile || store.w.w < 1024) {
        this.$items[this.activeIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      } else {
        const left = this.items[this.activeIndex].left

        this.drag.target = - store.round(left) + store.remToPixel(store.paddingContainer)
        this.drag.target = gsap.utils.clamp(-this.drag.max, 0, this.drag.target)
        this.drag.end = this.drag.target
      }
    }
  }

  checkActive() {
    this.$items.forEach((item, i) => {
      const left = item.getBoundingClientRect().left

      if (left > 0 && left < this.max) this.activeIndex = i
    })
  }

  resize() {
    this.setup()
  }

  update() {
    this.needU = this.drag.target !== this.drag.currentR
    
    if (this.needU) {
      this.drag.current += (this.drag.target - this.drag.current) * 0.09
      this.drag.currentR = store.round(this.drag.current)
      this.$slider.style.transform = `translate3d(${this.drag.currentR}px, 0, 0)`
    }
  }
}
