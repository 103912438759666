import gsap from 'gsap'
import ToggleManager from './ToggleManager'
import store from './store'

export default class Submenu extends ToggleManager {
  constructor({ el, overlay, toggler }) {
    super(el, overlay, toggler, true)
  }

  getElems() {
    this.$panel = this.$el
    this.$panelBackground = this.$panel.querySelector('.submenu__background')
    this.$panelImages = this.$panel.querySelectorAll('.submenu__image')
    this.$panelItems = this.$panel.querySelectorAll('.submenu__item')
    this.$togglerCaret = this.$toggler.querySelector('.menu__item-caret')
  }

  init() {
    super.init()

    this.isTransitioning = false
    this.activePanelItemIndex = 0
    this.hoveredPanelItemIndex = 0
    this.mobileScreenWidthThreshold = 1024
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold

    this.prepareAnimation()
  }

  prepareAnimation() {
    this.isCurrentlyMobileScreen ? gsap.set(this.$el, { height: 0 }) : gsap.set(this.$panelBackground, { yPercent: -101 })
    gsap.set([this.$panelImages, this.$panelItems], { autoAlpha: 0 })
  }

  events() {
    super.events()

    for (let i = 0; i < this.$panelItems.length; i++) {
      this.$panelItems[i].addEventListener('click', this.onPanelItemClick.bind(this, i))
      this.$panelItems[i].addEventListener('mouseenter', this.onPanelItemEnter.bind(this, i))
      this.$panelItems[i].addEventListener('mouseleave', this.onPanelItemLeave.bind(this, i))
    }
  }

  onPanelItemClick() {
    this.isTransitioning = true
  }

  onPanelItemEnter(index) {
    if (index === this.hoveredPanelItemIndex || this.isTransitioning) return

    this.hoveredPanelItemIndex = index

    const enteringAnimation = gsap.timeline()

    gsap.killTweensOf(this.$panelImages[this.activePanelItemIndex])

    for (let i = 0; i < this.$panelImages.length; i++) {
      const opacity = i === index ? 1 : 0
      const scale = i === index ? 1.1 : 1

      /* eslint-disable object-property-newline */
      enteringAnimation
        .set(this.$panelImages[i], { autoAlpha: opacity }, 0)
        .fromTo(this.$panelImages[i], { scale }, { duration: 1.5, ease: 'expo.out', scale: 1 }, 0)
      /* eslint-enable object-property-newline */
    }
  }

  onPanelItemLeave(index) {
    if (this.isTransitioning) return

    this.hoveredPanelItemIndex = this.activePanelItemIndex

    if (index === this.hoveredPanelItemIndex) return
    
    const leavingAnimation = gsap.timeline()

    for (let i = 0; i < this.$panelImages.length; i++) {
      const opacity = i === this.activePanelItemIndex ? 1 : 0
      const scale = i === this.activePanelItemIndex ? 1.1 : 1

      /* eslint-disable object-property-newline */
      leavingAnimation
        .set(this.$panelImages[i], { autoAlpha: opacity }, 0)
        .fromTo(this.$panelImages[i], { scale }, { duration: 1.5, ease: 'expo.out', scale: 1 }, 0)
      /* eslint-enable object-property-newline */
    }
  }

  open() {
    super.open().then(() => {
      if (this.isCurrentlyMobileScreen) {
        this.$el.style.height = 'auto'

        const height = `${this.$el.clientHeight}px`
    
        this.$el.style.height = '0px'

        this.openingAnimation
          .to(this.$togglerCaret, { rotate: -180 })
          .to(this.$el, { height }, 0)
          .to([this.$panelItems, this.$panelImages[this.activePanelItemIndex]], {
            autoAlpha: 1,
            duration: 0.3,
            ease: 'none',
            stagger: 0.025
          }, 0.1)
      } else {
        this.openingAnimation
          .to(this.$togglerCaret, { rotate: -180 })
          .to(this.$panelBackground, { yPercent: 0 }, 0)
          .to([this.$panelItems, this.$panelImages[this.activePanelItemIndex]], {
            autoAlpha: 1,
            duration: 0.3,
            ease: 'none',
            stagger: 0.025
          }, 0.1)
      }

      this.$panel.classList.remove('pointer-events-none')
    })
  }

  close(force = false) {
    super.close(force)

    if (this.isCurrentlyMobileScreen) {
      this.closingAnimation
        .to(this.$togglerCaret, { rotate: 0 })
        .to(this.$el, { height: 0 }, 0)
        .to([this.$panelImages, this.$panelItems], {
          autoAlpha: 0,
          duration: force ? 0 : 0.1,
          ease: 'none'
        }, 0)
    } else {
      this.closingAnimation
        .to(this.$togglerCaret, { rotate: 0 })
        .to(this.$panelBackground, { yPercent: -101 }, 0.05)
        .to([this.$panelImages, this.$panelItems], {
          autoAlpha: 0,
          duration: force ? 0 : 0.1,
          ease: 'none'
        }, 0)
    }

    this.$panel.classList.add('pointer-events-none')
  }
}
