import AnchoredSections from './AnchoredSections'
import ArrowSlider from './ArrowSlider'
import Button from './Button'
import CardsSlider from './CardsSlider'
import ContentPageCover from './ContentPageCover'
import Dropdown from './Dropdown'
import EventsSlider from './EventsSlider'
import Footer from './Footer'
import FooterCredits from './FooterCredits'
import Form from './Form'
import FullScreenTitles from './FullScreenTitles'
import HomeCover from './HomeCover'
import ImageContent from './ImageContent'
import ImagesSlider from './ImagesSlider'
import KeyFigure from './KeyFigure'
import PageCover from './PageCover'
import ParallaxImage from './ParallaxImage'
import PreFooter from './PreFooter'
import ProductCard from './ProductCard'
import ProductCover from './ProductCover'
import ProductHighlights from './ProductHighlights'
import ProductPosts from './ProductPosts'
import RoundedIconButton from './RoundedIconButton'
import ScrollIndicator from './ScrollIndicator'
import Simulator from './Simulator'
import Slider from './Slider'
import TechnicalDocuments from './TechnicalDocuments'
import Testimonies from './Testimonies'
import TextualPaginationSlider from './TextualPaginationSlider'
import Title from './Title'
import UnderlinedLink from './UnderlinedLink'

const blockList = [
  {
    name: 'anchored-sections',
    Class: AnchoredSections
  },
  {
    name: 'content-page-cover',
    Class: ContentPageCover
  },
  {
    name: 'dropdown',
    Class: Dropdown
  },
  {
    name: 'events-slider',
    Class: EventsSlider
  },
  {
    name: 'form',
    Class: Form
  },
  {
    name: 'footer',
    Class: Footer
  },
  {
    name: 'footer-credits',
    Class: FooterCredits
  },
  {
    name: 'full-screen-titles',
    Class: FullScreenTitles
  },
  {
    name: 'home-cover',
    Class: HomeCover
  },
  {
    name: 'image-content',
    Class: ImageContent
  },
  {
    name: 'images-slider',
    Class: ImagesSlider
  },
  {
    name: 'key-figure',
    Class: KeyFigure
  },
  {
    name: 'parallax-image',
    Class: ParallaxImage
  },
  {
    name: 'pre-footer',
    Class: PreFooter
  },
  {
    name: 'product-card',
    Class: ProductCard
  },
  {
    name: 'product-cover',
    Class: ProductCover
  },
  {
    name: 'product-highlights',
    Class: ProductHighlights
  },
  {
    name: 'scroll-indicator',
    Class: ScrollIndicator
  },
  {
    name: 'slider',
    Class: Slider
  },
  {
    name: 'testimonies',
    Class: Testimonies
  },
  {
    name: 'textual-pagination-slider',
    Class: TextualPaginationSlider
  },
  {
    name: 'underlined-link, .wysiwyg a',
    Class: UnderlinedLink
  },
  {
    name: 'product-posts',
    Class: ProductPosts
  },
  {
    name: 'simulator',
    Class: Simulator
  },
  {
    name: 'arrow-slider',
    Class: ArrowSlider
  },
  {
    name: 'cards-slider',
    Class: CardsSlider
  },
  {
    name: 'title',
    Class: Title
  },
  {
    name: 'button',
    Class: Button
  },
  {
    name: 'page-cover',
    Class: PageCover
  },
  {
    name: 'rounded-icon-button',
    Class: RoundedIconButton
  },
  {
    name: 'technical-documents',
    Class: TechnicalDocuments
  }
]

export default blockList
