import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class TextualPaginationSlider extends Block {
  bindMethods() {
    this.onNextClick = this.onNextClick.bind(this)
    this.onPaginationItemLeave = this.onPaginationItemLeave.bind(this)
    this.onPreviousClick = this.onPreviousClick.bind(this)
  }

  getElems() {
    this.$contents = this.$el.querySelectorAll('.textual-pagination-slider__content')
    this.$images = this.$el.querySelectorAll('.textual-pagination-slider__image')
    this.$imagesWrapper = this.$images[0].parentNode
    this.$next = this.$el.querySelector('.textual-pagination-slider__next')
    if (this.$next) this.$nextArrows = this.$next.querySelectorAll('.textual-pagination-slider__next-arrow')
    this.$paginationItems = this.$el.querySelectorAll('.textual-pagination-slider__pagination-item')
    this.$previous = this.$el.querySelector('.textual-pagination-slider__previous')
    if (this.$previous) this.$previousArrows = this.$previous.querySelectorAll('.textual-pagination-slider__previous-arrow')
    this.$slides = this.$el.querySelectorAll('.textual-pagination-slider__slide')
    this.$titles = this.$el.querySelectorAll('.textual-pagination-slider__title')
  }

  init() {
    this.currentIndex = 0
    this.isAnimating = false
    this.slidesCount = this.$slides.length
    this.nextIndex = gsap.utils.wrap(0, this.slidesCount, this.currentIndex + 1)
    this.previousIndex = gsap.utils.wrap(0, this.slidesCount, this.currentIndex - 1)

    this.getImageWrapperTranslateValue()
    this.setHeight()
    this.setImageWrapperStyles()

    if (this.slidesCount > 1) {
      this.buttons = [{
        arrows: this.$nextArrows,
        el: this.$next
      }, {
        arrows: this.$previousArrows,
        el: this.$previous
      }]

      this.titles = []

      for (let i = 0; i < this.slidesCount; i++) this.titles.push(this.splitTitle(this.$titles[i]))

      this.getBounds()
      this.prepareAnimation()
    }
  }

  getImageWrapperTranslateValue() {
    this.imageWrapperTransformValue = store.w.w / 20
  }

  setHeight() {
    let biggestSlideIndex = 0
    let maxHeight = 0
  
    this.$slides.forEach((_, i) => {
      const contentHeight = this.$contents[i].offsetHeight
      const titleHeight = this.$titles[i].offsetHeight
      const slideHeight = titleHeight + store.remToPixel(5) + contentHeight
  
      if (slideHeight > maxHeight) {
        biggestSlideIndex = i
        maxHeight = slideHeight
      }
    })
  
    if (biggestSlideIndex > -1) this.$slides[biggestSlideIndex].classList.remove('absolute-full')
  }

  setImageWrapperStyles() {
    this.$imagesWrapper.style.height = `calc(100% + ${this.imageWrapperTransformValue}px)`
    this.$imagesWrapper.style.marginTop = `-${this.imageWrapperTransformValue / 2}px`
  }

  splitTitle(title) {
    const lines = Splitting({
      by: 'linesChars',
      target: title
    })[0].lines
    const linesCharactersData = lines.map((line) => Splitting({
      by: 'chars',
      target: line
    }).flatMap((lineCharactersData) => lineCharactersData.chars))

    return linesCharactersData
  }

  prepareAnimation() {
    for (let i = 1; i < this.slidesCount; i++) {
      gsap.set([this.$contents[i], this.$images[i]], { alpha: 0 })
      for (let j = 0; j < this.titles[i].length; j++) gsap.set(this.titles[i][j], { alpha: 0 })
      this.$slides[i].classList.add('pointer-events-none')
    }
  }

  getBounds() {
    const { width } = this.buttons[0].el.getBoundingClientRect()
    const { height: arrowHeight } = this.buttons[0].arrows[0].getBoundingClientRect()

    this.arrowTranslateXValue = (width * 0.75 + arrowHeight) / 2

    this.buttons[0].arrowTranslateXValue = this.arrowTranslateXValue
    this.buttons[1].arrowTranslateXValue = - this.arrowTranslateXValue

  }

  events() {
    if (this.slidesCount > 1) {
      for (let i = 0; i < this.buttons.length; i++) this.buttons[i].el.addEventListener('mouseenter', this.onButtonEnter.bind(this, i))
      this.$next && this.$next.addEventListener('click', this.onNextClick)
      for (let i = 0; i < this.$paginationItems.length; i++) {
        this.$paginationItems[i].addEventListener('click', this.onPaginationItemClick.bind(this, i))
        this.$paginationItems[i].addEventListener('mouseenter', this.onPaginationItemEnter.bind(this, i))
        this.$paginationItems[i].addEventListener('mouseleave', this.onPaginationItemLeave)
      }
      this.$previous && this.$previous.addEventListener('click', this.onPreviousClick)
    }
  }

  onButtonEnter(index) {
    this.buttons[index].arrows.forEach((arrow) => gsap.killTweensOf(arrow))

    this.buttonEnteringAnimation = gsap.fromTo(this.buttons[index].arrows, {
      x: 0
    }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      x: () => this.buttons[index].arrowTranslateXValue
    })
  }

  onNextClick() {
    if (this.isAnimating) return

    this.updateSlide(this.nextIndex)
  }

  onPaginationItemClick(index) {
    if (index === this.currentIndex || this.isAnimating) return

    this.updateSlide(index)
  }

  onPaginationItemEnter(index) {
    for (let i = 0; i < this.$paginationItems.length; i++) this.$paginationItems[i].classList.toggle('a', i === index)
  }

  onPaginationItemLeave() {
    for (let i = 0; i < this.$paginationItems.length; i++) this.$paginationItems[i].classList.toggle('a', i === this.currentIndex)
  }

  onPreviousClick() {
    if (this.isAnimating) return

    this.updateSlide(this.previousIndex)
  }

  updateSlide(index) {
    this.currentIndex = index
    this.isAnimating = true
    this.nextIndex = gsap.utils.wrap(0, this.slidesCount, this.currentIndex + 1)
    this.previousIndex = gsap.utils.wrap(0, this.slidesCount, this.currentIndex - 1)

    store.cancelAnimation(this.updatingSlideAnimation)

    this.updatingSlideAnimation = gsap.timeline()

    for (let i = 0; i < this.slidesCount; i++) {
      const isCurrentIndex = i === this.currentIndex

      gsap.killTweensOf([this.$contents[i], this.$images[i]])

      this.$paginationItems[i].classList.toggle('a', isCurrentIndex)
      this.$slides[i].classList.toggle('pointer-events-none', !isCurrentIndex)

      this.updatingSlideAnimation
        .to(this.$contents[i], {
          alpha: isCurrentIndex ? 1 : 0,
          duration: isCurrentIndex ? 0.5 : 0.25,
          ease: 'beaucoup.alpha'
        }, isCurrentIndex ? 0.3 : 0)
        .fromTo(this.$images[i], {
          alpha: isCurrentIndex ? 1 : 0,
          scale: isCurrentIndex ? 1.1 : 1
        }, {
          duration: 1.5,
          ease: 'expo.out',
          scale: 1
        }, 0)

      for (let j = 0; j < this.titles[i].length; j++) {
        gsap.killTweensOf(this.titles[i][j])

        this.updatingSlideAnimation.to(this.titles[i][j], {
          alpha: isCurrentIndex ? 1 : 0,
          duration: 0.375,
          ease: 'beaucoup.alpha',
          stagger: 0.01875
        }, isCurrentIndex ? 0.375 + j * 0.0375 : j * 0.0375)
      }

      this.updatingSlideAnimation.call(() => {
        this.isAnimating = false
      }, null, this.updatingSlideAnimation.duration() * 0.5)
    }
  }

  resizeX() {
    if (this.slidesCount > 1) {
      this.getBounds()
      for (let i = 0; i < this.buttons.length; i++) this.buttons[i].arrows.forEach((arrow) => gsap.set(arrow, { x: 0 }))
    }
  }
}
