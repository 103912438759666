import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class EventsSlider extends Block {
  bindMethods() {
    this.onNextClick = this.onNextClick.bind(this)
    this.onPreviousClick = this.onPreviousClick.bind(this)
  }

  getElems() {
    this.$contents = this.$el.querySelectorAll('.events-slider__content')
    this.$images = this.$el.querySelectorAll('.events-slider__image')
    this.$imagesWrapper = this.$images[0].parentNode
    this.$lines = this.$el.querySelectorAll('.events-slider__line')
    this.$next = this.$el.querySelector('.events-slider__next')
    if (this.$next) this.$nextArrows = this.$next.querySelectorAll('.events-slider__next-arrow')
    this.$pagination = this.$el.querySelector('.events-slider__pagination')
    this.$previous = this.$el.querySelector('.events-slider__previous')
    if (this.$previous) this.$previousArrows = this.$previous.querySelectorAll('.events-slider__previous-arrow')
    this.$wrapper = this.$el.querySelector('.events-slider__wrapper')
    this.$years = this.$el.querySelectorAll('.events-slider__year')
  }

  init() {
    this.currentIndex = 0
    this.isAnimating = false
    this.mustAnimate = this.$el.dataset.mustAnimate
    this.mobileScreenWidthThreshold = 1200
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    this.nextIndex = gsap.utils.wrap(0, this.$contents.length, this.currentIndex + 1)
    this.previousIndex = gsap.utils.wrap(0, this.$contents.length, this.currentIndex - 1)

    this.getBounds()
    this.setHeight()
    !this.isCurrentlyMobileScreen && this.setImageStyles()

    if (this.$contents.length > 1) {
      this.buttons = [{
        arrows: this.$nextArrows,
        el: this.$next
      }, {
        arrows: this.$previousArrows,
        el: this.$previous
      }]

      this.years = []

      for (let i = 0; i < this.$years.length; i++) this.years.push(this.splitTitle(this.$years[i]))

      if (!this.isCurrentlyMobileScreen) {
        const { width } = this.buttons[0].el.getBoundingClientRect()
        const { height: arrowHeight } = this.buttons[0].arrows[0].getBoundingClientRect()

        this.arrowTranslateXValue = (width * 0.75 + arrowHeight) / 2

        this.buttons[0].arrowTranslateXValue = this.arrowTranslateXValue
        this.buttons[1].arrowTranslateXValue = - this.arrowTranslateXValue

        this.initInterval()
        this.interval.pause()
      }
    }
      
    this.prepareAnimation()

    if (this.isCurrentlyMobileScreen) return

    this.initAnimations()
    this.initScrollTrigger()
  }

  getBounds() {
    this.rect = this.$el.getBoundingClientRect()
    this.imageWrapperTransformValue = store.w.w / 20
    this.isVisible = this.rect.top < store.w.h
  }

  setHeight() {
    this.height = store.getMaxHeight(this.$contents)
    this.$el.style.height = this.isCurrentlyMobileScreen ? `${this.rect.height + this.height}px` : `calc(${this.height}px + var(--padding-container) * 4)`
  }

  setImageStyles() {
    this.$imagesWrapper.style.height = `calc(100% + ${this.imageWrapperTransformValue}px)`
    this.$imagesWrapper.style.marginTop = `-${this.imageWrapperTransformValue / 2}px`
  }

  splitTitle(title) {
    const lines = Splitting({
      by: 'linesChars',
      target: title
    })[0].lines
    const linesCharactersData = lines.map((line) => Splitting({
      by: 'chars',
      target: line
    }).flatMap((lineCharactersData) => lineCharactersData.chars))

    return linesCharactersData
  }

  prepareAnimation() {
    setTimeout(() => {
      this.isVisible && this.mustAnimate && gsap.set(this.$el, { y: store.w.h - this.$el.getBoundingClientRect().top })
      gsap.set(this.$el, { opacity: 1 })
    }, 100)
    
    if (this.$contents.length > 1) {
      for (let i = 1; i < this.$contents.length; i++) {
        gsap.set([this.$contents[i].children, this.$images[i]], { alpha: 0 })
        for (let j = 0; j < this.years[i].length; j++) gsap.set(this.years[i][j], { alpha: 0 })
      }
    }
  }

  initInterval() {
    this.interval = gsap.to(this.$lines[this.nextIndex], {
      duration: 20,
      ease: 'none',
      onComplete: () => this.updateSlide(this.nextIndex),
      scaleX: 1,
      scaleY: 1,
      transformOrigin: this.isCurrentlyMobileScreen ? 'left' : 'top'
    })
  }

  updateSlide(index, direction) {
    this.isAnimating = true
    this.oldIndex = this.currentIndex
    this.oldNextIndex = this.nextIndex
    this.currentIndex = index
    this.nextIndex = gsap.utils.wrap(0, this.$contents.length, this.currentIndex + 1)
    this.previousIndex = gsap.utils.wrap(0, this.$contents.length, this.currentIndex - 1)

    this.interval && setTimeout(() => store.cancelAnimation(this.interval), 0)

    store.cancelAnimation(this.updatingSlideAnimation)

    this.updatingSlideAnimation = gsap.timeline({
      onComplete: () => {
        !this.isCurrentlyMobileScreen && this.initInterval()
      }
    })

    if (this.isCurrentlyMobileScreen) {
      this.updatingSlideAnimation.to(this.$el, {
        duration: 0.6,
        ease: 'expo.out',
        height: this.rect.height + this.$contents[this.currentIndex].offsetHeight
      })
    }

    for (let i = 0; i < this.$contents.length; i++) {
      const isCurrentIndex = i === this.currentIndex
      const transformOrigin = isCurrentIndex ? this.isCurrentlyMobileScreen ? direction === 1 ? 'left' : 'right' : direction === 1 ? 'top' : 'bottom' : this.isCurrentlyMobileScreen ? direction === 1 ? 'right' : 'left' : direction === 1 ? 'bottom' : 'top'

      gsap.killTweensOf([this.$contents[i].children, this.$images[i], this.$lines[i]])

      this.updatingSlideAnimation
        .to(this.$contents[i].children, {
          alpha: isCurrentIndex ? 1 : 0,
          duration: isCurrentIndex ? 0.5 : 0.25,
          ease: 'beaucoup.alpha',
          stagger: 0.05
        }, isCurrentIndex ? 0.3 : 0)
        .fromTo(this.$images[i], {
          alpha: isCurrentIndex ? 1 : 0,
          scale: isCurrentIndex ? 1.1 : 1
        }, {
          duration: 1.5,
          ease: 'expo.out',
          scale: 1
        }, 0)
        .to(this.$lines[i], {
          duration: 0.8,
          ease: 'expo.out',
          scaleX: isCurrentIndex ? 1 : 0,
          scaleY: isCurrentIndex ? 1 : 0,
          transformOrigin
        }, 0)

      for (let j = 0; j < this.years[i].length; j++) {
        gsap.killTweensOf(this.years[i][j])

        this.updatingSlideAnimation.to(this.years[i][j], {
          alpha: isCurrentIndex ? 1 : 0,
          duration: 0.375,
          ease: 'beaucoup.alpha',
          stagger: 0.01875
        }, isCurrentIndex ? 0.375 + j * 0.0375 : j * 0.0375)
      }

      this.updatingSlideAnimation.call(() => {
        this.isAnimating = false
      }, null, this.updatingSlideAnimation.duration() * 0.5)
    }
  }

  initAnimations() {
    this.leavingParallaxAnimation = gsap.fromTo(this.$imagesWrapper, { y: 0 }, {
      ease: 'none',
      y: () => this.imageWrapperTransformValue
    })

    this.enteringParallaxAnimation = gsap.fromTo(this.$imagesWrapper, { y: () => - this.imageWrapperTransformValue }, {
      ease: 'none',
      y: 0
    })
  }

  initScrollTrigger() {
    this.enteringParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.enteringParallaxAnimation,
      end: () => `top ${store.header.height + store.remToPixel(store.w.w < 1024 ? 1 : 1.5)}px`,
      invalidateOnRefresh: true,
      scrub: true,
      start: 'top bottom',
      trigger: this.$wrapper
    })

    if (this.$contents.length > 1) {
      this.intervalScrollTrigger = store.scrollTrigger.create({
        onEnter: () => this.interval.play(),
        onEnterBack: () => this.interval.play(),
        onLeave: () => this.interval.pause(),
        onLeaveBack: () => this.interval.pause(),
        trigger: this.$el
      })
    }

    this.leavingParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingParallaxAnimation,
      end: () => `bottom ${store.header.height + store.remToPixel(store.w.w < 1024 ? 1 : 1.5)}px`,
      invalidateOnRefresh: true,
      scrub: true,
      start: 'bottom bottom',
      trigger: this.$wrapper
    })
  }

  appear() {
    this.isVisible && this.mustAnimate && gsap.to(this.$el, {
      delay: 0.3,
      duration: 0.8,
      ease: 'expo.out',
      y: 0
    })
  }

  events() {
    if (this.$contents.length > 1) {
      if (!this.isCurrentlyMobileScreen) for (let i = 0; i < this.buttons.length; i++) this.buttons[i].el.addEventListener('mouseenter', this.onButtonEnter.bind(this, i))
      this.$next && this.$next.addEventListener('click', this.onNextClick)
      for (let i = 0; i < this.$pagination.children.length; i++) this.$pagination.children[i].addEventListener('click', this.onPaginationChildClick.bind(this, i))
      this.$previous && this.$previous.addEventListener('click', this.onPreviousClick)
    }
  }

  onButtonEnter(index) {
    this.buttons[index].arrows.forEach((arrow) => gsap.killTweensOf(arrow))

    this.buttonEnteringAnimation = gsap.fromTo(this.buttons[index].arrows, {
      x: 0
    }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      x: () => this.buttons[index].arrowTranslateXValue
    })
  }

  onNextClick() {
    if (this.isAnimating) return

    this.updateSlide(this.nextIndex, 1)

    store.smoothScroll.scrollTo(this.$el.getBoundingClientRect().top + store.currentScroll, { duration: 1.2 })
  }

  onPaginationChildClick(index) {
    if (index === this.currentIndex || this.isAnimating) return

    const direction = index === this.nextIndex ? 1 : index === this.previousIndex ? -1 : index > this.currentIndex ? 1 : -1

    this.interval && this.interval.pause()
    this.updateSlide(index, direction)

    store.smoothScroll.scrollTo(this.$el.getBoundingClientRect().top + store.currentScroll, { duration: 1.2 })
  }

  onPreviousClick() {
    if (this.isAnimating) return

    this.updateSlide(this.previousIndex, -1)

    store.smoothScroll.scrollTo(this.$el.getBoundingClientRect().top + store.currentScroll, { duration: 1.2 })
  }

  resize() {
    const isNewStateMobileScreen = store.w.w < this.mobileScreenWidthThreshold

    if (isNewStateMobileScreen !== this.isCurrentlyMobileScreen) {
      this.isCurrentlyMobileScreen = isNewStateMobileScreen
      this.screenSizeChange()
    }

    if (this.$contents.length > 1) {
      if (!this.isCurrentlyMobileScreen) {
        const { width } = this.buttons[0].el.getBoundingClientRect()
        const { height: arrowHeight } = this.buttons[0].arrows[0].getBoundingClientRect()
    
        this.arrowTranslateXValue = (width * 0.75 + arrowHeight) / 2
    
        this.buttons[0].arrowTranslateXValue = this.arrowTranslateXValue
        this.buttons[1].arrowTranslateXValue = - this.arrowTranslateXValue
      }
  
      for (let i = 0; i < this.buttons.length; i++) this.buttons[i].arrows.forEach((arrow) => gsap.set(arrow, { x: 0 }))
    }
    
    gsap.set(this.$el, { height: '100%' })
    
    this.getBounds()

    this.isCurrentlyMobileScreen ? gsap.set(this.$el, { height: this.rect.height + this.$contents[this.currentIndex].offsetHeight }) : this.setImageStyles()

    setTimeout(() => store.scrollTrigger.refresh(), 100)
  }

  screenSizeChange() {
    const scrollTriggers = [
      this.enteringParallaxScrollTrigger,
      this.intervalScrollTrigger,
      this.leavingParallaxScrollTrigger
    ]

    if (this.isCurrentlyMobileScreen) {
      this.interval && this.interval.restart() && this.interval.pause()
      scrollTriggers.forEach((scrollTrigger) => scrollTrigger && scrollTrigger.kill(true))
    } else {
      this.initInterval()
      this.interval.pause()
      this.initAnimations()
      this.initScrollTrigger()
    }
  }
}
