import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class PageCover extends Block {
  getElems() {
    this.$content = this.$el.querySelector('.page-cover__content')
    this.$leafs = this.$el.querySelectorAll('.page-cover__leaf')
    this.$title = this.$el.querySelector('.page-cover__title')
  }

  init() {
    this.mobileScreenWidthThreshold = 1024
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    this.titleLinesCharacters = this.splitTitle(this.$title)

    this.prepareAnimation()
  }

  splitTitle(title) {
    const lines = Splitting({
      by: 'linesChars',
      target: title
    })[0].lines
    const linesCharactersData = lines.map((line) => Splitting({
      by: 'chars',
      target: line
    }).flatMap((lineCharactersData) => lineCharactersData.chars))

    return linesCharactersData
  }

  prepareAnimation() {
    if (this.$content) gsap.set(this.$content, { alpha: 0 })

    for (let i = 0; i < this.titleLinesCharacters.length; i++) gsap.set(this.titleLinesCharacters[i], { alpha: 0 })

    if (this.isCurrentlyMobileScreen) return

    gsap.set(this.$leafs, {
      rotate: -50,
      xPercent: -50,
      yPercent: -50
    })
  }

  appear() {
    if (this.$content) {
      gsap.to(this.$content, {
        alpha: 1,
        delay: 0.75,
        duration: 0.5,
        ease: 'beaucoup.alpha'
      })
    }

    for (let i = 0; i < this.titleLinesCharacters.length; i++) {
      gsap.to(this.titleLinesCharacters[i], {
        alpha: 1,
        delay: 0.5 + i * 0.05,
        duration: 0.75,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      })
    }

    if (this.isCurrentlyMobileScreen) return

    gsap.to(this.$leafs, {
      ease: 'expo.out',
      duration: 2.4,
      rotate: 0,
      stagger: 0.075,
      xPercent: 0,
      yPercent: 0
    })
  }
}
