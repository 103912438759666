import Block from './Block'
import gsap from 'gsap'
import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo'
import store from '../util/store'

export default class ImageContent extends Block {
  getElems() {
    this.$scrollingVideo = this.$el.querySelector('.image-content__scrolling-video')
    if (this.$scrollingVideo) this.$scrollingVideoParent = this.$scrollingVideo.parentNode
    this.$scrollingVideoPlaceholder = this.$el.querySelector('.image-content__scrolling-video-placeholder')
    this.$video = this.$el.querySelector('.image-content__video')
    this.$wrapper = this.$el.querySelector('.image-content__wrapper')
  }

  init() {
    if (!this.$scrollingVideo) return

    this.$video.src = this.$video.dataset.src
    this.$video.load()
    this.$video.addEventListener('loadedmetadata', () => {
      const height = `${this.$video.duration * 24 + 300}vh`

      this.$wrapper.style.height = height

      this.scrollingVideo = new ScrollyVideo({
        frameThreshold: 0.6,
        scrollyVideoContainer: this.$scrollingVideo,
        src: this.$scrollingVideo.dataset.src,
        sticky: false,
        trackScroll: false,
        useWebCodecs: false
      })

      this.scrollingVideo.video.style.objectFit = 'cover'

      this.getBounds()
      this.initAnimations()
      this.initScrollTriggers()
  
      setTimeout(() => {
        this.setVideoPosition()
        store.scrollTrigger.refresh()
      }, 0)
    })
  }

  getBounds() {
    this.parallaxTranslateYValue = store.w.w * 0.1

    this.scrollingVideoParentRect = this.$scrollingVideo.parentNode.getBoundingClientRect()
    this.scrollingVideoPlaceholderRect = this.$scrollingVideoPlaceholder.getBoundingClientRect()
    this.wrapperRect = this.$wrapper.getBoundingClientRect()

    this.translateXValue = this.scrollingVideoPlaceholderRect.left - this.wrapperRect.left - this.scrollingVideoParentRect.width / 2 + this.scrollingVideoPlaceholderRect.width / 2
    this.translateYValue = this.scrollingVideoPlaceholderRect.top - this.wrapperRect.top - this.scrollingVideoParentRect.height / 2 + this.scrollingVideoPlaceholderRect.height / 2

    this.insetBorderRadius = '0.8rem'
    this.insetLeftRight = `${(this.scrollingVideoParentRect.width - this.scrollingVideoPlaceholderRect.width) / 2 / this.scrollingVideoParentRect.width * 100}%`
    this.insetTopBottom = `${(this.scrollingVideoParentRect.height - this.scrollingVideoPlaceholderRect.height) / 2 / this.scrollingVideoParentRect.height * 100}%`
  }

  setVideoPosition() {
    if (store.w.w < 1024) return

    gsap.set(this.$el, {
      '--inset-border-radius': this.insetBorderRadius,
      '--inset-left-right': this.insetLeftRight,
      '--inset-top-bottom': this.insetTopBottom
    })

    gsap.set(this.$scrollingVideo, { scale: 0.75 })

    gsap.set(this.$scrollingVideoParent, {
      x: this.translateXValue,
      y: this.translateYValue
    })
  }

  initAnimations() {
    this.leavingParallaxAnimation = gsap.to(this.$scrollingVideo, {
      ease: 'none',
      y: () => this.parallaxTranslateYValue
    })
    
    if (store.w.w < 1024) return

    this.clipPathAndTranslateAnimation = gsap.timeline()
      .fromTo(this.$el, {
        '--inset-border-radius': () => this.insetBorderRadius,
        '--inset-left-right': () => this.insetLeftRight,
        '--inset-top-bottom': () => this.insetTopBottom
      }, {
        ease: 'none',
        '--inset-left-right': () => this.insetTopBottom
      })
      .fromTo(this.$scrollingVideoParent, {
        x: () => this.translateXValue,
        y: () => this.translateYValue
      }, {
        ease: 'none',
        x: 0,
        y: 0
      }, 0)

    this.clipPathAndScaleAnimation = gsap.timeline()
      .fromTo(this.$el, {
        '--inset-border-radius': () => this.insetBorderRadius,
        '--inset-left-right': () => this.insetTopBottom,
        '--inset-top-bottom': () => this.insetTopBottom
      }, {
        ease: 'power2.out',
        '--inset-top-bottom': '0%',
        '--inset-left-right': '0%',
        '--inset-border-radius': '0rem'
      })
      .fromTo(this.$scrollingVideo, {
        scale: 0.75
      }, {
        ease: 'power2.out',
        scale: 1
      }, 0)
  }

  initScrollTriggers() {
    this.leavingParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingParallaxAnimation,
      end: 'bottom top',
      invalidateOnRefresh: true,
      scrub: true,
      start: 'bottom bottom',
      trigger: this.$wrapper
    })

    this.videoScrollTrigger = store.scrollTrigger.create({
      end: 'bottom top',
      scrub: true,
      start: 'top top',
      onUpdate: ({ progress }) => {
        this.scrollingVideo.setTargetTimePercent(progress)
      },
      trigger: this.$wrapper
    })

    if (store.w.w < 1024) return

    this.clipPathAndTranslateScrollTrigger = store.scrollTrigger.create({
      animation: this.clipPathAndTranslateAnimation,
      end: 'top top',
      invalidateOnRefresh: true,
      scrub: true,
      start: () => '-=' + (this.wrapperRect.top - this.scrollingVideoPlaceholderRect.top - this.scrollingVideoPlaceholderRect.height / 2) + 'px 50%',
      trigger: this.$wrapper
    })

    this.clipPathAndScaleScrollTrigger = store.scrollTrigger.create({
      animation: this.clipPathAndScaleAnimation,
      end: () => store.w.h * 3 + 'px bottom',
      invalidateOnRefresh: true,
      scrub: true,
      start: 'top top',
      trigger: this.$wrapper
    })
  }

  resize() {
    if (!this.$scrollingVideo) return

    this.getBounds()
    store.scrollTrigger.refresh()

    if (window.scrollY < this.clipPathAndTranslateScrollTrigger.start) {
      setTimeout(() => {
        this.setVideoPosition()
      }, 0)
    }
  }
}
