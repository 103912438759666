import gsap from 'gsap'
import store from './store'

export default class ToggleManager {
  constructor(el, overlay, toggler, isSubmenu = false) {
    store.toggleManagers.push(this)

    this.$el = el
    this.$overlay = overlay
    this.$toggler = toggler
    this.isSubmenu = isSubmenu

    this.bindMethods()
    this.getElems()
    this.init()
    this.events()
  }

  bindMethods() {
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onToggleManagerOpened = this.onToggleManagerOpened.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  init() {
    this.mobileScreenWidthThreshold = 1024
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    this.isOpen = false
  }
  
  events() {
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('toggleManagerOpened', this.onToggleManagerOpened)

    this.$overlay.addEventListener('click', this.close.bind(this, false))
    this.$toggler.addEventListener('click', this.toggle)
  }

  onKeyDown(e) {
    e.code === 'Escape' && this.isOpen && this.close()
  }

  close(force = false) {
    this.isOpen = false

    /* eslint-disable-next-line brace-style, max-statements-per-line, object-property-newline */
    this.closingAnimation = gsap.timeline({ defaults: { duration: force ? 0 : 0.4, ease: 'expo.out' }, onComplete: () => { window.dispatchEvent(new CustomEvent('openedToggleManagerClosed', { detail: { toggleManager: this } })) }})
    /* eslint-enable brace-style, max-statements-per-line, object-property-newline */

    store.cancelAnimation(this.openingAnimation)

    if (!(this.isCurrentlyMobileScreen && this.isSubmenu)) {
      this.$overlay.classList.add('pointer-events-none', 'opacity-0');
    }

    store.smoothScroll && store.smoothScroll.start()
  }

  onToggleManagerOpened(event) {
    if (event.detail.toggleManager !== this || !this.isOpen) return

    this.close()
  }

  toggle() {
    this.isOpen ? this.close() : this.open()
  }

  open() {
    return new Promise((resolve) => {
      const openedToggleManagers = store.toggleManagers.filter((toggleManager) => toggleManager !== this && toggleManager.isOpen)

      if (openedToggleManagers.length) {
        openedToggleManagers.forEach((openedToggleManager) => {
          if (this.isCurrentlyMobileScreen && this.isSubmenu && !openedToggleManager.isSubmenu) {
            this.openToggleManager().then(() => resolve())
          } else {
            window.dispatchEvent(new CustomEvent('toggleManagerOpened', { detail: { toggleManager: openedToggleManager } }))
  
            window.addEventListener('openedToggleManagerClosed', () => {
              this.openToggleManager().then(() => resolve())
            }, { once: true })
          }
        })
      } else {
        this.openToggleManager().then(() => resolve())
      }

      store.smoothScroll && store.smoothScroll.stop()
    })
  }

  openToggleManager() {
    return new Promise((resolve) => {
      this.isOpen = true

      /* eslint-disable object-property-newline */
      this.openingAnimation = gsap.timeline({ defaults: { duration: 0.6, ease: 'expo.out' }})
      /* eslint-enable object-property-newline */
  
      store.cancelAnimation(this.closingAnimation)
  
      if (!(this.isCurrentlyMobileScreen && this.isSubmenu)) {
        this.$overlay.classList.remove('pointer-events-none', 'opacity-0')
      }
  
      resolve()
    })
  }
}
