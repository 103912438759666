import Block from './Block';
import store from '../util/store';

export default class CardsSlider extends Block {
  bindMethods() { }

  getElems() {
    this.$titles = this.$el.querySelectorAll('.slider__item__title')
  }
  init() {
    if (store.detect.mobile || store.w.w < 1024) return

    this.maxItemHeight = 0

    this.$titles.forEach((title) => {
      const height = title.offsetHeight

      if (height > this.maxItemHeight) this.maxItemHeight = height
    })

    if (this.maxItemHeight) this.$titles.forEach((title) => {
      title.style.height = this.maxItemHeight + 'px'
    })
  }

  resize() {
    if (store.detect.mobile || store.w.w < 1024) {
      this.$titles.forEach((title) => {
        title.style.height = 'auto'
      })
    } else this.init()

  }

  onEnterCompleted() { }

  events() { }
}
