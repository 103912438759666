import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class Testimonies extends Block {
  bindMethods() {
    this.onNextClick = this.onNextClick.bind(this)
    this.onPreviousClick = this.onPreviousClick.bind(this)
  }

  getElems() {
    this.$identities = this.$el.querySelectorAll('.testimonies__identity')
    this.$identitiesWrapper = this.$identities[0].parentNode
    this.$images = this.$el.querySelectorAll('.testimonies__image')
    this.$lines = this.$el.querySelectorAll('.testimonies__line')
    this.$next = this.$el.querySelector('.testimonies__next')
    if (this.$next) this.$nextArrows = this.$next.querySelectorAll('.testimonies__next-arrow')
    this.$quotes = this.$el.querySelectorAll('.testimonies__quote')
    this.$quotesWrapper = this.$quotes[0].parentNode
    this.$pagination = this.$el.querySelector('.testimonies__pagination')
    this.$previous = this.$el.querySelector('.testimonies__previous')
    if (this.$previous) this.$previousArrows = this.$previous.querySelectorAll('.testimonies__previous-arrow')

    if (this.$identities.length > 1) {
      this.buttons = []
  
      this.buttons.push({
        arrows: this.$nextArrows,
        el: this.$next
      })
  
      this.buttons.push({
        arrows: this.$previousArrows,
        el: this.$previous
      })
    }
  }

  init() {
    this.currentIndex = 0
    this.isAnimating = false
    this.nextIndex = gsap.utils.wrap(0, this.$identities.length, this.currentIndex + 1)
    this.previousIndex = gsap.utils.wrap(0, this.$identities.length, this.currentIndex - 1)

    this.setWrappersHeight()
    this.prepareAnimation()

    if (this.$identities.length > 1) {
      this.getBounds()
      this.initInterval()
      this.initScrollTrigger()
  
      this.interval.pause()
    }
  }

  setWrappersHeight() {
    this.identitiesWrapperHeight = store.getMaxHeight(this.$identities)
    this.quotesWrapperHeight = store.getMaxHeight(this.$quotes)

    gsap.set(this.$identitiesWrapper, { height: this.identitiesWrapperHeight })
    gsap.set(this.$quotesWrapper, { height: this.quotesWrapperHeight })
  }

  prepareAnimation() {
    const elementsToAnimate = [this.$identities, this.$images, this.$quotes]

    elementsToAnimate.forEach((elements) => {
      for (let i = 1; i < elements.length; i++) gsap.set(elements[i], { alpha: 0 })
    })
  }

  getBounds() {
    const { width } = this.buttons[0].el.getBoundingClientRect()
    const { height: arrowHeight } = this.buttons[0].arrows[0].getBoundingClientRect()

    this.arrowTranslateXValue = (width * 0.75 + arrowHeight) / 2

    this.buttons[0].arrowTranslateXValue = this.arrowTranslateXValue
    this.buttons[1].arrowTranslateXValue = - this.arrowTranslateXValue
  }

  initInterval() {
    this.interval = gsap.to(this.$lines[this.nextIndex], {
      duration: 7.5,
      ease: 'none',
      onComplete: () => this.updateTestiomny(this.nextIndex, 1),
      scaleX: 1,
      transformOrigin: 'left'
    })
  }

  initScrollTrigger() {
    this.intervalScrollTrigger = store.scrollTrigger.create({
      end: 'bottom top',
      onEnter: () => this.interval.play(),
      onEnterBack: () => this.interval.play(),
      onLeave: () => this.interval.pause(),
      onLeaveBack: () => this.interval.pause(),
      trigger: this.$el
    })
  }

  events() {
    if (this.$identities.length > 1) {
      if (!store.detect.isMobile) {
        for (let i = 0; i < this.buttons.length; i++) {
          this.buttons[i].el.addEventListener('mouseenter', this.onButtonEnter.bind(this, i))
        }
      }
      this.$next.addEventListener('click', this.onNextClick)
      for (let i = 0; i < this.$pagination.children.length; i++) this.$pagination.children[i].addEventListener('click', this.onPaginationChildClick.bind(this, i))
      this.$previous.addEventListener('click', this.onPreviousClick)
    }
  }

  onButtonEnter(index) {
    this.buttons[index].arrows.forEach((arrow) => gsap.killTweensOf(arrow))

    this.buttonEnteringAnimation = gsap.fromTo(this.buttons[index].arrows, {
      x: 0
    }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      x: () => this.buttons[index].arrowTranslateXValue
    })
  }

  onNextClick() {
    if (this.isAnimating) return

    this.updateTestiomny(this.nextIndex, 1)
  }

  onPaginationChildClick(index) {
    if (index === this.currentIndex || this.isAnimating) return

    this.interval && this.interval.pause()
    this.updateTestiomny(index, index === this.nextIndex ? 1 : -1)
  }

  onPreviousClick() {
    if (this.isAnimating) return

    this.updateTestiomny(this.previousIndex, -1)
  }

  updateTestiomny(index, direction) {
    this.isAnimating = true
    this.oldIndex = this.currentIndex
    this.currentIndex = index
    this.nextIndex = gsap.utils.wrap(0, this.$identities.length, this.currentIndex + 1)
    this.previousIndex = gsap.utils.wrap(0, this.$identities.length, this.currentIndex - 1)

    setTimeout(() => store.cancelAnimation(this.interval), 0)

    this.updatingTestimonyAnimation = gsap.timeline({
      onComplete: () => {
        this.isAnimating = false
        this.initInterval()
      }
    })

    gsap.killTweensOf([this.$identities, this.$images, this.$lines, this.$quotes])

    const animationOptions = {
      duration: 0.375,
      ease: 'beaucoup.alpha'
    }

    const lineAnimationOptions = {
      duration: 0.8,
      ease: 'expo.out'
    }

    for (let i = 0; i < this.$identities.length; i++) {
      const isCurrentIndex = i === this.currentIndex

      /* eslint-disable object-property-newline */
      this.updatingTestimonyAnimation
        .to(this.$identities[i], { ...animationOptions, autoAlpha: isCurrentIndex ? 1 : 0 }, isCurrentIndex ? 0.3 : 0)
        .to(this.$images[i], { ...animationOptions, autoAlpha: isCurrentIndex ? 1 : 0 }, isCurrentIndex ? 0.3 : 0)
        .to(this.$lines[i], { ...lineAnimationOptions, scaleX: isCurrentIndex ? 1 : 0, transformOrigin: isCurrentIndex ? direction === 1 ? 'left' : 'right' : direction === 1 ? 'right' : 'left' }, 0)
        .to(this.$quotes[i], { ...animationOptions, autoAlpha: isCurrentIndex ? 1 : 0 }, isCurrentIndex ? 0.3 : 0)
      /* eslint-enable object-property-newline */
    }
  }

  resizeX() {
    this.setWrappersHeight()
    if (this.$identities.length > 1) {
      this.getBounds()
      for (let i = 0; i < this.buttons.length; i++) this.buttons[i].arrows.forEach((arrow) => gsap.set(arrow, { x: 0 }))
    }
  }
}
