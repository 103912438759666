import { gsap } from 'gsap'
import store from './store'

export default class Loader {
  constructor() {
    this.getElems()

    // Scroll to top
    document.body.scrollTop = 0

    this.hide()
  }

  getElems() {
    this.$el = document.querySelector('.loader')
    this.$logotype = document.querySelector('.loader__logotype')
  }

  hide() {
    const tl = gsap.timeline({ delay: 0.4 })

    tl
      .to(this.$logotype, {
        alpha: 1,
        duration: 0.6,
        ease: 'beaucoup.alpha'
      })
      .to(this.$el, {
        autoAlpha: 0,
        duration: 0.4,
        ease: 'beaucoup.alpha'
      }, '>0.3')
      .call(() => {
        window.dispatchEvent(new CustomEvent('loaderComplete'))
        store.isFirstLoaded = true
      }, [], '<')
  }
}
