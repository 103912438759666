import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class FooterCredits extends Block {
  bindMethods() {
    this.onEnterCredits = this.onEnterCredits.bind(this)
    this.onLeaveCredits = this.onLeaveCredits.bind(this)
    this.onClickCredits = this.onClickCredits.bind(this)
  }

  getElems() {
    this.$creditsLink = this.$el.querySelector('.footer-credits-link')
    this.$creditsTitle = this.$el.querySelector('.footer-credits-title')
  }

  init() {
    gsap.set(this.$creditsLink, {
      yPercent: -100
    })
  }

  onEnterCredits() {
    if (store.detect.isMobile) return

    gsap.to(this.$creditsTitle, {
      yPercent: 100,
      ease: 'expo.out',
      duration: 0.6
    })
    gsap.to(this.$creditsLink, {
      yPercent: 0,
      ease: 'expo.out',
      duration: 0.6
    })
  }

  onLeaveCredits() {
    if (store.detect.isMobile) return

    gsap.to(this.$creditsTitle, {
      yPercent: 0,
      ease: 'expo.out',
      duration: 0.6
    })
    gsap.to(this.$creditsLink, {
      yPercent: -100,
      ease: 'expo.out',
      duration: 0.6
    })
  }

  onClickCredits() {
    if (!store.detect.isMobile) return
  
    gsap.to(this.$creditsTitle, {
      yPercent: 100,
      ease: 'expo.out',
      duration: 0.6
    })
    gsap.to(this.$creditsLink, {
      yPercent: 0,
      ease: 'expo.out',
      duration: 0.6
    })
  }

  events() {
    this.$el.addEventListener('mouseenter', this.onEnterCredits)
    this.$el.addEventListener('mouseleave', this.onLeaveCredits)
    this.$creditsTitle.addEventListener('click', this.onClickCredits)
  }
}
