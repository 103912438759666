const store = {
  cancelAnimation: (a) => {
    if (!a) return
    
    a.pause()
    a.invalidate()
    a.kill()
  },
  currentScroll: 0,
  dropdowns: [],
  fade: null,
  getMaxHeight: (elements) => Math.max(...Array.from(elements).map((element) => element.offsetHeight)),
  remToPixel: (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize),
  pixelToRem: (px) => px / parseFloat(getComputedStyle(document.documentElement).fontSize),
  toggleManagers: [],
  detect: {
    uA: navigator.userAgent.toLowerCase(),
    get iPadIOS13() {
     return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
    },
    get isMobile() {
      return (/mobi|android|tablet|ipad|iphone/).test(this.uA) && window.innerWidth <= 1024 || this.iPadIOS13
    },
    get isMobileAndroid() {
      return (/android.*mobile/).test(this.uA)
    },
    get isFirefox() {
      return this.uA.indexOf('firefox') > -1
    },
    get isAndroid() {
      return this.isMobileAndroid || !this.isMobileAndroid && (/android/i).test(this.uA)
    },
    get safari() {
      return this.uA.match(/version\/[\d.]+.*safari/)
    },
    get isSafari() {
      return this.safari && !this.isAndroid
    }
  },
  lerp: (s, e, v) => s * (1 - v) + e * v,
  round: (v, d) => {
    const e = d ? Math.pow(10, d) : 100

    return Math.round(v * e) / e
  }
}

export default store
