import Anchor from '../util/Anchor'
import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class ProductCover extends Block {
  bindMethods() {
    this.render = this.render.bind(this)
  }

  getElems() {
    this.$background = this.$el.querySelector('.product-cover__background')
    this.$canvas = this.$el.querySelector('.product-cover__canvas')
    this.$content = this.$el.querySelector('.product-cover__content')
    this.$contentWrapper = this.$content.parentNode
    this.$image = this.$el.querySelector('.product-cover__image')
    this.$scrollIndicator = this.$el.querySelector('.scroll-indicator')
    this.$scrollIndicatorWrapper = this.$scrollIndicator.parentNode
    this.$title = this.$el.querySelector('.product-cover__title')
  }

  init() {
    this.mobileScreenWidthThreshold = 1200
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold

    this.anchor = new Anchor({
      scrollDuration: 1.2,
      targetScrollPosition: this.$el.getBoundingClientRect().bottom - (this.isCurrentlyMobileScreen ? 0 : store.remToPixel(15)) + store.currentScroll + 1,
      triggerEl: this.$scrollIndicator
    })

    this.titleLinesCharactersData = this.splitTitle(this.$title)

    if (this.isCurrentlyMobileScreen) {
      if (store.w.w < 1024) this.getBounds()
    } else {
      this.canvasContext = this.$canvas.getContext('2d')
      this.currentFrame = { value: 0 }
      this.framesCount = this.$el.dataset.framesCount
      this.framesDirectoryUri = this.$el.dataset.framesDirectoryUri
  
      this.frames = []
  
      for (let i = 0; i < this.framesCount; i++) {
        const frame = new Image()
  
        frame.src = `${this.framesDirectoryUri}frame_${(i + 1).toString().padStart(4, '0')}.webp`
  
        this.frames.push(frame)
      }
  
      this.frames[0].addEventListener('load', () => {
        this.getBounds()
        this.initAnimations()
        this.initScrollTriggers()
        this.render()
      })
    }

    this.prepareAnimation()
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharactersData = []
    
    lines.forEach((line, lineIndex) => {
      linesCharactersData[lineIndex] = {
        characters: [],
        charactersWrappers: []
      }
      
      /* eslint-disable object-property-newline */
      Splitting({ by: 'charactersWrappers', target: line }).forEach((lineCharactersData) => {
        linesCharactersData[lineIndex].characters.push(...lineCharactersData.charactersWrappers.characters)
        linesCharactersData[lineIndex].charactersWrappers.push(...lineCharactersData.charactersWrappers.charactersWrappers)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharactersData
  }

  getBounds() {
    if (this.isCurrentlyMobileScreen) {
      this.imageRect = this.$image.getBoundingClientRect()
      this.imageBottom = this.imageRect.bottom
      this.imageHeight = this.imageRect.height
  
      this.scrollIndicatorRect = this.$scrollIndicator.getBoundingClientRect()
      this.scrollIndicatorBottom = this.scrollIndicatorRect.bottom
      this.scrollIndicatorHeight = this.scrollIndicatorRect.height

      this.imageTranslateValue = this.scrollIndicatorBottom - this.imageBottom + this.imageHeight * 0.85
    } else {
      this.$canvas.width = store.w.w
      this.$canvas.height = store.w.h
  
      this.canvasHeight = this.$canvas.height
      this.canvasWidth = this.$canvas.width
      const canvasRatio = this.canvasWidth / this.canvasHeight
  
      const frame = this.frames[this.currentFrame.value]
      const frameHeight = frame.height
      const frameWidth = frame.width
      const frameRatio = frameWidth / frameHeight
  
      if (frameRatio > canvasRatio) {
        this.dWidth = this.canvasHeight * frameRatio
        this.dHeight = this.canvasHeight
        this.dx = (this.canvasWidth - this.dWidth) / 2
        this.dy = 0
      } else {
        this.dWidth = this.canvasWidth
        this.dHeight = this.canvasWidth / frameRatio
        this.dx = 0
        this.dy = (this.canvasHeight - this.dHeight) / 2
      }
    }
  }

  initAnimations() {
    this.animation = gsap.timeline()

    this.animation
      .to(this.$background, {
        autoAlpha: 0,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      })
      .to(this.$content, {
        autoAlpha: 0,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      }, 0)
      .to(this.$scrollIndicator, {
        autoAlpha: 0,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      }, 0)

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) {
      this.animation.to(this.titleLinesCharactersData[i].characters, {
        alpha: 0,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, i * 0.05)
    }

    this.framesAnimation = gsap.to(this.currentFrame, {
      ease: 'none',
      onUpdate: this.render,
      snap: 'value',
      value: this.framesCount - 1
    })

    this.parallaxAnimation = gsap.to(this.$el, { y: () => store.w.h / 3.5 })
  }

  initScrollTriggers() {
    this.animationScrollTrigger = store.scrollTrigger.create({
      animation: this.animation,
      end: 'bottom bottom',
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.framesScrolLTrigger = store.scrollTrigger.create({
      animation: this.framesAnimation,
      end: 'bottom bottom',
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.parallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.parallaxAnimation,
      end: 'bottom top',
      invalidateOnRefresh: true,
      scrub: true,
      start: 'bottom bottom',
      trigger: this.$el
    })
  }

  prepareAnimation() {
    gsap.set(this.$canvas, { yPercent: 100 })
    gsap.set([this.$contentWrapper, this.$scrollIndicatorWrapper], { alpha: 0 })
    gsap.set(this.$image, { y: this.imageHeight })

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) gsap.set(this.titleLinesCharactersData[i].charactersWrappers, { alpha: 0 })
  }

  render() {
    this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    this.canvasContext.drawImage(this.frames[this.currentFrame.value], this.dx, this.dy, this.dWidth, this.dHeight)
  }

  appear() {
    gsap.to(this.$canvas, {
      delay: 0.5,
      duration: 1.6,
      ease: 'expo.out',
      yPercent: 0
    })

    gsap.to([this.$contentWrapper, this.$scrollIndicatorWrapper], {
      alpha: 1,
      delay: 0.75,
      duration: 0.5,
      ease: 'beaucoup.alpha',
      stagger: 0.025
    })

    gsap.to(this.$image, {
      delay: 0.5,
      duration: 1.2,
      ease: 'expo.out',
      y: this.imageTranslateValue
    })

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) {
      gsap.to(this.titleLinesCharactersData[i].charactersWrappers, {
        alpha: 1,
        delay: 0.5 + i * 0.05,
        duration: 0.75,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      })
    }
  }

  resize() {
    const isNewStateMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    
    if (isNewStateMobileScreen !== this.isCurrentlyMobileScreen) this.isCurrentlyMobileScreen = isNewStateMobileScreen

    this.anchor.targetScrollPosition = this.$el.getBoundingClientRect().bottom - (this.isCurrentlyMobileScreen ? 0 : store.remToPixel(15)) + store.currentScroll + 1
    
    if (this.isCurrentlyMobileScreen) {
      gsap.set(this.$image, { y: 0 })

      if (store.w.w < 1024) {
        this.getBounds()
        this.setImagePosition()
      }
    } else {
      this.getBounds()
      this.render()
    }
  }

  setImagePosition() {
    this.imageTranslateValue = this.scrollIndicatorBottom - this.imageBottom + this.imageHeight * 0.85

    gsap.set(this.$image, { y: this.imageTranslateValue })
  }
}
