import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class PreFooter extends Block {
  getElems() {
    this.$background = this.$el.querySelector('.pre-footer__background')
    this.$foreground = this.$el.querySelector('.pre-footer__foreground')
    this.$middleground = this.$el.querySelector('.pre-footer__middleground')
    this.$productImage = this.$el.querySelector('.pre-footer__product-image')
  }

  init() {
    this.getBounds()
    this.initAnimation()
    this.initScrollTrigger()
  }

  getBounds() {
    this.$backgroundTranslateYValue = store.w.w * 0.028125
    this.$middlegroundTranslateYValue = store.w.w * 0.05625
    this.$foregroundTranslateYValue = store.w.w * 0.1125

    this.elRect = this.$el.getBoundingClientRect()
    this.elOffset = store.w.w < 768 ? store.remToPixel(3) : store.w.w < 1024 ? store.remToPixel(4) : store.remToPixel(6)

    this.insetTopBottom = this.elOffset / 2 / this.elRect.height * 100 + '%'
    this.insetLeftRight = this.elOffset / 2 / this.elRect.width * 100 + '%'
    this.insetBorderRadius = '1rem'
  }

  initAnimation() {
    this.clipPathAnimation = gsap.to(this.$el, {
      ease: 'none',
      '--inset-top-bottom': () => this.insetTopBottom,
      '--inset-left-right': () => this.insetLeftRight,
      '--inset-border-radius': () => this.insetBorderRadius
    })

    this.parallaxAnimation = gsap.timeline({
      defaults: {
        ease: 'none'
      }
    })

    this.parallaxAnimation
      .fromTo(this.$background, { y: () => - this.$backgroundTranslateYValue }, { y: () => this.$backgroundTranslateYValue })
      .fromTo([this.$middleground, this.$productImage.parentNode], { y: () => - this.$middlegroundTranslateYValue }, { y: this.$middlegroundTranslateYValue }, '<')
      .fromTo(this.$foreground, { y: () => - this.$foregroundTranslateYValue }, { y: () => this.$foregroundTranslateYValue }, '<')
  }

  initScrollTrigger() {
    this.clipPathScrollTrigger = store.scrollTrigger.create({
      animation: this.clipPathAnimation,
      end: 'top top',
      invalidateOnRefresh: true,
      scrub: true,
      start: 'top 75%',
      trigger: this.$el
    })

    this.parallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.parallaxAnimation,
      invalidateOnRefresh: true,
      scrub: true,
      trigger: this.$el
    })
  }

  resize() {
    this.getBounds()
  }
}
