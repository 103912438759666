import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class ParallaxImage extends Block {
  getElems() {
    this.$image = this.$el.querySelector('img')
  }

  init() {
    this.mobileScreenWidthThreshold = 1024
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold

    if (store.w.w < 1024 || store.detect.isMobile) return

    this.transformValue = store.w.w / 25

    this.$image.style.height = `calc(100% + ${this.transformValue}px)`
    this.$image.style.marginTop = `-${this.transformValue / 2}px`

    this.animation = gsap.fromTo(
      this.$image, {
      y: -this.transformValue
    }, {
      y: this.transformValue,
      ease: 'none'
    }
    )

    this.scrollTrigger = store.scrollTrigger.create({
      trigger: this.$el,
      animation: this.animation,
      scrub: true
    })
  }

  resizeX() {
    const isNewStateMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    
    if (isNewStateMobileScreen !== this.isCurrentlyMobileScreen) {
      this.isCurrentlyMobileScreen = isNewStateMobileScreen

      if (this.isCurrentlyMobileScreen) {
        this.scrollTrigger && this.scrollTrigger.disable()
        this.animation && store.cancelAnimation(this.animation)
        gsap.set(this.$image, { clearProps: true })

        return
      }
      
      this.init()
      this.scrollTrigger && this.scrollTrigger.enable()
    }

    this.transformValue = store.w.w / 20
    this.$image.style.height = `calc(100% + ${this.transformValue}px)`
    this.$image.style.top = `-${this.transformValue}`
  }
}
