import Block from './Block'
import ArrowDragSlider from '../util/ArrowDragSlider'

export default class ArrowSlider extends Block {
  init() {
    this.slider = new ArrowDragSlider({
      el: this.$el
    })
  }
  
  appear() {
    this.slider && this.slider.appear()
  }

  destroy() {
    this.slider && this.slider.destroy()
  }

  resize() {
    this.slider && this.slider.resize()
  }

  update() {
    this.slider && this.slider.update()
  }
}
