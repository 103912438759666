/* eslint-disable max-lines */

import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class ProductCard extends Block {
  bindMethods() {
    this.onLeftButtonClick = this.onLeftButtonClick.bind(this)
    this.onLeftCloseClick = this.onLeftCloseClick.bind(this)
    this.onOverlayClick = this.onOverlayClick.bind(this)
    this.onRightButtonClick = this.onRightButtonClick.bind(this)
    this.onRightCloseClick = this.onRightCloseClick.bind(this)
  }

  getElems() {
    this.$background = this.$el.querySelector('.product-card__background')
    this.$colors = this.$el.querySelectorAll('.product-card__color')
    this.$colorsNames = this.$el.querySelectorAll('.product-card__color-name')
    this.$documents = this.$el.querySelectorAll('.product-card__document')
    this.$leftButton = this.$el.querySelector('.product-card__left-button')
    this.$leftClose = this.$el.querySelector('.product-card__left-close')
    this.$leftSection = this.$el.querySelector('.product-card__left-section')
    this.$overlay = this.$el.querySelector('.product-card__overlay')
    this.$overlayMobile = this.$el.querySelector('.product-card__overlay-mobile')
    this.$product = this.$el.querySelector('.product-card__product')
    this.$productImages = this.$product.querySelectorAll('.product-card__product-image')
    this.$rightButton = this.$el.querySelector('.product-card__right-button')
    this.$rightClose = this.$el.querySelector('.product-card__right-close')
    this.$rightSection = this.$el.querySelector('.product-card__right-section')
    this.$tabButtons = this.$el.querySelectorAll('.product-card__tab-button')
    this.$tabSections = this.$el.querySelectorAll('.product-card__tab-section')
    this.$title = this.$el.querySelector('.product-card__title')
  }

  init() {
    if (this.$documents.length) {
      this.documents = Array.from(this.$documents).map((el) => ({
        el,
        icons: el.querySelectorAll('.product-card__icon'),
        iconsWrapper: el.querySelector('.product-card__icons-wrapper')
      }))
    }

    this.titleLinesCharacters = []

    for (let i = 0; i < this.$title.children.length; i++) this.splitTitle(this.$title.children[i]).forEach((lineCharacters) => this.titleLinesCharacters.push(lineCharacters))

    this.getBounds()
    this.prepareAnimation()
    this.initAnimations()
    this.initScrollTriggers()
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'linesChars', target: title })[0].lines
    const linesCharacters = lines.map((line) => Splitting({ by: 'chars', target: line }).flatMap((lineCharacters) => lineCharacters.chars))
    /* eslint-enable object-property-newline */

    return linesCharacters
  }

  getBounds() {
    if (this.$documents.length) {
      const { height } = this.documents[0].iconsWrapper.getBoundingClientRect()
      const { height: iconHeight } = this.documents[0].icons[0].getBoundingClientRect()

      this.iconTranslateYValue = (height * 0.75 + iconHeight) / 2 + 1
    }

    const { right: leftSectionRight, width: leftSectionWidth } = this.$leftSection.getBoundingClientRect()
    const paddingContainer = store.w.w < 768 ? store.remToPixel(1.5) : store.w.w < 1024 ? store.remToPixel(2) : store.remToPixel(3)
    const productWidth = this.$product.getBoundingClientRect().width
    const productImageRight = this.$productImages[0].getBoundingClientRect().right
    const rightSectionWidth = this.$rightSection.getBoundingClientRect().width

    this.insetTopBottom = paddingContainer + 'px'
    this.insetLeftRight = paddingContainer + 'px'
    this.insetBorderRadius = '1rem'
    this.leftSectionTranslateValue = leftSectionWidth + paddingContainer
    this.productTranslateValue = leftSectionRight - productImageRight + productWidth / 2
    this.rightSectionTranslateValue = rightSectionWidth + paddingContainer
  }

  prepareAnimation() {
    if (store.w.w < 1024) {
      gsap.set([this.$leftSection, this.$rightSection], { yPercent: 100 })
    } else {
      gsap.set(this.$leftSection, { x: - this.leftSectionTranslateValue })
      gsap.set(this.$rightSection, { x: this.rightSectionTranslateValue })
    }

    for (let i = 1; i < this.$productImages.length; i++) gsap.set(this.$productImages[i], { autoAlpha: 0 })
    gsap.set(this.titleLinesCharacters, { alpha: 0 })
    for (let i = 1; i < this.$tabSections.length; i++) gsap.set(this.$tabSections[i], { autoAlpha: 0 })
  }

  initAnimations() {
    this.backgroundAnimation = gsap.to(this.$background, {
      ease: 'none',
      rotate: 15
    })

    this.clipPathAnimation = gsap.to(this.$el, {
      ease: 'none',
      '--inset-top-bottom': () => this.insetTopBottom,
      '--inset-left-right': () => this.insetLeftRight,
      '--inset-border-radius': () => this.insetBorderRadius
    })

    this.titleAnimation = gsap.timeline({ paused: true })

    this.titleLinesCharacters.forEach((lineCharacters, i) => {
      this.titleAnimation.to(lineCharacters, {
        alpha: 1,
        duration: 0.375,
        ease: 'beaucoup.alpha',
        stagger: 0.01875
      }, i * 0.0375)
    })
  }

  initScrollTriggers() {
    this.backgroundScrollTrigger = store.scrollTrigger.create({
      animation: this.backgroundAnimation,
      end: 'bottom top',
      scrub: 0.5,
      stop: 'top bottom',
      trigger: this.$el
    })

    this.clipPathScrollTrigger = store.scrollTrigger.create({
      animation: this.clipPathAnimation,
      end: 'top top',
      invalidateOnRefresh: true,
      scrub: true,
      stop: 'top 75%',
      trigger: this.$el
    })

    this.titleSrollTrigger = store.scrollTrigger.create({
      animation: this.titleAnimation,
      stop: 'top 25%',
      trigger: this.$el
    })
  }

  events() {
    for (let i = 0; i < this.$colors.length; i++) this.$colors[i].addEventListener('click', this.onColorClick.bind(this, i))
      if (this.$documents.length) for (let i = 0; i < this.documents.length; i++) this.documents[i].el.addEventListener('mouseenter', this.onDocumentEnter.bind(this, i))
    this.$leftButton.addEventListener('click', this.onLeftButtonClick)
    this.$leftClose.addEventListener('click', this.onLeftCloseClick)
    this.$overlay.addEventListener('click', this.onOverlayClick)
    this.$overlayMobile.addEventListener('click', this.onOverlayClick)
    this.$rightButton.addEventListener('click', this.onRightButtonClick)
    this.$rightClose.addEventListener('click', this.onRightCloseClick)
    for (let i = 0; i < this.$tabButtons.length; i++) this.$tabButtons[i].addEventListener('click', this.onTabButtonClick.bind(this, i, false))
  }

  onColorClick(index) {
    const animation = gsap.timeline()

    this.$productImages.forEach((productImage, i) => {
      const autoAlpha = i === index ? 1 : 0

      gsap.killTweensOf(productImage)

      animation.to(productImage, {
        autoAlpha,
        duration: 0.1875,
        ease: 'beaucoup.alpha'
      }, 0)

      this.$colors[i].classList.toggle('a', i === index)
      this.$colorsNames[i].classList.toggle('a', i === index)
    })
  }

  onDocumentEnter(index) {
    if (store.detect.isMobile) return

    gsap.killTweensOf(this.documents[index].icons)

    gsap.fromTo(this.documents[index].icons, { y: 0 }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      y: () => this.iconTranslateYValue
    })
  }

  onLeftButtonClick() {
    const animation = gsap.timeline({
      defaults: {
        duration: 1.1,
        ease: 'expo.out'
      }
    })

    if (store.w.w < 1024) {
      animation.to(this.$leftSection, { yPercent: 0 })
    } else {
      animation
        .to(this.$product, { x: this.productTranslateValue })
        .to(this.$leftSection, {
          duration: 1,
          ease: 'expo.out',
          x: 0
        }, 0.1)
    }
    
    animation.to([this.$leftButton, this.$rightButton], {
      autoAlpha: 0,
      duration: 0.1875,
      ease: 'beaucoup.alpha'
    }, 0)

    this.onTabButtonClick(0, true)

    if (store.w.w < 1024 && store.smoothScroll) {
      store.smoothScroll.stop()
      store.smoothScroll.scrollTo(this.$el, {
        force: true
      })
    }

    this.$overlay.classList.remove('pointer-events-none')
    this.$overlayMobile.classList.remove('pointer-events-none')
    this.$overlayMobile.classList.remove('opacity-0')
  }

  onLeftCloseClick() {
    const animation = gsap.timeline({
      defaults: {
        duration: 0.7,
        ease: 'expo.out'
      }
    })

    gsap.killTweensOf([this.$leftSection, this.$product, this.$leftButton, this.$rightButton])

    if (store.w.w < 1024) {
      animation.to(this.$leftSection, { yPercent: 100 })
    } else {
      animation
        .to(this.$leftSection, { x: - this.leftSectionTranslateValue })
        .to(this.$product, { x: 0 }, 0)
    }
    
    animation.to([this.$leftButton, this.$rightButton], {
      autoAlpha: 1,
      duration: 0.1875,
      ease: 'beaucoup.alpha'
    }, 0.1)

    store.w.w < 1024 && store.smoothScroll && store.smoothScroll.start()

    this.$overlay.classList.add('pointer-events-none')
    this.$overlayMobile.classList.add('pointer-events-none')
    this.$overlayMobile.classList.add('opacity-0')
  }

  onOverlayClick() {
    this.onLeftCloseClick()
    this.onRightCloseClick()
  }

  onRightButtonClick() {
    const animation = gsap.timeline({
      defaults: {
        duration: 1.1,
        ease: 'expo.out'
      }
    })

    if (store.w.w < 1024) {
      animation.to(this.$rightSection, { yPercent: 0 })
    } else {
      animation
        .to(this.$product, { x: - this.productTranslateValue })
        .to(this.$rightSection, {
          duration: 1,
          ease: 'expo.out',
          x: 0
        }, 0.1)
    }

    animation.to([this.$leftButton, this.$rightButton], {
      autoAlpha: 0,
      duration: 0.1875,
      ease: 'beaucoup.alpha'
    }, 0)

    if (store.w.w < 1024 && store.smoothScroll) {
      store.smoothScroll.stop()
      store.smoothScroll.scrollTo(this.$el, {
        force: true
      })
    }

    this.$overlay.classList.remove('pointer-events-none')
    this.$overlayMobile.classList.remove('pointer-events-none')
    this.$overlayMobile.classList.remove('opacity-0')
  }

  onRightCloseClick() {
    const animation = gsap.timeline({
      defaults: {
        duration: 0.7,
        ease: 'expo.out'
      }
    })

    gsap.killTweensOf([this.$rightSection, this.$product, this.$leftButton, this.$rightButton])

    if (store.w.w < 1024) {
      animation.to(this.$rightSection, { yPercent: 100 })
    } else {
      animation
        .to(this.$rightSection, { x: this.rightSectionTranslateValue })
        .to(this.$product, { x: 0 }, 0)
    }
    
    animation.to([this.$leftButton, this.$rightButton], {
      autoAlpha: 1,
      duration: 0.1875,
      ease: 'beaucoup.alpha'
    }, 0.1)

    store.w.w < 1024 && store.smoothScroll && store.smoothScroll.start()

    this.$overlay.classList.add('pointer-events-none')
    this.$overlayMobile.classList.add('pointer-events-none')
    this.$overlayMobile.classList.add('opacity-0')
  }

  onTabButtonClick(index, force = false) {
    const animation = gsap.timeline()

    this.$tabSections.forEach((tabSection, i) => {
      const autoAlpha = i === index ? 1 : 0
      const delay = i === index ? 0.1875 : 0
      const duration = force ? 0 : 0.1875

      gsap.killTweensOf(tabSection)

      animation.to(tabSection, {
        autoAlpha,
        duration,
        ease: 'beaucoup.alpha'
      }, delay)

      this.$tabButtons[i].classList.toggle('a', i === index)
      if (i === index) tabSection.children[0].scrollTop = 0
    })
  }

  resizeX() {
    gsap.set([this.$leftButton, this.$leftSection, this.$product, this.$rightButton, this.$rightSection], { clearProps: 'all' })

    store.smoothScroll && store.smoothScroll.start()

    this.$overlay.classList.add('pointer-events-none')
    this.$overlayMobile.classList.add('pointer-events-none')
    this.$overlayMobile.classList.add('opacity-0')

    this.getBounds()

    if (this.$documents.length) for (let i = 0; i < this.documents.length; i++) gsap.set(this.documents[i].icons, { y: 0 })

    if (store.w.w < 1024) {
      gsap.set([this.$leftSection, this.$rightSection], { yPercent: 100 })
    } else {
      gsap.set(this.$leftSection, { x: - this.leftSectionTranslateValue })
      gsap.set(this.$rightSection, { x: this.rightSectionTranslateValue })
    }
  }
}

/* eslint-enable max-lines */
