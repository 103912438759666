import gsap from 'gsap'
import store from '../util/store'

export default class Slider {
  constructor({ el, wrapper, items, offset, infinite = true }) {
    this.$el = el
    this.$wrapper = wrapper
    this.$items = items
    this.offset = offset
    this.infinite = infinite

    this.init()
    this.bindMethods()
    this.events()
  }

  bindMethods() {
    this.formatContent = this.formatContent.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  init() {
    this.offset = 6.8

    this.wrapperWidth = this.$el.getBoundingClientRect().width
    this.initialWidth = 0
    this.mapper = gsap.utils.mapRange(0, window.innerWidth, 0, 1)

    this.formatContent()

    this.items.forEach((item) => {
      this.initialWidth += item.width + store.remToPixel(this.offset)
    })

    this.finalWidth = this.initialWidth

    this.calculateClones()

    this.currentTranslation = - this.initialWidth + store.remToPixel(this.offset);
    this.speed = 2.5;
    // this.currentScrollDelta = 0
    // this.targetScrollDelta = 0
    this.currentIndex = null
    this.closestIndex = null
    this.paused = false
    this.lerp = {
      end: 0,
      current: this.currentTranslation - this.initialWidth / 2,
      start: 0,
      target: this.currentTranslation - this.initialWidth / 2,
      factor: 0.2
    };

    if (this.initialWidth > this.wrapperWidth) {
      this.$wrapper.style.transform = `translateX(${this.lerp.current}px)`
      this.$wrapper.style.justifyContent = 'start'
      this.$wrapper.style.cursor = 'grab'
      this.$wrapper.style.width = 'auto'
    } else {
      this.$wrapper.style.transform = 'translateX(0px)'
      this.$wrapper.style.justifyContent = 'center'
      this.$wrapper.style.cursor = 'default'
      this.$wrapper.style.width = '100%'
    }
  }

  events() {
    document.body.addEventListener('mouseleave', this.onMouseUp)
    this.$wrapper.addEventListener('mousedown', this.onMouseDown)
    this.$wrapper.addEventListener('mousemove', this.onMouseMove)
    this.$wrapper.addEventListener('mouseup', this.onMouseUp)
    this.$wrapper.addEventListener('touchstart', this.onMouseDown)
    this.$wrapper.addEventListener('touchmove', this.onMouseMove)
    this.$wrapper.addEventListener('touchend', this.onMouseUp)
    this.$wrapper.addEventListener('mouseenter', this.onMouseEnter)
    this.$wrapper.addEventListener('mouseleave', this.onMouseLeave)
  }

  formatContent() {
    this.items = []

    Array.from(this.$items).forEach((el) => {
      const rect = el.getBoundingClientRect()

      this.items.push({
        el,
        left: rect.left,
        right: rect.right,
        width: rect.width,
        progress: this.mapper(rect.left),
        clone: false
      })
    })
  }

  calculateClones() {
    if (this.initialWidth < this.wrapperWidth) return

    while (this.finalWidth < this.wrapperWidth + this.initialWidth) {
      this.createClones()
    }
  }

  removeClones() {
    this.$wrapper.style.transform = 'translateX(0px)'
    this.$wrapper.style.justifyContent = 'center'

    this.items.filter((item) => {
      if (item.clone) {
        item.el.remove()

        return false
      }

      return item
    })
  }

  createClones() {
    this.items.forEach((item) => {
      const clone = item.el.cloneNode(true)

      this.$wrapper.appendChild(clone)

      const rect = clone.getBoundingClientRect()

      this.items.push({
        el: clone,
        left: rect.left,
        right: rect.right,
        width: rect.width,
        progress: this.mapper(rect.left),
        clone: true
      })
    })

    this.finalWidth += this.initialWidth
  }

  onMouseEnter() {
    if (store.detect.isMobile || store.w.w <= 1024) return

    this.isEnter = true
  }

  onMouseLeave() {
    if (store.detect.isMobile || store.w.w <= 1024) return

    this.isEnter = false
  }

  onMouseDown(e) {
    if (this.initialWidth < this.wrapperWidth) return

    this.isDown = true
    this.items.forEach((item) => item.el.classList.remove('active'))
    this.lerp.start = e.changedTouches ? e.changedTouches[0].clientX : e.clientX
    this.$wrapper.style.cursor = 'grabbing'
  }

  onMouseUp() {
    if (this.initialWidth < this.wrapperWidth) return

    this.isDown = false
    this.isMoving = false
    this.$wrapper.classList.remove('grabbing')
    this.lerp.end = this.lerp.target
    this.$wrapper.style.cursor = 'grab'
  }

  onMouseMove(e) {
    if (!this.isDown || this.initialWidth < this.wrapperWidth) return

    if (!this.isMoving) {
      this.isMoving = true
      this.$wrapper.classList.add('grabbing')
    }

    this.lerp.target = this.lerp.end + (e.changedTouches ? e.changedTouches[0].clientX : e.clientX) - this.lerp.start
  }

  destroy() {
    document.body.removeEventListener('mouseleave', this.onMouseUp)
  }

  resizeX() {
    if (store.detect.isMobile || store.w.w <= 1024) {
      this.removeClones()
      this.$wrapper.style.justifyContent = 'start'

      return
    }

    if (this.initialWidth < this.wrapperWidth) this.removeClones()
    else this.calculateClones()

    this.currentTranslation = - this.$wrapper.getBoundingClientRect().width / 2 + store.remToPixel(this.offset);
    this.currentScrollDelta = 0
    this.targetScrollDelta = 0
    this.lerp = {
      end: 0,
      current: this.currentTranslation - this.initialWidth / 2,
      start: 0,
      target: this.currentTranslation - this.initialWidth / 2,
      factor: 0.2
    };

    if (this.initialWidth > this.wrapperWidth) {
      this.$wrapper.style.transform = `translateX(${this.lerp.current}px)`
      this.$wrapper.style.justifyContent = 'start'
    }
  }

  // scroll(e) {
  //   if (e.type === 'wheel' || store.detect.isMobile || this.initialWidth < this.wrapperWidth) return

  //   if (this.currentScroll) this.targetScrollDelta = Math.min(Math.max(Math.abs(this.currentScroll - store.currentScroll), 0), 50) / 50
  //   this.currentScroll = store.currentScroll
  // }

  update() {
    if (this.initialWidth < this.wrapperWidth || store.detect.isMobile || store.w.w <= 1024) return

    // this.currentScrollDelta += (this.targetScrollDelta - this.currentScrollDelta) * 0.3

    if (!this.isDown && !this.isEnter && !this.paused) {
      this.lerp.target -= this.speed;
      this.lerp.end = this.lerp.target
    }

    this.lerp.current = store.lerp(this.lerp.current, this.lerp.target, 0.04)

    this.translateValue = gsap.utils.wrap(store.remToPixel(this.offset), this.currentTranslation, this.lerp.current)

    this.$wrapper.style.transform = `translateX(${this.translateValue}px)`
  }
}
