/* eslint-disable */
import { domReady } from '@roots/sage/client'
import Site from './Site'
import Grid from './util/Grid'

/**
 * app.main
 * @param {object} err Handle HMR error
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err)
  }

  document.fonts.ready.then(() => {
    new Site()
  })
  
  new Grid()
}

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main)
import.meta.webpackHot?.accept(main)
