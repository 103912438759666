import Block from './Block';
import { gsap } from 'gsap';
import store from '../util/store';

export default class Form extends Block {
  bindMethods() {
    this.onSubmitClick = this.onSubmitClick.bind(this)
  }

  getElems() {
    this.$submit = this.$el.querySelector('.form__submit')
    this.$success = this.$el.querySelector('.form__success')
    this.$error = this.$el.querySelector('.form__error')
    this.$formGroups = this.$el.querySelectorAll('.form__group')
  }

  init() {
    setTimeout(() => {
      this.offset = - (store.header.$el.offsetHeight + (store.w.w < 1024 ? store.remToPixel(1) * 2 : store.remToPixel(1.5) * 2))
    }, 0)
  }

  events() {
    this.$submit.addEventListener('click', this.onSubmitClick)
  }

  resize() {
    setTimeout(() => {
      this.offset = - (store.header.$el.offsetHeight + (store.w.w < 1024 ? store.remToPixel(1) * 2 : store.remToPixel(1.5) * 2))
    }, 0)
  }

  async onSubmitClick() {
    const formData = new FormData(this.$el)
    const fields = []

    for (const [key, value] of formData.entries()) {
      if (key !== 'g-recaptcha-response' && key !== 'legalConsentOptions' && !key.includes('objectTypeId')) {
        const input = this.$el.querySelector(`[name="${key}"]`)

        if (input.hasAttribute('required') && value.length === 0) {
          input.classList.add('error')
          this.hasErrors = true
        } else if (input.hasAttribute('required') && value.length !== 0) input.classList.remove('error')

        fields.push({
          objectTypeId: formData.get(`${key}-objectTypeId`),
          name: key,
          value
        })
      }
    }

    if (this.hasErrors) {
      this.hasErrors = false

      store.smoothScroll.scrollTo(this.$el, {
        force: true,
        offset: this.offset
      })
    } else {
      this.hasErrors = false

      gsap.to(this.$submit, {
        pointerEvents: 'none',
        alpha: 0.5
      })

      const response = await fetch(`${window.location.origin}/wp-json/beaucoup/v1/form_submit`, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          fields,
          formId: this.$el.dataset.formId
        })
      })
        .then((res) => {
          gsap.to(this.$submit, {
            pointerEvents: 'auto',
            alpha: 1
          })

          return res.json()
        })
        .catch((err) => {
          console.log(err);
        })

      if ('status' in response && response.status === 'error') {
        this.$error.innerHTML = response.message
        this.$error.style.display = 'flex'
        this.$success.style.display = 'none'
      }

      if (response.inlineMessage) {
        this.$error.style.display = 'none'

        // gsap.to(this.$el, {
        //   autoAlpha: 0,
        //   duration: 0.8,
        //   ease: 'beaucoup.out',
        //   onStart: () => {
        //     this.$overflow.scrollTop = 0
        //   }
        // })

        this.$success.innerHTML = response.inlineMessage
        this.$success.style.display = 'flex'
        console.log(response);
        gsap.to(this.$success, {
          autoAlpha: 1,
          onComplete: () => {
            setTimeout(() => {
              gsap.set(this.$el, { autoAlpha: 1 })
              
              this.$error.style.display = 'none'
              this.$success.style.display = 'none'

              this.$el.reset()
            }, 2000)
          }
        })
      }
    }
  }
}
