import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class ContentPageCover extends Block {
  getElems() {
    this.$content = this.$el.querySelector('.content-page-cover__content')
    this.$gradient = this.$el.querySelector('.content-page-cover__gradient')
    this.$imageWrapper = this.$el.querySelector('.content-page-cover__image')
    if (this.$imageWrapper) this.$image = this.$imageWrapper.querySelector('img')
    this.$leafs = this.$el.querySelectorAll('.content-page-cover__leaf')
    this.$text = this.$el.querySelector('.content-page-cover__text')
    if (this.$text) this.$textWrapper = this.$text.parentNode
    this.$title = this.$el.querySelector('.content-page-cover__title')
    this.$titleWrapper = this.$title.parentNode
  }

  init() {
    this.mobileScreenWidthThreshold = 1024
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    this.titleLinesCharactersData = this.splitTitle(this.$title)

    if (this.$imageWrapper && !this.isCurrentlyMobileScreen) {
      this.getBounds()
      this.setImageStyles()
    }
    this.initAnimations()
    this.initScrollTriggers()
    this.prepareAnimation()
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharactersData = []
    
    lines.forEach((line, lineIndex) => {
      linesCharactersData[lineIndex] = {
        characters: [],
        charactersWrappers: []
      }
      
      /* eslint-disable object-property-newline */
      Splitting({ by: 'charactersWrappers', target: line }).forEach((lineCharactersData) => {
        linesCharactersData[lineIndex].characters.push(...lineCharactersData.charactersWrappers.characters)
        linesCharactersData[lineIndex].charactersWrappers.push(...lineCharactersData.charactersWrappers.charactersWrappers)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharactersData
  }

  getBounds() {
    this.imageTransformValue = store.w.w / 20
    this.imageWrapperRect = this.$imageWrapper.getBoundingClientRect()
    this.imageWrapperOffset = store.w.w < 768 ? store.remToPixel(3) : store.w.w < 1024 ? store.remToPixel(4) : store.remToPixel(6)
    this.isImageWrapperVisible = this.imageWrapperRect.top < store.w.h

    this.insetTopBottom = this.imageWrapperOffset / 2 / this.imageWrapperRect.height * 100 + '%'
    this.insetLeftRight = this.imageWrapperOffset / 2 / this.imageWrapperRect.width * 100 + '%'
    this.insetBorderRadius = '1rem'
  }

  setImageStyles() {
    this.$image.style.height = `calc(100% + ${this.imageTransformValue}px)`
    this.$image.style.marginTop = `-${this.imageTransformValue / 2}px`
  }
  
  initAnimations() {
    this.gradientAnimation = gsap.to(this.$gradient, {
      alpha: 0,
      ease: 'none'
    })

    if (this.isCurrentlyMobileScreen) return

    if (this.$imageWrapper) {
      this.clipPathAnimation = gsap.to(this.$el, {
        ease: 'none',
        '--inset-top-bottom': () => this.insetTopBottom,
        '--inset-left-right': () => this.insetLeftRight,
        '--inset-border-radius': () => this.insetBorderRadius
      })
    }

    this.contentAnimation = gsap.fromTo(this.$content, {
      scale: 1,
      y: 0
    }, {
      ease: 'none',
      scale: 0.9,
      y: store.w.h * 0.33
    }, 0)

    if (this.$imageWrapper) {
      this.imageAnimation = gsap.fromTo(this.$image, {
        y: () => - this.imageTransformValue
      }, {
        ease: 'none',
        y: () => this.imageTransformValue
      })
    }

    this.textAnimation = gsap.to(this.$content, {
      alpha: 0,
      ease: 'beaucoup.alpha'
    })

    this.titleLinesCharactersDataAnimation = gsap.timeline()

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) {
      this.titleLinesCharactersDataAnimation.to(this.titleLinesCharactersData[i].characters, {
        alpha: 0,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, i * 0.05)
    }
  }

  initScrollTriggers() {
    this.gradientScrollTrigger = store.scrollTrigger.create({
      animation: this.gradientAnimation,
      end: 'bottom bottom',
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    if (this.isCurrentlyMobileScreen) return

    if (this.$imageWrapper) {
      this.clipPathScrollTrigger = store.scrollTrigger.create({
        animation: this.clipPathAnimation,
        end: 'top top',
        invalidateOnRefresh: true,
        scrub: true,
        start: 'top 75%',
        trigger: this.$imageWrapper
      })
    }

    this.contentScrollTrigger = store.scrollTrigger.create({
      animation: this.contentAnimation,
      end: () => `+=${store.w.h * 0.5}px top`,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    if (this.$imageWrapper) {
      this.imageScrollTrigger = store.scrollTrigger.create({
        animation: this.imageAnimation,
        invalidateOnRefresh: true,
        scrub: true,
        trigger: this.$imageWrapper
      })
    }

    this.textScrollTrigger = store.scrollTrigger.create({
      animation: this.textAnimation,
      end: () => `+=${store.w.h * 0.5}px top`,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.titleScrollTrigger = store.scrollTrigger.create({
      animation: this.titleWrapperAnimation,
      end: () => `+=${store.w.h * 0.5}px top`,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.titleLinesCharactersDataScrollTrigger = store.scrollTrigger.create({
      animation: this.titleLinesCharactersDataAnimation,
      end: () => `+=${store.w.h * 0.5}px top`,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })
  }

  prepareAnimation() {
    if (this.$text) gsap.set(this.$textWrapper, { alpha: 0 })

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) gsap.set(this.titleLinesCharactersData[i].charactersWrappers, { alpha: 0 })

    if (this.isCurrentlyMobileScreen) return

    if (this.$imageWrapper && this.isImageWrapperVisible) {
      setTimeout(() => {
        gsap.set(this.$imageWrapper, { y: store.w.h - this.imageWrapperRect.top })
      }, 100)
    }

    gsap.set(this.$leafs, {
      rotate: -50,
      xPercent: -50,
      yPercent: -50
    })
  }

  appear() {
    if (this.$text) {
      gsap.to(this.$textWrapper, {
        alpha: 1,
        delay: 0.75,
        duration: 0.5,
        ease: 'beaucoup.alpha'
      })
    }

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) {
      gsap.to(this.titleLinesCharactersData[i].charactersWrappers, {
        alpha: 1,
        delay: 0.5 + i * 0.05,
        duration: 0.75,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      })
    }

    if (this.isCurrentlyMobileScreen) return

    if (this.$imageWrapper && this.isImageWrapperVisible) {
      gsap.to(this.$imageWrapper, {
        delay: 0.3,
        duration: 0.8,
        ease: 'expo.out',
        y: 0
      })
    }

    gsap.to(this.$leafs, {
      ease: 'expo.out',
      duration: 2.4,
      rotate: 0,
      stagger: 0.075,
      xPercent: 0,
      yPercent: 0
    })
  }
  
  resize() {
    const isNewStateMobileScreen = store.w.w < this.mobileScreenWidthThreshold

    if (isNewStateMobileScreen !== this.isCurrentlyMobileScreen) {
      this.isCurrentlyMobileScreen = isNewStateMobileScreen
      this.screenSizeChange()
    }

    if (this.$imageWrapper && !this.isCurrentlyMobileScreen) {
      this.getBounds()
      this.setImageStyles()
    }
  }

  screenSizeChange() {
    const scrollTriggers = [
      this.clipPathScrollTrigger,
      this.contentScrollTrigger,
      this.imageScrollTrigger,
      this.textScrollTrigger,
      this.titleScrollTrigger,
      this.titleLinesCharactersDataScrollTrigger
    ]
  
    if (this.isCurrentlyMobileScreen) {
      scrollTriggers.forEach((scrollTrigger) => scrollTrigger && scrollTrigger.kill(true))

      if (this.$imageWrapper) {
        this.$image.style.height = '100%'
        this.$image.style.marginTop = '0px'
      }
    } else {
      this.initAnimations()
      this.initScrollTriggers()
    }
  }
}
