import gsap from 'gsap'
import store from './store'
import Submenu from './Submenu'
import ToggleManager from './ToggleManager'

export default class Menu extends ToggleManager {
  constructor({ el, overlay, toggler }) {
    super(el, overlay, toggler)
  }

  getElems() {
    this.$panel = this.$el.querySelector('.menu__panel')
    this.$panelBackground = this.$panel.querySelector('.menu__background')
    this.$panelItemsWrappers = this.$panel.querySelectorAll('.menu__items')
    this.$panelImage = this.$panel.querySelector('.menu__image')
    this.$panelButton = this.$panel.querySelector('.button')
    this.$panelOverflow = this.$el.querySelector('.menu__overflow')
    this.$panelSubmenus = this.$el.querySelectorAll('.submenu')
    this.$togglerOpen = this.$toggler.querySelector('.menu__open')
    this.$togglerClose = this.$toggler.querySelector('.menu__close')
  }

  init() {
    super.init()

    this.isTransitioning = false
    this.mobileScreenWidthThreshold = 1024
    this.isCurrentlyMobileScreen = store.w.w < this.mobileScreenWidthThreshold
    this.panelItemsWrappers = []
    
    this.$panelItemsWrappers.forEach((panelItemsWrapper) => {
      const items = []

      panelItemsWrapper.querySelectorAll('.menu__item').forEach((panelItem) => {
        let submenu = null

        /* eslint-disable object-property-newline */
        if (panelItem.dataset.index) submenu = new Submenu({ el: Array.from(this.$panelSubmenus).find((panelSubmenu) => panelSubmenu.dataset.index === panelItem.dataset.index), overlay: this.$overlay, toggler: panelItem })
        items.push({ el: panelItem, isHovering: false, submenu, wrapper: panelItemsWrapper })
        /* eslint-enable object-property-newline */
      })

      this.panelItemsWrappers.push({
        activeIndex: -1,
        activeSubindex: -1,
        el: panelItemsWrapper,
        items
      })
    })

    if (this.isCurrentlyMobileScreen) {
      this.setImageDisplay()
      this.prepareAnimation()
    }
  }

  setImageDisplay() {
    this.$panelImage.classList.remove('hidden')

    this.panelBottomPosition = Math.round(this.$panel.getBoundingClientRect().bottom)
    this.panelButtonBottomPosition = Math.round(this.$panelButton.getBoundingClientRect().bottom)

    const threshold = this.panelBottomPosition - Math.round(store.remToPixel(1.5))

    if (this.panelButtonBottomPosition > threshold) this.$panelImage.classList.add('hidden')
  }

  prepareAnimation() {
    gsap.set(this.$panelBackground, { yPercent: -101 })
    gsap.set([this.$panelButton, this.$panelImage, this.$panelOverflow], { autoAlpha: 0 })
    this.panelItemsWrappers.forEach((panelItemsWrapper) => panelItemsWrapper.items.forEach((item) => gsap.set(item.el.parentNode, { autoAlpha: 0 })))
  }

  events() {
    super.events()

    window.addEventListener('openedToggleManagerClosed', ({ detail }) => {
      let index = -1
      let itemIndex = -1
    
      this.panelItemsWrappers.some((panelItemsWrapper, i) => {
        itemIndex = panelItemsWrapper.items.findIndex((panelItem) => panelItem.submenu === detail.toggleManager)

        if (itemIndex > -1) {
          index = i

          return true
        }

        return false
      })
    
      if (index > -1 && itemIndex > -1) {
        setTimeout(() => {
          const panelItem = this.panelItemsWrappers[index].items[itemIndex]

          if (!panelItem.isHovering && !panelItem.submenu.isTransitioning) this.onPanelItemLeave(index, itemIndex)
        }, 0)
      }
    })

    this.panelItemsWrappers.forEach((panelItemsWrapper, i) => panelItemsWrapper.items.forEach((item, j) => {
      item.el.addEventListener('click', this.onPanelItemClick.bind(this, i, j))
      item.el.addEventListener('mouseenter', this.onPanelItemEnter.bind(this, i, j))
      item.el.addEventListener('mouseleave', this.onPanelItemLeave.bind(this, i, j))
    }))
  }

  onPanelItemClick(index, itemIndex) {
    const openedSubmenu = this.panelItemsWrappers[index].items.findIndex((panelItem) => panelItem.submenu && panelItem.submenu.isOpen)

    if (openedSubmenu > -1 && openedSubmenu !== itemIndex) return

    if (this.panelItemsWrappers[index].items[itemIndex].submenu && this.isCurrentlyMobileScreen && this.panelItemsWrappers[index].items[itemIndex].submenu.isOpen) this.onPanelItemLeave(index, itemIndex)

    if (!this.panelItemsWrappers[index].items[itemIndex].submenu) this.isTransitioning = true

    this.panelItemsWrappers[index].items.forEach((item, i) => item.el.classList.toggle('a', i === itemIndex))
  }

  onPanelItemEnter(index, itemIndex) {
    if (this.isCurrentlyMobileScreen || this.isTransitioning) return

    this.panelItemsWrappers[index].items[itemIndex].isHovering = true

    this.panelItemsWrappers[index].items.forEach((item, i) => item.el.classList.toggle('a', i === itemIndex || item.submenu && item.submenu.isOpen))
  }

  onPanelItemLeave(index, itemIndex) {
    if (!this.isCurrentlyMobileScreen && this.isTransitioning) return

    this.panelItemsWrappers[index].items[itemIndex].isHovering = false

    if (this.panelItemsWrappers[index].items[itemIndex].submenu && this.panelItemsWrappers[index].items[itemIndex].submenu.isOpen) return

    const openedSubmenus = this.panelItemsWrappers.flatMap((panelItemsWrapper) => panelItemsWrapper.items.filter((item) => item.submenu && item.submenu.isOpen))

    this.panelItemsWrappers[index].items.forEach((item, i) => item.el.classList.toggle('a', openedSubmenus.length ? item.submenu && item.submenu.isOpen : i === this.panelItemsWrappers[index].activeIndex))
  }

  open() {
    super.open().then(() => {
      this.openingAnimation
        .set(this.$panelOverflow, { autoAlpha: 1 })
        .to(this.$panelBackground, { yPercent: 0 })

      const openingAnimationParams = {
        autoAlpha: 1,
        duration: 0.3,
        ease: 'none',
        stagger: 0.025
      }

      this.panelItemsWrappers.forEach((panelItemsWrapper) => panelItemsWrapper.items.forEach((item) => this.openingAnimation.to(item.el.parentNode, openingAnimationParams, 0.1)))

      this.openingAnimation.to([this.$panelImage, this.$panelButton], openingAnimationParams, openingAnimationParams.stagger * this.panelItemsWrappers.reduce((sum, panelItemsWrapper) => sum + panelItemsWrapper.items.length, 0))

      this.$el.classList.remove('max-lg:pointer-events-none')
      this.$togglerOpen.classList.add('opacity-0', 'pointer-events-none')
      this.$togglerClose.classList.remove('opacity-0', 'pointer-events-none')
    })
  }

  close(force = false) {
    super.close(force)

    if (this.isCurrentlyMobileScreen) {
      const openedSubmenus = this.panelItemsWrappers.flatMap((panelItemsWrapper) => panelItemsWrapper.items.filter((item) => item.submenu && item.submenu.isOpen))
      
      if (openedSubmenus.length) openedSubmenus.forEach((item) => item.submenu.close())

      this.closingAnimation.to(this.$panelBackground, { yPercent: -101 }, 0.05)

      const closingAnimationParams = {
        autoAlpha: 0,
        duration: force ? 0 : 0.1,
        ease: 'none'
      }

      this.panelItemsWrappers.forEach((panelItemsWrapper) => panelItemsWrapper.items.forEach((item) => this.closingAnimation.to(item.el.parentNode, closingAnimationParams, 0)))

      this.closingAnimation.to([this.$panelImage, this.$panelButton], {
          autoAlpha: 0,
          duration: force ? 0 : 0.1,
          ease: 'none',
          onComplete: () => {
            gsap.set(this.$panelOverflow, { autoAlpha: 0 })
          }
        }, 0)

      this.$el.classList.add('max-lg:pointer-events-none')
      this.$togglerOpen.classList.remove('opacity-0', 'pointer-events-none')
      this.$togglerClose.classList.add('opacity-0', 'pointer-events-none')
    }
  }

  onPageChange({ location }) {
    this.isTransitioning = false

    this.panelItemsWrappers.forEach((panelItemsWrapper) => {
      for (let i = 0; i < panelItemsWrapper.items.length; i++) if (panelItemsWrapper.items[i].submenu) panelItemsWrapper.items[i].submenu.isTransitioning = false

      if (panelItemsWrapper.activeIndex > -1) {
        panelItemsWrapper.items[panelItemsWrapper.activeIndex].el.classList.remove('a')
        if (panelItemsWrapper.activeSubindex > -1) {
          panelItemsWrapper.items[panelItemsWrapper.activeIndex].submenu.activePanelItemIndex = 0
          panelItemsWrapper.items[panelItemsWrapper.activeIndex].submenu.hoveredPanelItemIndex = 0
          panelItemsWrapper.items[panelItemsWrapper.activeIndex].submenu.$panelItems[panelItemsWrapper.activeSubindex].classList.remove('a')
        }
      }
  
      panelItemsWrapper.activeIndex = -1
      panelItemsWrapper.activeSubindex = -1
  
      for (let i = 0; i < panelItemsWrapper.items.length; i++) {
        if (panelItemsWrapper.items[i].submenu) {
          for (let j = 0; j < panelItemsWrapper.items[i].submenu.$panelItems.length; j++) {
            if (panelItemsWrapper.items[i].submenu.$panelItems[j].href === location.href) {
              panelItemsWrapper.activeIndex = i
              panelItemsWrapper.activeSubindex = j
            }
          }
        } else if (panelItemsWrapper.items[i].el.href === location.href) panelItemsWrapper.activeIndex = i
      }
  
      if (panelItemsWrapper.activeIndex > -1) {
        panelItemsWrapper.items[panelItemsWrapper.activeIndex].el.classList.add('a')
        if (panelItemsWrapper.activeSubindex > -1) {
          panelItemsWrapper.items[panelItemsWrapper.activeIndex].submenu.activePanelItemIndex = panelItemsWrapper.activeSubindex
          panelItemsWrapper.items[panelItemsWrapper.activeIndex].submenu.hoveredPanelItemIndex = panelItemsWrapper.activeSubindex
          panelItemsWrapper.items[panelItemsWrapper.activeIndex].submenu.$panelItems[panelItemsWrapper.activeSubindex].classList.add('a')
        }
      }
    })
  }

  resize() {
    const isNewStateMobileScreen = store.w.w < this.mobileScreenWidthThreshold

    if (isNewStateMobileScreen !== this.isCurrentlyMobileScreen) {
      this.isCurrentlyMobileScreen = isNewStateMobileScreen
      this.screenSizeChange(isNewStateMobileScreen)
    }

    isNewStateMobileScreen && this.setImageDisplay()
  }

  screenSizeChange(isMobileScreen) {
    if (isMobileScreen) {
      this.prepareAnimation()
    } else {
      this.isOpen && this.close(true)

      gsap.set(this.$panelBackground, { yPercent: 0 })
      gsap.set([this.$panelButton, this.$panelImage], { autoAlpha: 1 })
      this.panelItemsWrappers.forEach((panelItemsWrapper) => panelItemsWrapper.items.forEach((item) => gsap.set(item.el.parentNode, { autoAlpha: 1 })))
    }
  }
}
