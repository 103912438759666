/* eslint-disable max-lines */
import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class KeyFigure extends Block {
  bindMethods() {
    this.render = this.render.bind(this)
  }

  getElems() {
    this.$canvas = this.$el.querySelector('.key-figure__canvas')
    this.$circle = this.$el.querySelector('.key-figure__circle')
    this.$gradientCircle = this.$el.querySelector('.key_figure__gradient-circle')
    this.$inner = this.$el.querySelector('.key-figure__inner')
    this.$number = this.$el.querySelector('.key-figure__number')
    this.$text = this.$el.querySelector('.key-figure__text')
    this.$title = this.$el.querySelector('.key-figure__title')
  }

  init() {
    this.canvasContext = this.$canvas.getContext('2d')
    this.currentFrame = { value: 0 }
    this.framesCount = this.$el.dataset.framesCount
    this.framesDirectoryUri = this.$el.dataset.framesDirectoryUri

    this.frames = []

    for (let i = 0; i < this.framesCount; i++) {
      const frame = new Image()

      frame.src = `${this.framesDirectoryUri}frame_${(i + 1).toString().padStart(4, '0')}.webp`

      this.frames.push(frame)
    }

    this.frames[0].addEventListener('load', () => {
      this.getBounds()
      this.setClipPath()
      this.prepareAnimation()
      this.initAnimations()
      this.initScrollTriggers()
      this.render()
    })

    this.numberInnerText = this.$number.innerText
    this.titleLinesCharacters = this.splitTitle(this.$title)
  }

  getBounds() {
    this.$canvas.width = store.w.w
    this.$canvas.height = store.w.h

    this.canvasHeight = this.$canvas.height
    this.canvasWidth = this.$canvas.width
    const canvasRatio = this.canvasWidth / this.canvasHeight

    const frame = this.frames[this.currentFrame.value]
    const frameHeight = frame.height
    const frameWidth = frame.width
    const frameRatio = frameWidth / frameHeight

    if (frameRatio > canvasRatio) {
      this.dWidth = this.canvasHeight * frameRatio;
      this.dHeight = this.canvasHeight;
      this.dx = (this.canvasWidth - this.dWidth) / 2;
      this.dy = 0;
    } else {
      this.dWidth = this.canvasWidth;
      this.dHeight = this.canvasWidth / frameRatio;
      this.dx = 0;
      this.dy = (this.canvasHeight - this.dHeight) / 2;
    }

    this.elRect = this.$el.getBoundingClientRect()
    this.elOffset = store.remToPixel(5)

    this.insetTopBottom = this.elOffset / 2 / this.elRect.height * 100 + '%'
    this.insetLeftRight = '6%'
    this.insetBorderRadius = store.w.w < 768 ? '0.8rem' : '1rem'

    this.numberRect = this.$number.getBoundingClientRect()
    this.numberParentRect = this.$number.parentNode.getBoundingClientRect()
    this.numberTranslateValue = this.numberParentRect.height / 2 - (this.numberRect.height + store.remToPixel(0.5)) / 2

    this.textRect = this.$text.getBoundingClientRect()
  }

  setClipPath() {
    gsap.set(this.$el, {
      '--inset-top-bottom': () => this.insetTopBottom,
      '--inset-left-right': () => this.insetLeftRight,
      '--inset-border-radius': () => this.insetBorderRadius
    })
  }
  
  prepareAnimation() {
    /* eslint-disable object-property-newline */
    gsap.set(this.$circle, { alpha: 0, scale: 0.6 })
    gsap.set(this.$gradientCircle, { alpha: 0, scale: 0.85 })
    gsap.set(this.$number, { alpha: 0, innerText: 0, y: this.numberTranslateValue })
    gsap.set(this.$text, { alpha: 0, y: this.numberTranslateValue })
    gsap.set(this.titleLinesCharacters, { alpha: 0 })
    /* eslint-enable object-property-newline */
  }

  initAnimations() {
    this.clipPathAnimation = gsap.fromTo(this.$el, {
      '--inset-top-bottom': () => this.insetTopBottom,
      '--inset-left-right': () => this.insetLeftRight,
      '--inset-border-radius': () => this.insetBorderRadius
    }, {
      ease: 'none',
      '--inset-top-bottom': '0%',
      '--inset-left-right': '0%',
      '--inset-border-radius': '0rem'
    })

    this.enteringAnimation = gsap.timeline({ defaults: { duration: 1 }})

    this.enteringAnimation
      .to(this.$circle, {
        alpha: 1,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      })
      .to(this.$circle, {
        ease: 'power2.out',
        scale: 1
      }, 0)
      .to(this.$gradientCircle, {
        alpha: 1,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      }, 0.05)
      .to(this.$gradientCircle, {
        ease: 'power2.out',
        scale: 1
      }, 0.05)
      .to(this.$number, {
        alpha: 1,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      }, 0.1)
      .to(this.$text, {
        alpha: 1,
        duration: 0.25,
        ease: 'beaucoup.alpha'
      }, 0.5)
      .fromTo([this.$number, this.$text], {
        y: () => this.numberTranslateValue
      }, {
        duration: 0.5,
        ease: 'power2.out',
        stagger: 0.025,
        y: 0
      }, 0.5)
    
    /* eslint-disable object-property-newline */
    this.enteringParallaxAnimation = gsap.fromTo(this.$inner, { yPercent: -25 }, { ease: 'none', yPercent: 0 })
    /* eslint-enable object-property-newline */

    this.enteringTitleAnimation = gsap.timeline()

    this.titleLinesCharacters.forEach((lineCharacters, i) => {
      this.enteringTitleAnimation.to(lineCharacters, {
        alpha: 1,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, i * 0.05)
    })

    this.framesAnimation = gsap.to(this.currentFrame, {
      ease: 'none',
      onUpdate: this.render,
      snap: 'value',
      value: this.framesCount - 1
    })

    this.gradientCircleAnimation = gsap.timeline({
      defaults: {
        duration: 1
      }
    })

    this.gradientCircleAnimation
      .to(this.$gradientCircle, {
        ease: 'power2.out',
        rotate: 405
      })
      .to(this.$number, {
        ease: 'power2.out',
        innerText: this.numberInnerText,
        snap: {
          innerText: 1
        }
      }, 0)

    /* eslint-disable object-property-newline */
    this.leavingParallaxAnimation = gsap.fromTo(this.$inner, { yPercent: 0 }, { ease: 'none', yPercent: 50 })
    /* eslint-enable object-property-newline */
    
    this.leavingTitleAnimation = gsap.timeline()

    this.titleLinesCharacters.forEach((lineCharacters, i) => {
      this.leavingTitleAnimation.to(lineCharacters, {
        alpha: 0,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, i * 0.05)
    })
  }

  initScrollTriggers() {
    this.clipPathScrollTrigger = store.scrollTrigger.create({
      animation: this.clipPathAnimation,
      end: 'top 25%',
      scrub: true,
      start: 'top bottom',
      trigger: this.$el
    })

    this.enteringScrollTrigger = store.scrollTrigger.create({
      animation: this.enteringAnimation,
      end: 'bottom bottom',
      invalidateOnRefresh: true,
      scrub: true,
      start: store.w.h / 2 + 'px top',
      trigger: this.$el
    })

    this.enteringParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.enteringParallaxAnimation,
      end: 'top top',
      scrub: true,
      trigger: this.$el
    })

    this.enteringTitleScrollTrigger = store.scrollTrigger.create({
      animation: this.enteringTitleAnimation,
      end: 'top 25%',
      scrub: true,
      start: 'top 75%',
      trigger: this.$el
    })

    this.framesScrolLTrigger = store.scrollTrigger.create({
      animation: this.framesAnimation,
      end: 'bottom top',
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.gradientCircleScrollTrigger = store.scrollTrigger.create({
      animation: this.gradientCircleAnimation,
      end: store.w.h * 2.5 + 'px bottom',
      scrub: true,
      start: store.w.h / 2 + 'px top',
      trigger: this.$el
    })

    this.leavingParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingParallaxAnimation,
      end: 'bottom top',
      scrub: true,
      start: 'bottom bottom',
      trigger: this.$el
    })

    this.leavingTitleScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingTitleAnimation,
      end: store.w.h / 2 + 'px top',
      invalidateOnRefresh: true,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })
  }

  render() {
    this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    this.canvasContext.drawImage(this.frames[this.currentFrame.value], this.dx, this.dy, this.dWidth, this.dHeight)
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharacters = []
    
    lines.forEach((line, i) => {
      linesCharacters[i] = []
      
      /* eslint-disable object-property-newline */
      Splitting({ by: 'chars', target: line }).forEach((lineCharacters) => {
        linesCharacters[i].push(...lineCharacters.chars)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharacters
  }

  resize() {
    this.getBounds()
    this.render()
  }
}
/* eslint-enable max-lines */
