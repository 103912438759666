import Block from './Block';
import store from '../util/store';
import Slider from '../util/Slider';
import SliderMobile from '../util/SliderMobile';

export default class ImagesSlider extends Block {
  getElems() {
    this.$container = this.$el.querySelector('.images-slider__container')
    this.$wrapper = this.$el.querySelector('.images-slider__wrapper')
    this.$arrows = this.$el.querySelector('.arrow-slider__wrapper')
    this.$items = this.$wrapper.querySelectorAll('.images-slider__item')
  }

  init() {
    this.paused = true

    if (store.detect.isMobile || store.w.w <= 1024) {
      if (this.slider) this.slider = null
      this.sliderMobile = new SliderMobile({
        el: this.$container,
        wrapper: this.$wrapper,
        items: this.$items,
        arrows: this.$arrows
      })
    } else {
      if (this.sliderMobile) this.sliderMobile = null
      this.slider = new Slider({
        el: this.$el,
        wrapper: this.$wrapper,
        items: this.$items,
        offset: 6.8
      })
    }

    this.scrollTrigger = store.scrollTrigger.create({
      trigger: this.$wrapper,
      onEnter: () => {
        this.paused = false
      },
      onEnterBack: () => {
        this.paused = false

      },
      onLeave: () => {
        this.paused = true
      },
      onLeaveBack: () => {
        this.paused = true
      }
    })
  }

  destroy() {
    this.slider && this.slider.destroy()
    this.sliderMobile && this.sliderMobile.destroy()
  }

  resizeX() {
    this.slider && this.slider.resizeX()
    this.sliderMobile && this.sliderMobile.resizeX()

    this.init()
  }

  update() {
    if (this.paused) return

    this.slider && this.slider.update()
  }
}
