import Block from './Block';
import { gsap } from 'gsap';
import store from '../util/store';

export default class Title extends Block {
  bindMethods() { }

  getElems() {
    this.$fan = this.$el.querySelector('.title__fan')
  }
  init() {
    this.fanAnimation && store.cancelAnimation(this.fanAnimation)

    this.fanAnimation = gsap.timeline()

    this.fanAnimation.to(this.$fan, {
      rotate: 360
    })

    this.scrollTrigger = store.scrollTrigger.create({
      trigger: this.$el,
      animation: this.fanAnimation,
      scrub: true
    })
  }

  onEnterCompleted() { }

  events() { }
}
