export default class Grid {
  constructor() {
    this.grid = document.querySelector('.debug-grid')
    this.grid && this.addEvents()
  }

  addEvents() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'g' || e.key === 'G') this.grid.classList.toggle('opacity-0')
    })
  }
}
