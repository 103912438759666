import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class ScrollIndicator extends Block {
  bindMethods() {
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  getElems() {
    this.$arrows = this.$el.querySelectorAll('.scroll-indicator__arrow')
  }

  init() {
    this.isHovering = false

    this.getBounds()
    this.createAnimation()
  }

  getBounds() {
    const { height } = this.$el.getBoundingClientRect()
    const { height: arrowHeight } = this.$arrows[0].getBoundingClientRect()

    this.translateYValue = (height * 0.75 + arrowHeight) / 2
  }
  
  createAnimation() {
    this.animation = gsap.timeline({
      paused: true
    })

    this.animation
      .fromTo(this.$arrows, {
        y: 0
      }, {
        duration: 0.8,
        ease: 'expo.out',
        stagger: 0.15,
        y: () => this.translateYValue
      })
      .call(() => {
        if (this.isHovering) {
          this.animation.play(0)
        }
      }, [], '>0.4')
  }

  events() {
    this.$el.addEventListener('mouseenter', this.onMouseEnter)
    this.$el.addEventListener('mouseleave', this.onMouseLeave)
  }

  onMouseEnter() {
    if (store.detect.isMobile) return

    this.isHovering = true

    this.animation.play(this.animation.time() === this.animation.totalDuration() ? 0 : this.animation.time())
  }

  onMouseLeave() {
    if (store.detect.isMobile) return
    
    this.isHovering = false
  }

  resize() {
    this.getBounds()
    this.createAnimation()
  }
}
