import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class ProductHighlights extends Block {
  getElems() {
    this.$background = this.$el.querySelector('.product-highlights__background')
    this.$caeliOne = this.$el.querySelector('.product-highlights__caeli-one')
    this.$firstSection = this.$el.querySelector('.product-highlights__first-section')

    this.highlights = Array.from(this.$el.querySelectorAll('.product-highlights__highlight')).map((highlight) => ({
      el: highlight,
      icon: highlight.querySelector('.product-highlights__highlight-icon'),
      text: highlight.querySelector('.product-highlights__highlight-text'),
      titleLinesCharacters: this.splitTitle(highlight.querySelector('.product-highlights__highlight-title'))
    }))

    this.$highlightsWrapper = this.$el.querySelector('.product-highlights__highlights')
    this.$inners = this.$el.querySelectorAll('.product-highlights__inner')
    this.$secondSection = this.$el.querySelector('.product-highlights__second-section')
    if (this.$secondSection) this.titleLinesCharacters = this.splitTitle(this.$secondSection.querySelector('.product-highlights__title'))
    this.$wrapper = this.$el.querySelector('.product-highlights__wrapper')
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'linesChars', target: title })[0].lines
    const linesCharacters = lines.map((line) => Splitting({ by: 'chars', target: line }).flatMap((lineCharacters) => lineCharacters.chars))
    /* eslint-enable object-property-newline */

    return linesCharacters
  }

  init() {
    this.highlightsLength = this.highlights.length
    this.highlightsThresholds = this.highlights.map((_, i) => (i + 1) / this.highlightsLength)

    this.$el.classList.toggle('small-screen', this.isSmallScreen())

    this.getBounds()
    this.prepareAnimation()
    this.initAnimations()
    this.initScrollTriggers()
  }

  isSmallScreen() {
    const highlightsHeight = Math.floor((store.w.w < 1024 ? store.remToPixel(1) : store.remToPixel(1.5)) * 4 + store.header.height * 2 + this.$highlightsWrapper.offsetHeight)
  
    return highlightsHeight > store.w.h
  }

  getBounds() {
    this.caeliOneRect = this.$caeliOne.getBoundingClientRect()
    this.caeliOneHeight = this.caeliOneRect.height

    this.wrapperRect = this.$wrapper.getBoundingClientRect()
    this.wrapperHeight = this.wrapperRect.height

    this.caeliOneTranslateY = - (this.wrapperHeight - (store.w.w < 768 ? store.remToPixel(30) * 2 : store.remToPixel(15) * 2) - this.caeliOneHeight)
  }

  prepareAnimation() {
    gsap.set(this.$caeliOne, { y: () => this.caeliOneTranslateY })

    for (let i = 0; i < this.highlights.length; i++) {
      const highlight = this.highlights[i]

      gsap.set([highlight.icon, highlight.text, highlight.titleLinesCharacters], { alpha: 0 })
    }

    if (this.$secondSection) gsap.set(this.titleLinesCharacters, { alpha: 0 })
  }

  initAnimations() {
    this.backgroundAnimation = gsap.to(this.$background, {
      rotate: 90,
      ease: 'none'
    })

    this.caeliOneAnimation = gsap.fromTo(this.$caeliOne, {
      y: () => this.caeliOneTranslateY
    }, {
      ease: 'none',
      y: 0
    })

    this.enteringParallaxAnimation = gsap.fromTo(this.$inners, {
      yPercent: -25
    }, {
      ease: 'none',
      yPercent: 0
    })

    if (this.isSmallScreen()) {
      for (let i = 0; i < this.highlights.length; i++) {
        const highlight = this.highlights[i]
        const elementsToAnimate = [highlight.icon, highlight.text]
  
        highlight.animation = gsap.timeline({ paused: true })
  
        highlight.titleLinesCharacters.forEach((lineCharacters, j) => {
          highlight.animation.to(lineCharacters, {
            alpha: 1,
            duration: 0.375,
            ease: 'beaucoup.alpha',
            stagger: 0.01875
         }, j * 0.0375)
        })
  
        highlight.animation.to(elementsToAnimate, {
          alpha: 1,
          duration: 0.375,
          ease: 'beaucoup.alpha',
          stagger: 0.01875
        }, 0)
  
        highlight.scrollTrigger = store.scrollTrigger.create({
          animation: highlight.animation,
          start: 'top bottom',
          trigger: highlight.el
        })
      }
    } else {
      this.highlightsAnimation = gsap.timeline()

      for (let i = 0; i < this.highlights.length; i++) {
        const highlight = this.highlights[i]
        const elementsToAnimate = [highlight.icon, highlight.text]
        const previousTotalDuration = this.highlightsAnimation.totalDuration()
  
        highlight.titleLinesCharacters.forEach((lineCharacters, j) => {
          this.highlightsAnimation.to(lineCharacters, {
            alpha: 1,
            duration: 0.375,
            ease: 'beaucoup.alpha',
            stagger: 0.01875
          }, previousTotalDuration + j * 0.0375)
        })
  
        this.highlightsAnimation
          .to(elementsToAnimate, {
            alpha: 1,
            duration: 0.375,
            ease: 'beaucoup.alpha',
            stagger: 0.01875
          }, previousTotalDuration)
          .to(elementsToAnimate, {
            alpha: 0,
            duration: 0.375,
            ease: 'beaucoup.alpha',
            stagger: 0.01875
          })
  
        highlight.titleLinesCharacters.forEach((lineCharacters) => {
          this.highlightsAnimation.to(lineCharacters, {
            alpha: 0.08,
            duration: 0.375,
            ease: 'beaucoup.alpha'
          }, '<')
        })
      }
    }

    this.leavingParallaxAnimation = gsap.fromTo(this.$inners, {
      yPercent: 0
    }, {
      ease: 'none',
      yPercent: 25
    })

    if (this.$secondSection) {
      this.titleAnimation = gsap.timeline({ paused: true })
      this.titleLinesCharacters.forEach((lineCharacters, i) => {
        this.titleAnimation.to(lineCharacters, {
          alpha: 1,
          duration: 0.5,
          ease: 'beaucoup.alpha',
          stagger: 0.025
        }, i * 0.05)
      })
    }
  }

  initScrollTriggers() {
    this.backgroundScrollTrigger = store.scrollTrigger.create({
      animation: this.backgroundAnimation,
      end: 'bottom top',
      scrub: 0.5,
      start: 'top bottom',
      trigger: this.$el
    })

    this.caeliOneScrollTrigger = store.scrollTrigger.create({
      animation: this.caeliOneAnimation,
      end: 'bottom bottom',
      invalidateOnRefresh: true,
      scrub: true,
      start: 'top top',
      trigger: this.$firstSection
    })

    this.enteringParallaxAnimation = store.scrollTrigger.create({
      animation: this.enteringParallaxAnimation,
      end: 'top top',
      scrub: true,
      start: 'top bottom',
      trigger: this.$el
    })

    if (!this.isSmallScreen()) {
      this.highlightsScrollTrigger = store.scrollTrigger.create({
        animation: this.highlightsAnimation,
        end: 'bottom bottom',
        invalidateOnRefresh: true,
        scrub: true,
        start: 'top top',
        trigger: this.$firstSection
      })
    }

    this.leavingParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingParallaxAnimation,
      end: 'bottom top',
      scrub: true,
      start: 'bottom bottom',
      trigger: this.$el
    })

    this.titleScrollTrigger = store.scrollTrigger.create({
      animation: this.titleAnimation,
      end: 'bottom bottom',
      scrub: true,
      start: 'top top',
      trigger: this.$secondSection
    })
  }

  resize() {
    this.$el.classList.remove('small-screen')
    this.$el.classList.toggle('small-screen', this.isSmallScreen())
  
    if (this.isSmallScreen()) {
      this.highlightsScrollTrigger?.disable(true)
  
      for (let i = 0; i < this.highlights.length; i++) {
        const highlight = this.highlights[i]

        gsap.set([highlight.icon, highlight.text, highlight.titleLinesCharacters], { alpha: 0 })
  
        const elementsToAnimate = [highlight.icon, highlight.text]

        highlight.animation = gsap.timeline({ paused: true })

        highlight.titleLinesCharacters.forEach((lineCharacters, j) => {
          highlight.animation.to(lineCharacters, {
            alpha: 1,
            duration: 0.375,
            ease: 'beaucoup.alpha',
            stagger: 0.01875
          }, j * 0.0375)
        })

        highlight.animation.to(elementsToAnimate, {
          alpha: 1,
          duration: 0.375,
          ease: 'beaucoup.alpha',
          stagger: 0.01875
        }, 0)

        highlight.scrollTrigger = store.scrollTrigger.create({
          animation: highlight.animation,
          start: 'top bottom',
          trigger: highlight.el
        })
      }
    } else {
      for (let i = 0; i < this.highlights.length; i++) {
        const highlight = this.highlights[i]

        highlight.scrollTrigger?.disable(true)
  
        gsap.set([highlight.icon, highlight.text, highlight.titleLinesCharacters], { alpha: 0 })
      }

      if (this.highlightsScrollTrigger) {
        this.highlightsScrollTrigger.enable()
      } else {
        this.highlightsAnimation = gsap.timeline()
  
        for (let i = 0; i < this.highlights.length; i++) {
          const highlight = this.highlights[i]
          const elementsToAnimate = [highlight.icon, highlight.text]
          const previousTotalDuration = this.highlightsAnimation.totalDuration()
    
          highlight.titleLinesCharacters.forEach((lineCharacters, j) => {
            this.highlightsAnimation.to(lineCharacters, {
              alpha: 1,
              duration: 0.375,
              ease: 'beaucoup.alpha',
              stagger: 0.01875
            }, previousTotalDuration + j * 0.0375)
          })
    
          this.highlightsAnimation
            .to(elementsToAnimate, {
              alpha: 1,
              duration: 0.375,
              ease: 'beaucoup.alpha',
              stagger: 0.01875
            }, previousTotalDuration)
            .to(elementsToAnimate, {
              alpha: 0,
              duration: 0.375,
              ease: 'beaucoup.alpha',
              stagger: 0.01875
            })
    
          highlight.titleLinesCharacters.forEach((lineCharacters) => {
            this.highlightsAnimation.to(lineCharacters, {
              alpha: 0.08,
              duration: 0.375,
              ease: 'beaucoup.alpha'
            }, '<')
          });
        }
    
        this.highlightsScrollTrigger = store.scrollTrigger.create({
          animation: this.highlightsAnimation,
          end: 'bottom bottom',
          invalidateOnRefresh: true,
          scrub: true,
          start: 'top top',
          trigger: this.$firstSection
        })
      }
    }
  }
  

  resizeX() {
    this.getBounds()

    if (window.scrollY < this.caeliOneScrollTrigger.start) {
      setTimeout(() => {
        gsap.set(this.$caeliOne, { y: () => this.caeliOneTranslateY })
        store.scrollTrigger.refresh()
      }, 0)
    }
  }
}
