import Block from './Block';
import store from '../util/store';

export default class ProductPosts extends Block {
  bindMethods() { }

  getElems() {
    this.$leftContent = this.$el.querySelector('.product-posts__left .post-card__content')
    this.$rightButton = this.$el.querySelector('.product-posts__right__button')
  }

  init() {
    if (store.w.w < 1024) return

    this.bottom = this.$leftContent.offsetHeight + store.remToPixel(2) + 'px'
    this.$rightButton.style.bottom = this.bottom
  }

  resize() {
    if (store.w.w < 1024) return

    this.init()
  }

  onEnterCompleted() { }

  events() { }
}
