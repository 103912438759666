import Anchor from '../util/Anchor'
import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class AnchoredSections extends Block {
  bindMethods() {
    this.onSelectChange = this.onSelectChange.bind(this)
  }

  getElems() {
    this.$anchors = this.$el.querySelectorAll('[data-anchor]')
    this.$card = this.$el.querySelector('.anchored-sections__card')
    this.$content = this.$el.querySelector('.anchored-sections__content')
    this.$gradient = this.$el.querySelector('.anchored-sections__gradient')
    this.$leafs = this.$el.querySelectorAll('.anchored-sections__leaf')
    this.$sections = this.$content.querySelectorAll('[id]')
    this.$select = this.$el.querySelector('.anchored-sections__select')
    this.$selectWrapper = this.$select.parentNode
    this.$title = this.$el.querySelector('.anchored-sections__title')
  }

  init() {
    this.titleLinesCharactersData = this.splitTitle(this.$title)

    if (store.w.w < 1024) this.updateCSSVariable()

    this.targetScrollOffset = store.w.w < 1024 ? store.header.height + this.selectHeight + store.remToPixel(1 + 0.5 + 5) : store.header.height + store.remToPixel(1.5 + 5)

    this.anchors = []

    if (store.w.w < 1024) {
      for (let i = 0; i < this.$select.options.length; i++) {
        const anchoredSection = Array.from(this.$sections).find((section) => section.id === this.$select.options[i].id)

        if (anchoredSection) this.anchors.push({ anchor: { targetEl: anchoredSection }})
      }
    } else {
      for (let i = 0; i < this.$anchors.length; i++) {
        const anchoredSection = Array.from(this.$sections).find((section) => section.id === this.$anchors[i].dataset.anchor)

        if (anchoredSection) {
          this.anchors.push({
            anchor: new Anchor({
              scrollDuration: 1.2,
              targetEl: anchoredSection,
              targetScrollOffset: this.targetScrollOffset,
              triggerEl: this.$anchors[i]
            })
          })
        }
      }
    }

    if (!this.anchors.length) return

    this.initAnimations()
    this.initAnchorsScrollTriggers()
    this.initScrollTriggers()
    this.prepareAnimation()
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharactersData = []
    
    lines.forEach((line, lineIndex) => {
      linesCharactersData[lineIndex] = {
        characters: [],
        charactersWrappers: []
      }
      
      /* eslint-disable object-property-newline */
      Splitting({ by: 'charactersWrappers', target: line }).forEach((lineCharactersData) => {
        linesCharactersData[lineIndex].characters.push(...lineCharactersData.charactersWrappers.characters)
        linesCharactersData[lineIndex].charactersWrappers.push(...lineCharactersData.charactersWrappers.charactersWrappers)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharactersData
  }

  updateCSSVariable() {
    this.selectHeight = this.$select.offsetHeight
    this.$el.style.setProperty('--select-height', `${this.selectHeight + store.remToPixel(1)}px`)
  }

  initAnimations() {
    this.gradientAnimation = gsap.to(this.$gradient, {
      alpha: 0,
      ease: 'none'
    })

    if (store.w.w < 1024) return

    this.titleAnimation = gsap.fromTo(this.$title, {
      scale: 1,
      y: 0
    }, {
      ease: 'none',
      scale: 0.9,
      y: store.w.h * 0.33
    }, 0)

    this.titleLinesCharactersDataAnimation = gsap.timeline()

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) {
      this.titleLinesCharactersDataAnimation.to(this.titleLinesCharactersData[i].characters, {
        alpha: 0,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, i * 0.05)
    }
  }

  initAnchorsScrollTriggers() {
    for (let i = 0; i < this.anchors.length; i++) {
      this.anchors[i].scrollTrigger = store.scrollTrigger.create({
        onEnter: () => {
          this.updateActiveAnchor(i)
        },
        onLeaveBack: () => {
          if (i > 0) this.updateActiveAnchor(i - 1)
        },
        start: `top ${this.targetScrollOffset + 1}px`,
        trigger: this.anchors[i].anchor.targetEl
      })
    }
  }

  initScrollTriggers() {
    this.gradientScrollTrigger = store.scrollTrigger.create({
      animation: this.gradientAnimation,
      end: 'bottom bottom',
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    if (store.w.w < 1024) return

    this.titleScrollTrigger = store.scrollTrigger.create({
      animation: this.titleAnimation,
      end: `+=${store.w.h * 0.5}px top`,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })

    this.titleLinesCharactersDataScrollTrigger = store.scrollTrigger.create({
      animation: this.titleLinesCharactersDataAnimation,
      end: `+=${store.w.h * 0.5}px top`,
      scrub: true,
      start: 'top top',
      trigger: this.$el
    })
  }

  updateActiveAnchor(index) {
    if (store.w.w < 1024) {
      this.$select.selectedIndex = index
    } else for (let i = 0; i < this.anchors.length; i++) this.anchors[i].anchor.$triggerEl.classList.toggle('a', i === index)
  }

  prepareAnimation() {
    gsap.set(this.$card, { y: store.w.h - this.$card.getBoundingClientRect().top })

    gsap.set(this.$leafs, {
      rotate: -50,
      xPercent: -50,
      yPercent: -50
    })

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) gsap.set(this.titleLinesCharactersData[i].charactersWrappers, { alpha: 0 })
  }

  appear() {
    gsap.to(this.$card, {
      delay: 0.3,
      duration: 0.8,
      ease: 'expo.out',
      onComplete: () => {
        for (let i = 0; i < this.anchors.length; i++) this.anchors[i].scrollTrigger.refresh()
      },
      y: 0
    })

    gsap.to(this.$leafs, {
      ease: 'expo.out',
      duration: 2.4,
      rotate: 0,
      stagger: 0.075,
      xPercent: 0,
      yPercent: 0
    })

    for (let i = 0; i < this.titleLinesCharactersData.length; i++) {
      gsap.to(this.titleLinesCharactersData[i].charactersWrappers, {
        alpha: 1,
        delay: 0.5 + i * 0.05,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      })
    }
  }

  events() {
    if (!this.anchors.length) return

    if (store.w.w < 1024) this.$select.addEventListener('change', this.onSelectChange)

    window.addEventListener('dropdownResize', () => {
      setTimeout(() => {
        store.scrollTrigger.refresh()
      }, 100)
    })

    window.addEventListener('headerIsHiding', () => {
      if (store.w.w < 1024) {
        gsap.killTweensOf(this.$selectWrapper)
    
        gsap.to(this.$selectWrapper, {
          duration: 0.6,
          ease: 'expo.out',
          y: - store.header.height - store.remToPixel(store.w.w < 1024 ? 1 : 1.5) - 1 + store.remToPixel(0.5)
        })
  
        this.targetScrollOffset = this.selectHeight + store.remToPixel(1 + 5) - 1

        setTimeout(() => {
          for (let i = 0; i < this.anchors.length; i++) this.anchors[i].scrollTrigger.kill()
  
          this.initAnchorsScrollTriggers()
        }, 100)
      }
    })

    window.addEventListener('headerIsShowing', () => {
      if (store.w.w < 1024) {
        gsap.killTweensOf(this.$selectWrapper)
  
        gsap.to(this.$selectWrapper, {
          duration: 0.6,
          ease: 'expo.out',
          y: 0
        })

        this.targetScrollOffset = store.header.height + this.selectHeight + store.remToPixel(1 + 0.5 + 5) - 1

        setTimeout(() => {
          for (let i = 0; i < this.anchors.length; i++) this.anchors[i].scrollTrigger.kill()

          this.initAnchorsScrollTriggers()
        }, 100)
      }
    })
  }

  onSelectChange() {
    const selectedOptionId = this.$select.options[this.$select.selectedIndex].id
    const anchoredSection = Array.from(this.$sections).find((section) => section.id === selectedOptionId)

    if (anchoredSection) {
      let targetScrollPosition = anchoredSection.getBoundingClientRect().top - this.targetScrollOffset + store.smoothScroll.scroll

      if (store.header.isHidden) {
        if (targetScrollPosition < store.currentScroll) targetScrollPosition -= store.header.height + store.remToPixel(0.5)
      } else if (targetScrollPosition > store.currentScroll + store.header.scrollDownOffset) targetScrollPosition += store.header.height + store.remToPixel(0.5)

      store.smoothScroll.scrollTo(targetScrollPosition, { duration: 1.2 })
    }
  }

  resize() {
    setTimeout(() => {
      if (store.w.w < 1024) this.updateCSSVariable()

      this.targetScrollOffset = store.w.w < 1024 ? store.header.height + this.selectHeight + store.remToPixel(1 + 0.5 + 5) - 1 : store.header.height + store.remToPixel(1.5 + 5) - 1

      for (let i = 0; i < this.anchors.length; i++) {
        this.anchors[i].scrollTrigger.kill()
        if (store.w.w > 1023) this.anchors[i].anchor.targetScrollOffset = this.targetScrollOffset
      }

      this.initAnchorsScrollTriggers()
    }, 100)
  }
}
