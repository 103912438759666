import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class Slider extends Block {
  getElems() {
    this.contents = []
    this.items = []

    this.$contents = this.$el.querySelectorAll('.slider__content')
    this.$contentsWrapper = this.$el.querySelectorAll('.slider__contents')
    this.$images = this.$el.querySelectorAll('.slider__image')
    this.$imagesWrapper = this.$el.querySelector('.slider__images')
    this.$items = this.$el.querySelectorAll('.slider__item')
    this.$itemsWrapper = this.$el.querySelectorAll('.slider__items')

    /* eslint-disable object-property-newline */
    for (let i = 0; i < this.$contents.length; i++) this.contents.push({ el: this.$contents[i], height: this.$contents[i].getBoundingClientRect().height })
    /* eslint-enable object-property-newline */
    
    for (let i = 0; i < this.$items.length; i++) {
      const item = this.$items[i]
      const counter = item.querySelector('.slider__counter')
      const foregroundLine = item.querySelector('.slider__foreground-line')
      const title = item.querySelector('.slider__title')
      
      /* eslint-disable object-property-newline */
      this.items.push({ el: item, counter, foregroundLine, isHovering: false, title: { el: title, linesCharacters: this.splitTitle(title) } })
      /* eslint-enable object-property-newline */
    }
  }

  splitTitle(title) {
    /* eslint-disable object-property-newline */
    const lines = Splitting({ by: 'lines', target: title })[0].lines
    /* eslint-enable object-property-newline */
    const linesCharacters = []
    
    lines.forEach((line, i) => {
      linesCharacters[i] = []
      
      /* eslint-disable object-property-newline */
      Splitting({ by: 'chars', target: line }).forEach((lineCharacters) => {
        linesCharacters[i].push(...lineCharacters.chars)
      })
      /* eslint-enable object-property-newline */
    })

    return linesCharacters
  }

  init() {
    this.isAnimating = false
    this.currentIndex = 0
    this.nextIndex = gsap.utils.wrap(0, this.$items.length, this.currentIndex + 1)

    this.initInterval()

    this.interval.pause()

    this.prepareAnimation()
    store.w.w >= 1200 && this.initAnimation()
    this.initScrollTrigger()
  }

  initInterval() {
    this.interval = gsap.to(this.items[this.nextIndex].foregroundLine, {
      duration: 7.5,
      ease: 'none',
      onComplete: () => {
        this.updateSlide(this.nextIndex)
      },
      scaleX: 1,
      transformOrigin: 'left'
    })
  }

  prepareAnimation() {
    gsap.set(this.$contentsWrapper, { height: this.contents[this.currentIndex].height })
    gsap.set(this.$images[this.currentIndex], { scale: 1.1 })
    
    for (let i = this.currentIndex + 1; i < this.items.length; i++) {
      const item = this.items[i]

      gsap.set(item.counter, { alpha: 0.2 })

      item.title.linesCharacters.forEach((lineCharacters) => {
        gsap.set(lineCharacters, { alpha: 0.2 })
      })
    }
  }

  initAnimation() {
    /* eslint-disable object-property-newline */
    this.parallaxAnimation = gsap.fromTo(this.$imagesWrapper, { yPercent: -15 }, { ease: 'none', yPercent: 15 })
    /* eslint-enable object-property-newline */
  }

  initScrollTrigger() {
    this.intervalScrollTrigger = store.scrollTrigger.create({
      end: 'bottom top',
      onEnter: () => {
        this.interval.play()
      },
      onEnterBack: () => {
        this.interval.play()
      },
      onLeave: () => {
        this.interval.pause()
      },
      onLeaveBack: () => {
        this.interval.pause()
      },
      trigger: this.$itemsWrapper
    })

    if (store.w.w >= 1200) {
      this.parallaxScrollTrigger = store.scrollTrigger.create({
        animation: this.parallaxAnimation,
        end: 'bottom top',
        scrub: true,
        trigger: this.$el
      })
    }
  }

  events() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i]

      item.el.addEventListener('click', this.onItemClick.bind(this, i))
      item.el.addEventListener('mouseenter', this.onItemEnter.bind(this, i))
      item.el.addEventListener('mouseleave', this.onItemLeave.bind(this, i))
    }
  }

  onItemEnter(index) {
    const item = this.items[index]

    if (item.isHovering || !this.isAnimating && index === this.currentIndex) return

    const animation = gsap.timeline()

    item.isHovering = true

    gsap.killTweensOf(item.counter)

    /* eslint-disable object-property-newline */
    animation.to(item.counter, { alpha: 1, duration: 0.375, ease: 'beaucoup.alpha' }, 0.2)
    /* eslint-enable object-property-newline */
    
    item.title.linesCharacters.forEach((lineCharacters, i) => {
      gsap.killTweensOf(lineCharacters)
      
      animation
      .to(lineCharacters, {
        alpha: 0,
        duration: 0.375,
        ease: 'beaucoup.alpha',
        stagger: 0.01875
      }, i * 0.0375)
      .to(lineCharacters, {
        alpha: 1,
        duration: 0.375,
        ease: 'beaucoup.alpha',
        stagger: 0.01875
      }, 0.375 + i * 0.0375)
    })
  }
  
  onItemLeave(index) {
    if (index === this.currentIndex) return
    
    const animation = gsap.timeline()
    const item = this.items[index]
    
    item.isHovering = false

    gsap.killTweensOf(item.counter)
    
    /* eslint-disable object-property-newline */
    animation.to(item.counter, { alpha: 0.2, duration: 0.375, ease: 'beaucoup.alpha' }, 0)
    /* eslint-enable object-property-newline */

    item.title.linesCharacters.forEach((lineCharacters) => {
      gsap.killTweensOf(lineCharacters)

      animation.to(lineCharacters, {
        alpha: 0.2,
        duration: 0.375,
        ease: 'beaucoup.alpha'
      }, 0)
    })
  }

  onItemClick(index) {
    if (index === this.currentIndex) return

    this.interval.pause()
    this.updateSlide(index)
  }

  updateSlide(index) {
    this.isAnimating = true
    
    this.oldIndex = this.currentIndex
    this.oldNextIndex = this.nextIndex
    this.currentIndex = index
    this.nextIndex = gsap.utils.wrap(0, this.$items.length, this.currentIndex + 1)
    
    this.onItemLeave(this.oldIndex)
    this.onItemEnter(this.currentIndex)

    this.updatingSlideAnimation = gsap.timeline({
      onComplete: () => {
        this.isAnimating = false
        store.cancelAnimation(this.interval)
        this.initInterval()
      }
    })

    if (this.currentIndex !== this.oldNextIndex) {
      /* eslint-disable object-property-newline */
      this.updatingSlideAnimation.to(this.items[this.oldNextIndex].foregroundLine, { duration: 0.8, ease: 'expo.out', scaleX: 0, transformOrigin: 'left' }, 0)
      /* eslint-enable object-property-newline */
    }

    /* eslint-disable object-property-newline */
    this.updatingSlideAnimation
      .to(this.$contentsWrapper, { duration: 0.6, ease: 'expo.out', height: this.contents[this.currentIndex].height }, 0)
      .to(this.contents[this.oldIndex].el, { alpha: 0, duration: 0.2, ease: 'none' }, '<')
      .to(this.items[this.oldIndex].foregroundLine, { duration: 0.8, ease: 'expo.out', scaleX: 0, transformOrigin: 'right' }, '<')
      .set(this.$images[this.oldIndex], { alpha: 0 }, '<')
      .fromTo(this.$images[this.oldIndex], { scale: 1.1 }, { duration: 1.5, ease: 'expo.out', scale: 1 }, '<')
      .to(this.contents[this.currentIndex].el, { alpha: 1, duration: 0.2, ease: 'none' }, '<')
      .to(this.items[this.currentIndex].foregroundLine, { duration: 0.8, ease: 'expo.out', scaleX: 1, transformOrigin: 'left' }, '<')
      .set(this.$images[this.currentIndex], { alpha: 1 }, '<')
      .fromTo(this.$images[this.currentIndex], { scale: 1.2 }, { duration: 1.5, ease: 'expo.out', scale: 1.1 }, '<')
    /* eslint-enable object-property-newline */
  }

  resize() {
    for (let i = 0; i < this.contents.length; i++) this.contents[i].height = this.contents[i].el.getBoundingClientRect().height

    gsap.set(this.$contentsWrapper, { height: this.contents[this.currentIndex].height })

    if (store.w.w < 1200) {
      if (this.parallaxAnimation) {
        store.cancelAnimation(this.parallaxAnimation)
        gsap.set(this.$imagesWrapper, { yPercent: 0 })
      }
    }
  }
}
