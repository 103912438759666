import { gsap } from 'gsap';

export default class SimulatorResult {
  constructor({ positive, negative }) {
    this.$positive = positive
    this.$negative = negative

    this.getElems()
    this.init()
    this.bindMethods()
  }

  getElems() {
    this.$positiveContent = this.$positive.querySelector('.simulator__result__content')
  }

  init() {
    this.computedResult = false
    this.caeliNeeded = 0
    this.resultShown = false

    this.prepareAnimation()
  }

  bindMethods() {
    this.prepareAnimation = this.prepareAnimation.bind(this)
    this.get = this.get.bind(this)
    this.compute = this.compute.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.restart = this.restart.bind(this)
  }

  get() {
    // console.log(this.caeliNeeded, this.computedResult);

    this.compute('very-good', 'yes', 30)
    this.compute('very-good', 'no', 20)
    this.compute('medium', 'yes', 25)
    this.compute('medium', 'no', 15)
    this.compute('bad', 'yes', 20)
    this.compute('bad', 'no', 15)
  }

  compute(isolation, sun, space) {
    // console.log(this.responses);
    if (this.computedResult || this.caeliNeeded !== 0) return

    if (this.responses.isolation === isolation && this.responses.sun === sun) {
      if (this.responses.space <= space) {
        this.caeliNeeded = 1
        this.computedResult = true
      } else if (this.responses.space <= space * 2) {
        this.caeliNeeded = 2
        this.computedResult = true
      }

      return
    }

    this.computedResult = false
  }

  show(responses) {
    this.resultShown = true
    this.responses = responses

    this.get()

    setTimeout(() => {
      // If positive or negative
      if (this.computedResult && this.caeliNeeded) {
        // console.log('show', this.caeliNeeded);
        const resultToReplace = this.$positive.querySelector('.result-to-replace')

        if (resultToReplace) {
          resultToReplace.innerHTML = this.caeliNeeded
        } else {
          const updateContent = this.$positiveContent.innerHTML.replaceAll('{result}', `<span class='result-to-replace'>${this.caeliNeeded}</span>`)

          this.$positiveContent.innerHTML = updateContent
        }

        gsap.to(this.$positive, {
          yPercent: 0,
          pointerEvents: 'auto',
          duration: 0.8,
          ease: 'expo.out'
        })
      } else {
        gsap.to(this.$negative, {
          yPercent: 0,
          pointerEvents: 'auto',
          duration: 1.2,
          ease: 'expo.out'
        })
      }
    }, 100);
  }

  hide() {
    this.resultShown = false

    // If positive or negative
    this.computedResult && gsap.to(this.$positive, {
      yPercent: 100,
      pointerEvents: 'none',
      duration: 0.8,
      ease: 'expo.out'
    })

    !this.computedResult && gsap.to(this.$negative, {
      yPercent: 100,
      pointerEvents: 'none',
      duration: 0.8,
      ease: 'expo.out'
    })

    this.computedResult = false
    this.caeliNeeded = 0
  }

  prepareAnimation() {
    gsap.set([this.$positive, this.$negative], {
      yPercent: 100,
      pointerEvents: 'none'
    })
  }

  restart() {
    this.hide()
  }
}
