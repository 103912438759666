import Block from './Block'
import gsap from 'gsap'
import store from '../util/store'

export default class RoundedIconButton extends Block {
  bindMethods() {
    this.onMouseEnter = this.onMouseEnter.bind(this)
  }

  getElems() {
    this.$icons = this.$el.querySelectorAll('.rounded-icon-button__icon')
    this.$iconsWrapper = this.$el.querySelector('.rounded-icon-button__icons-wrapper')
  }

  init() {
    this.animation = this.$el.dataset.animation
    this.direction = this.$el.dataset.direction

    this.animation === 'translation' && this.getBounds()
  }

  getBounds() {
    const { width } = this.$iconsWrapper.getBoundingClientRect()
    const { height: iconHeight } = this.$icons[0].getBoundingClientRect()

    this.iconTranslateXValue = (width * 0.75 + iconHeight) / 2 + 1
  }

  events() {
    this.$el.addEventListener('mouseenter', this.onMouseEnter)
  }

  onMouseEnter() {
    if (store.detect.isMobile) return
    
    gsap.killTweensOf(this.$icons)

    if (this.animation === 'translation') {
      gsap.fromTo(this.$icons, { x: 0 }, {
        duration: 0.8,
        ease: 'expo.out',
        stagger: 0.15,
        x: () => this.iconTranslateXValue * (this.direction === 'left' ? -1 : 1)
      })
    } else {
      gsap.fromTo(this.$icons, { rotate: 0 }, {
        duration: 0.8,
        ease: 'expo.out',
        rotate: 360
      })
    }
  }

  resizeX() {
    this.animation === 'translation' && this.getBounds()
    
    gsap.set(this.$icons, {
      rotate: 0,
      x: 0
    })
  }
}
