import gsap from 'gsap'
import ToggleManager from './ToggleManager'

export default class LanguageSwitcher extends ToggleManager {
  constructor({ el, overlay, toggler }) {
    super(el, overlay, toggler)
  }

  getElems() {
    this.$panel = this.$el.querySelector('.language-switcher__panel')
    this.$panelBackground = this.$panel.querySelector('.language-switcher__background')
    this.$panelItems = this.$panel.querySelectorAll('.language-switcher__item')
    this.$togglerCaret = this.$toggler.querySelector('.language-switcher__caret')
  }

  init() {
    super.init()

    this.prepareAnimation()
  }

  prepareAnimation() {
    gsap.set(this.$panelBackground, { yPercent: -101 })
    gsap.set(this.$panelItems, { autoAlpha: 0 })
  }

  open() {
    super.open().then(() => {
      this.openingAnimation
        .to(this.$togglerCaret, { rotate: -180 })
        .to(this.$panelBackground, { yPercent: 0 }, 0)
        .to(this.$panelItems, {
          autoAlpha: 1,
          duration: 0.3,
          ease: 'none',
          stagger: 0.025
        }, 0.1)

      this.$panel.classList.remove('pointer-events-none')
    })
  }

  close(force = false) {
    super.close(force)

    this.closingAnimation
      .to(this.$togglerCaret, { rotate: 0 })
      .to(this.$panelBackground, { yPercent: -101 }, 0.05)
      .to(this.$panelItems, {
        autoAlpha: 0,
        duration: force ? 0 : 0.1,
        ease: 'none'
      }, 0)

    this.$panel.classList.add('pointer-events-none')
  }
}
