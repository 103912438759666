import Block from './Block'
import gsap from 'gsap'

export default class Button extends Block {
  bindMethods() {
    this.onMouseEnter = this.onMouseEnter.bind(this)
  }

  getElems() {
    this.$arrows = this.$el.querySelectorAll('.button__arrow')
    this.$arrowsWrapper = this.$el.querySelector('.button__arrows-wrapper')
  }

  init() {
    if (!this.$arrowsWrapper) return

    this.getBounds()
  }

  getBounds() {
    const { width: arrowWrapperWidth } = this.$arrowsWrapper.getBoundingClientRect()
    const { height: arrowHeight } = this.$arrows[0].getBoundingClientRect()

    this.arrowTranslateXValue = (arrowWrapperWidth + arrowHeight) / 2
  }

  events() {
    if (!this.$arrowsWrapper) return

    this.$el.addEventListener('mouseenter', this.onMouseEnter)
  }

  onMouseEnter() {
    gsap.killTweensOf(this.$arrows)

    gsap.fromTo(this.$arrows, { x: 0 }, {
      duration: 0.8,
      ease: 'expo.out',
      stagger: 0.15,
      x: () => this.arrowTranslateXValue
    })
  }

  resize() {
    if (!this.$arrowsWrapper) return

    this.getBounds()
  }
}
