import Block from './Block'
import gsap from 'gsap'
import Splitting from 'splitting'
import store from '../util/store'

export default class FullScreenTitles extends Block {
  getElems() {
    this.$inner = this.$el.querySelector('.full-screen-titles__inner')
    this.$leafs = this.$el.querySelectorAll('.full-screen-titles__leaf')
    this.$firstTitle = this.$el.querySelector('.full-screen-titles__first-title')
    this.$secondTitle = this.$el.querySelector('.full-screen-titles__second-title')
  }

  init() {
    this.firstTitleLinesCharacters = this.splitTitle(this.$firstTitle)
    if (this.$secondTitle) this.secondTitleLinesCharacters = this.splitTitle(this.$secondTitle)

    this.initAnimation()
    this.initScrollTrigger()
    this.prepareAnimation()
  }

  splitTitle(title) {
    const lines = Splitting({
      by: 'lines',
      target: title
    })[0].lines
    const linesCharacters = []
    
    lines.forEach((line, i) => {
      linesCharacters[i] = []
      
      Splitting({
        by: 'chars',
        target: line
      }).forEach((lineCharacters) => {
        linesCharacters[i].push(...lineCharacters.chars)
      })
    })

    return linesCharacters
  }

  initAnimation() {
    /* eslint-disable object-property-newline */
    this.enteringParallaxAnimation = gsap.fromTo(this.$inner, { yPercent: -25 }, { ease: 'none', yPercent: 0 })
    /* eslint-enable object-property-newline */

    if (this.$leafs.length) {
      this.enteringLeafsAnimation = gsap.fromTo(this.$leafs, {
        rotate: -50,
        xPercent: -50,
        yPercent: -50
      }, {
        ease: 'expo.out',
        duration: 1.6,
        rotate: 0,
        stagger: 0.075,
        xPercent: 0,
        yPercent: 0
      })
    }

    this.titlesAnimation = gsap.timeline()

    this.firstTitleLinesCharacters.forEach((lineCharacters, i) => {
      this.titlesAnimation.to(lineCharacters, {
        alpha: this.$secondTitle ? 0 : 1,
        duration: 0.5,
        ease: 'beaucoup.alpha',
        stagger: 0.025
      }, i * 0.05)
    })

    if (this.$secondTitle) {
      this.secondTitleLinesCharacters.forEach((lineCharacters, i) => {
        this.titlesAnimation.to(lineCharacters, {
          alpha: 1,
          duration: 0.5,
          ease: 'beaucoup.alpha',
          stagger: 0.025
        }, 0.5 + i * 0.05)
      })
    }

    /* eslint-disable object-property-newline */
    this.leavingParallaxAnimation = gsap.fromTo(this.$inner, { yPercent: 0 }, { ease: 'none', yPercent: 25 })
    /* eslint-enable object-property-newline */
  }

  initScrollTrigger() {
    this.enteringParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.enteringParallaxAnimation,
      end: 'top top',
      scrub: true,
      trigger: this.$el
    })

    if (this.$leafs.length) {
      this.enteringLeafsScrollTrigger = store.scrollTrigger.create({
        animation: this.enteringLeafsAnimation,
        start: 'top 25%',
        trigger: this.$el
      })
    }

    this.titlesScrollTrigger = store.scrollTrigger.create({
      animation: this.titlesAnimation,
      end: 'bottom bottom',
      invalidateOnRefresh: true,
      scrub: true,
      start: this.$secondTitle ? 'top top' : 'top 25%',
      trigger: this.$el
    })

    this.leavingParallaxScrollTrigger = store.scrollTrigger.create({
      animation: this.leavingParallaxAnimation,
      end: 'bottom top',
      scrub: true,
      start: 'bottom bottom',
      trigger: this.$el
    })
  }

  prepareAnimation() {
    gsap.set(this.$secondTitle ? this.secondTitleLinesCharacters : this.firstTitleLinesCharacters, { alpha: 0 })
  }
}
